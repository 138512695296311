import { useState, useEffect, useContext, useRef } from 'react'
import {
    getTeamMembers, imgData, newConversation, getMyConversations, deleteConversations, blockConversations, getMessages, getMyConversationDetails,
    listClientChat, imageUploadApi, getGroupDetails, addMemberGroup, removeMemberGroup, adminSetting, updateGroup, updateGroupSetting,
    uploadDoc, deleteDoc, chatDocs, logOutApi
} from '../../../Context/api'
import axios from 'axios';
import { commonMessage, statusCodes, roleNames } from '../../../Constants/generalConfig';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { Notifications } from '../../../App';
import { useNavigate } from 'react-router-dom';

const UseChat = () => {
    const { receiveData, setReceiveData, socket, userInfo } = useContext(Notifications)
    const [teamMemberData, setTeamMemberData] = useState([])
    const [conversationData, setConversationData] = useState([])
    const [profile, setProfile] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [searchText, setSearchText] = useState('')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [sendMessage, setSendMessage] = useState('');
    const [receiveMessages, setReceiveMessages] = useState({});
    const [clientList, setClientList] = useState([])
    const [type, setType] = useState('INDIVIDUAL')
    const [file, setFile] = useState('')
    const [groupName, setGroupName] = useState('')
    const [selectedValues, setSelectedValues] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [directModalShow, setDirectModalShow] = useState(false)
    const [clientModalShow, setClientModalShow] = useState(false)
    const [showGroupInfo, setShowGroupInfo] = useState(false)
    const [addMember, setAddMember] = useState(false)
    const [groupDetailData, setGroupDetailData] = useState({})
    const [updateBtn, setUpdateBtn] = useState(false)
    const [checked, setChecked] = useState({
        onlyAdminCanAddOrRemove: false,
        onlyAdminCanMessage: false,
        onlyAdminUpdateInfo: false,
        type: ""
    })
    const [msgId, setMsgId] = useState('')
    const [editMsg, setEditMsg] = useState('')
    const [uploadData, setUploadData] = useState('')
    const [groupUpdateSettingShow, setGroupUpdateSettingShow] = useState(false)
    const [index, setIndex] = useState(null)
    const messageEl = useRef(null);
    const fileInputRef = useRef(null);
    const [disableAdmin, setDisableAdmin] = useState(null)
    const [showPicker, setShowPicker] = useState(false);
    const [showEditPicker, setShowEditPicker] = useState(false)
    const [chatMessageList, setChatMessageList] = useState([])
    const navigate = useNavigate()
    const [conversationType, setConversationType] = useState({
        INDIVIDUAL: '',
        GROUP: '',
        CLIENT: ''
    })
    // console.log(receiveData,'receiveData')
    useEffect(() => {
        if (Object.keys(receiveData)?.length) {
            if (receiveData.deleted || receiveData.isEdited) {
                chatMessages(receiveData.conversation_id)
            }
            else {
                ConversationDetails(receiveData)
            }
        }
    }, [receiveData])

    useEffect(() => {
        if (messageEl) {
            messageEl.current?.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }

    }, [receiveMessages])

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            ConversationList()
        }
    }, [type])

    useEffect(() => {
        if (groupUpdateSettingShow) {
            groupUpdateSetting()
        }
    }, [checked])


    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const handleSendMessage = () => {
        if (!sendMessage.trim() && !uploadData.length) return
        const data = {
            loggedInUser: userData?.userId,
            conversation_id: profile[0].conversation_id,
            message: sendMessage.trim(),
            nowTime: moment(new Date()).format(),
            conversationType: type,
            document: uploadData ? [uploadData] : []
        }
        socket.emit('send-message', { ...data });
        setSendMessage('')
        setUploadData('')
    }
    const handleEditMessage = () => {
        if (!editMsg.trim()) return
        const data = {
            loggedInUser: userData?.userId,
            conversation_id: profile[0].conversation_id,
            message: editMsg,
            nowTime: moment(new Date()).format(),
            conversationType: type,
            isEdited: true,
            messageId: msgId
        }
        socket.emit('send-message', { ...data });
        setMsgId('')
    }
    const handleDeleteMessage = (id) => {
        const data = {
            messageId: id,
            conversationType: type,
            conversation_id: profile[0].conversation_id
        }
        socket.emit('delete-message', { ...data })
    }
    const enterSendMessage = (event) => {
        if (event.keyCode === 13) {
            handleSendMessage()
        }
    }
    const teamMember = () => {
        const data = {
            provider_id: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
            loggedInUser: userData?.userId,
            branchId: userData?.locationId
        }
        setSpinner(true)
        axios.post(getTeamMembers(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTeamMemberData(resp.data.data)
                setSpinner(false)
            }
            else {
                setSpinner(false)
                setTeamMemberData([])
            }
        })
    }
    // console.log(conversationData, 'conversationData')
    const Conversation = (item) => {
        const data = {
            loggedInUser: userData?.userId,
            type: type,
            membersArr: type == 'GROUP' ? selectedValues : [item._id],
            nowTime: moment(new Date()).format(),
            name: type == 'GROUP' ? groupName : item.name,
            image: type == 'GROUP' ? file : item.image
        }
        axios.post(newConversation(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                // ConversationList()
                const existingConversation = conversationData.find((id) => id.conversation_id === resp.data.data.conversation_id);
                if (!existingConversation) {
                    conversationData.unshift(resp.data.data);
                }
                if (existingConversation) {
                    setProfile([resp.data.data])
                    chatMessages(resp.data.data.conversation_id)
                }
                setIndex(0)
                setFile('')
                setGroupName('')
                setShowModal(false)
                setSelectedValues([])
                setDirectModalShow(false)
                setClientModalShow(false)


            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const ConversationList = (e) => {
        const data = {
            searchText: e,
            loggedInUser: userData?.userId,
            type: type,
            page: 1,
            count: 20
        }
        if (type == 'CLIENT') {
            data.title = 'PROVIDER'
        }
        setSpinner(true)
        axios.post(getMyConversations(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setConversationData(resp.data.data)
                setSpinner(false)

            }
            else {
                setConversationData([])
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const ConversationDelete = (id) => {
        const data = {
            loggedInUser: userData?.userId,
            conversation_id: id,
            nowTime: moment(new Date()).format()
        }
        axios.post(deleteConversations(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                // toast.success(resp.data.message)
                ConversationList()
                setProfile([])
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const ConversationBlock = (item) => {
        const data = {
            loggedInUser: userData?.userId,
            conversation_id: item.conversation_id,
            nowTime: moment(new Date()).format(),
            action: !item.isBlocked ? "BLOCK" : "UN-BLOCK"
        }
        axios.post(blockConversations(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                // let index = conversationData.findIndex((item) => item.conversation_id == profile[0].conversation_id)
                let index = conversationData.findIndex((item) => item.conversation_id == item.conversation_id)
                let temp = [...conversationData]
                temp[index].isBlocked = !item.isBlocked
                setProfile(temp)
                setConversationData(temp)
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const enterSearch = (event) => {
        if (event.keyCode === 13) {
            ConversationList(event.target.value)
        }
    }
    const chatMessages = (id) => {
        const data = {
            loggedInUser: userData?.userId,
            page: 1,
            count: 50,
            conversationType: type,
            conversation_id: id,
            nowTime: moment(new Date()).format()
        }
        axios.post(getMessages(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setReceiveMessages(resp.data.data)
                if (messageEl) {
                    messageEl.current?.addEventListener('DOMNodeInserted', event => {
                        const { currentTarget: target } = event;
                        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                    });
                }
                let temp = [...conversationData]
                const indexofconversation = conversationData.findIndex((item, index) => item.conversation_id == resp.data.data.conversation_id)
                temp[indexofconversation].unReadMsg = 0
                setConversationData(temp)
            }
            else {
                setReceiveMessages({})
            }
        })
    }
    const ConversationDetails = (item) => {
        const data = {
            sender_id: item.sender_id,
            message_id: item.message_id,
            nowTime: moment(new Date()).format(),
            loggedInUser: userData?.userId,
            conversation_id: item.conversation_id,
            conversationType: item?.conversationType,
            convOpened: item?.conversation_id === profile[0]?.conversation_id ? true : false
        }

        axios.post(getMyConversationDetails(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setReceiveData({})

                if (conversationData.find((id) => id.conversation_id == resp.data.data.conversation_id)) {
                    const indexofconversation = conversationData.findIndex((item, index) => item.conversation_id == resp.data.data.conversation_id)
                    changeIndex(conversationData, 0, indexofconversation);
                    setIndex(0)
                }
                // setConversationType(resp.data.data.type)
                if (userData.userId != item.sender_id) {
                    // console.log("truetruetruetruetruetrue");
                    setConversationType((pre) => {
                        let temp = { ...pre }
                        if (resp.data.data.type === "INDIVIDUAL") {
                            temp.INDIVIDUAL = resp.data.data.type
                        } else if (resp.data.data.type === "GROUP") {
                            temp.GROUP = resp.data.data.type
                        }
                        else {
                            temp.CLIENT = resp.data.data.type
                        }
                        return temp
                    })
                }
                if (type == item.conversationType) {
                    if (!conversationData.length) {
                        conversationData.push(resp.data.data)
                    }
                }
                if (resp.data.data?.lastMessages[0].isEdited || resp.data.data?.lastMessages[0].isDeleted) {
                    let msgData = { ...receiveMessages }
                    let msgIndex = receiveMessages?.messagesData?.allMessages?.findIndex((item) => item.message_id == resp.data.data?.lastMessages[0]._id)
                    msgData.messagesData.allMessages[msgIndex].message = resp.data.data?.lastMessages[0].message
                    msgData.messagesData.allMessages[msgIndex].isDeleted = resp.data.data?.lastMessages[0].isDeleted
                    msgData.messagesData.allMessages[msgIndex].isEdited = resp.data.data?.lastMessages[0].isEdited
                    lastMessage(item, resp.data.data)

                }
                else {
                    if (resp.data.data?.conversation_id == profile[0]?.conversation_id) {
                        lastMessage(item, resp.data.data)
                        // console.log(resp.data.data, 'resp.data.data')
                        let messageArray = { ...receiveMessages }
                        messageArray?.messagesData?.allMessages.push(resp.data.data?.lastMessages[0])
                        setReceiveMessages(messageArray)
                    }
                    else {
                        lastMessage(item, resp.data.data)
                    }
                }
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const lastMessage = (data, i) => {
        let temp = [...conversationData]
        const indexofconversation = conversationData.findIndex((item, index) => item.conversation_id == data.conversation_id)
        let newObj = {
            document: [],
            message: i.lastMessages[0].message,
            msgTime: i.lastMessages[0].msgTime,
            _id: null
        }

        temp[indexofconversation].lastMessages[0] = newObj
        temp[indexofconversation].unReadMsg = i.unReadMsg
        setConversationData(temp)
    }
    const selectConversation = (conversation) => {
        let conversationData = []
        conversationData.push({ ...conversation })
        setProfile(conversationData);
        chatMessages(conversation.conversation_id)
    }
    const getClientList = (e) => {
        const data = {
            userId: userData?.createdbyId,
            isActive: true,
            page: 1,
            count: 100,
            isAccepted: 'accepted',
            searchText: e,
        }
        setSpinner(true)
        axios.post(listClientChat(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setClientList(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setClientList([])
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const uploadImage = (e) => {
        const fd = new FormData()
        fd.append('file', e)
        fd.append('type', 'PROFILE')
        axios.post(imageUploadApi(), fd).then((res) => {
            if (res.data.code === 200) {
                setFile(res.data.data.imagePath)
                setUpdateBtn(true)
            }
            else {
                toast.error(res.data.message)
            }
        })
    }
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
        } else {
            setSelectedValues((prevSelectedValues) =>
                prevSelectedValues.filter((item) => item !== value)
            );
        }
    };
    const groupDetail = (id) => {
        const data = {
            conversation_id: id,
            loggedInUser: userData?.userId
        }
        setSpinner(true)
        axios.post(getGroupDetails(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setGroupDetailData(resp.data.data)
                setShowGroupInfo(true)
                setSpinner(false)
                setGroupName(resp.data.data.name)
                setFile(resp.data.data.image)
                setAddMember(false)
                setUpdateBtn(false)
                setChecked({
                    onlyAdminCanAddOrRemove: resp.data.data.onlyAdminCanAddOrRemove,
                    onlyAdminCanMessage: resp.data.data.onlyAdminCanMessage,
                    onlyAdminUpdateInfo: resp.data.data.onlyAdminUpdateInfo
                })
                let adminD = resp?.data?.data?.membersData?.find((cur, index) => cur?.user_id == userData?.userId)?.isAdmin


                setDisableAdmin(adminD)

            }
            else {
                setGroupDetailData([])
                setSpinner(false)
            }
        })
    }
    const memberAddInGroup = (item) => {
        const data = {
            loggedInUser: userData?.userId,
            conversation_id: profile[0].conversation_id,
            nowTime: moment(new Date()).format(),
            user_id: item._id
        }
        axios.post(addMemberGroup(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                let temp = { ...groupDetailData }
                temp.membersData.push({ image: item.image, name: item.name, user_id: item._id, isAdmin: false })
                setGroupDetailData(temp)
            }
            else {
                toast.error(resp.data.message)
            }
        })

    }
    const memberRemoveInGroup = (id) => {
        const data = {
            conversation_id: profile[0].conversation_id,
            nowTime: moment(new Date()).format(),
            loggedInUser: userData?.userId,
            member_id: id,

        }
        axios.post(removeMemberGroup(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setShowGroupInfo(false)
                ConversationList()
                setProfile([])
            }
            else {
                toast.error(resp.data.message)
            }
        })

    }
    const groupSetting = (id, member) => {
        const data = {
            action: member ? "REMOVE" : "ADD",
            conversation_id: profile[0].conversation_id,
            nowTime: moment(new Date()).format(),
            loggedInUser: userData?.userId,
            member_id: id,
        }
        axios.post(adminSetting(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                let index = groupDetailData.membersData.findIndex((item) => item.user_id == id)
                let temp = { ...groupDetailData }
                temp.membersData[index].isAdmin = member ? false : true
                setGroupDetailData(temp)
            }
            else {
                toast.error(resp.data.message)
            }
        })

    }
    const groupUpdate = () => {
        const data = {
            conversation_id: profile[0].conversation_id,
            nowTime: moment(new Date()).format(),
            loggedInUser: userData?.userId,
            name: groupName,
            image: file
        }
        if (!groupName) return toast.warn('Please enter group name')
        axios.post(updateGroup(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setShowGroupInfo(false)
                let index = conversationData.findIndex((item) => item.conversation_id == profile[0].conversation_id)
                let temp = [...conversationData]
                temp[index].name = groupName
                temp[index].image = file
                setProfile(temp)
                setConversationData(temp)
                setFile('')
            }
            else {
                toast.error(resp.data.message)
            }
        })

    }
    const groupUpdateSetting = () => {
        const data = {
            conversation_id: profile[0]?.conversation_id,
            nowTime: moment(new Date()).format(),
            loggedInUser: userData?.userId,
            [checked?.type]: checked?.type == "onlyAdminCanAddOrRemove" ? !checked.onlyAdminCanAddOrRemove : checked?.type == "onlyAdminCanMessage" ?
                !checked.onlyAdminCanMessage : checked?.type == "onlyAdminUpdateInfo" ? !checked.onlyAdminUpdateInfo : "",
        }
        axios.post(updateGroupSetting(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setGroupUpdateSettingShow(false)
            }
            else {
                toast.error(resp.data.message)
            }
        })

    }
    const uploadPdf = (e) => {
        const fd = new FormData()
        fd.append('file', e)
        fd.append('type', '')
        setSpinner(true)
        axios.post(uploadDoc(), fd, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setUploadData(resp.data.data.db_name)
                setSpinner(false)
                fileInputRef.current.value = null;
            } else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        })
    }
    const deleteDocument = () => {
        setSpinner(true)
        axios.post(deleteDoc(), { fileName: uploadData }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                setUploadData('')
            } else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        })
    }
    const pdfDownload = (docs) => {
        window.open(`${chatDocs}${docs}`);
    }
    const onEmojiClick = (emoji) => {
        setSendMessage((prevInput) => prevInput + emoji.emoji);
    };
    const onEmojiClickEdit = (emoji) => {
        setEditMsg((prevInput) => prevInput + emoji.emoji)
    };
    // const emoji_Scroll = () => {
    //     messageEl.current?.addEventListener('DOMNodeInserted', event => {
    //         const { currentTarget: target } = event;
    //         target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    //     });
    // }
    const teamFilteredData = teamMemberData.filter(item => item.name.toLowerCase().includes(searchText.toLocaleLowerCase()));
    const clientFilteredData = clientList.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLowerCase()));

    const matchTime = (currentTime, prevousTime) => {
        if (moment(currentTime).format('YYYY-MM-DD') == moment(prevousTime).format('YYYY-MM-DD')) return ''
        else return moment(currentTime).format('ll')
    }
    const handleScroll = e => {
        let element = e.target;
        if (element.scrollTop === 0) {
            chatMessages(profile[0].conversation_id)
        }
    }
    const changeIndex = (array, fromIndex, toIndex) => {
        const elementToMove = array.splice(fromIndex, 1)[0];
        array.splice(toIndex, 0, elementToMove);
    };

    return {
        handleSendMessage,
        handleEditMessage,
        handleDeleteMessage,
        enterSendMessage,
        teamMember,
        Conversation,
        ConversationList,
        ConversationDelete,
        ConversationBlock,
        enterSearch,
        chatMessages,
        ConversationDetails,
        lastMessage,
        selectConversation,
        getClientList,
        uploadImage,
        handleCheckboxChange,
        groupDetail,
        memberAddInGroup,
        memberRemoveInGroup,
        groupSetting,
        handleScroll,
        matchTime,
        onEmojiClickEdit,
        onEmojiClick,
        pdfDownload,
        deleteDocument,
        uploadPdf,
        groupUpdateSetting,
        groupUpdate,
        navigate,
        setTeamMemberData,
        setConversationData,
        setProfile,
        setSpinner,
        setSearchText,
        setSendMessage,
        setReceiveMessages,
        setClientList,
        setType,
        setFile,
        setGroupName,
        setSelectedValues,
        setShowModal,
        setShowGroupInfo,
        setAddMember,
        setGroupDetailData,
        setUpdateBtn,
        setChecked,
        setMsgId,
        setEditMsg,
        setUploadData,
        setGroupUpdateSettingShow,
        setIndex,
        setDisableAdmin,
        setShowPicker,
        setShowEditPicker,
        setChatMessageList,
        setConversationType,
        directModalShow, setDirectModalShow,
        clientModalShow, setClientModalShow,
        teamFilteredData,
        clientFilteredData,
        receiveData,
        setReceiveData,
        socket,
        teamMemberData,
        conversationData,
        profile,
        spinner,
        searchText,
        userData,
        sendMessage,
        receiveMessages,
        clientList,
        type,
        file,
        groupName,
        selectedValues,
        showModal,
        showGroupInfo,
        addMember,
        groupDetailData,
        updateBtn,
        checked,
        msgId,
        editMsg,
        uploadData,
        groupUpdateSettingShow,
        index,
        messageEl,
        fileInputRef,
        disableAdmin,
        showPicker,
        showEditPicker,
        chatMessageList,
        imgData,
        conversationType,
        userInfo

    }
}

export default UseChat
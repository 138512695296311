/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { staffBookAppointmentValidation, cancelAppointmentValidation, unAvailabilityValidation } from '../../../Context/validation'
import {
    logOutApi, calendarAppointments, appointmentBookApi, clientListApi, staffServices, staffBranches, appointmentViewApi, cancelappointmnetApi, appointmentDeleteApi, rescheduleAppointmentApi,
    timeSlotApi, staffApi, getOffer, applyOffer, timeZoneApi, unAvailability
} from '../../../Context/api'
import { statusCodes, commonMessage, initialState } from '../../../Constants/generalConfig'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import 'react-calendar/dist/Calendar.css'
import { momentLocalizer } from 'react-big-calendar';
import { Notifications } from '../../../App'

const UseCalendar = () => {
    const { userInfo } = useContext(Notifications)
    const [branchListData, setBranchListData] = useState([])
    const [serviceList, setServiceList] = useState()
    const [appointmentList, setAppointmentList] = useState([])
    const timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [clientData, setClientData] = useState([])
    const [show, setShow] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const EVENTS = []
    const localizer = momentLocalizer(moment)
    const [modalView, setModalView] = useState(false);
    const [appointmentViewData, setAppointmentViewData] = useState([])
    const [appointmentDetail, setAppointmentDetail] = useState([])
    const [showReschedule, setShowReschedule] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [slots, setSlots] = useState([])
    const [slotTime, setSlotTime] = useState([])
    const [date, setDate] = useState('')
    const [staffAvailabilityId, setStaffAvailabilityId] = useState('')
    const [clientPerPage, setClientPerPage] = useState(1)
    const [weekFilterDate, setweekFilterDate] = useState({
        startDateFilter: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))).startOf("week").utc(),
        endDateFilter: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6))).endOf("week").utc(),
    })
    const [monthFilter, setMonthFilter] = useState({
        startDateFilter: moment().startOf('month').startOf('week'),
        endDateFilter: moment().endOf('month').endOf('week')
    })
    const [dayFilter, setDayFilter] = useState([])
    const [currentBranch, setCurrentBranch] = useState(userData?.locationId)
    const [daySelected, setDaySelected] = useState('month')
    const [calendarValue, setCalendarValue] = useState(new Date())
    const [showSlot, setShowSlot] = useState(false)
    const [offerListData, setOfferListData] = useState([])

    const [popup, setPopup] = useState(false)
    const [showUnAvailability, setShowUnAvailability] = useState(false)
    const [timeZoneList, setTimeZoneList] = useState([])

    const [defaultFormValue, setFormValue] = useState(initialState)

    const onShowMore = useCallback((events, date) =>
        setDayFilter({ startDateFilter: moment(events[0].start).startOf('day').utc(), endDateFilter: moment(events[0].end).endOf('day').utc() }),
        []
    )

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getBranchList()
        }
    }, [])
    useEffect(() => {
        if (currentBranch) {
            calendarList()
        }
    }, [daySelected, monthFilter, dayFilter, weekFilterDate])

    useEffect(() => {
        if (showSlot) {
            timesSlot()
        }

    }, [showSlot])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    // appointmentList?.map((item) => {
    //     EVENTS.push({ title: item.status === 'UNAVAILABILITY' ? `Un-Available(${item.staffId == null ? item.providerName : item.StaffName})` : `${item.clientName} ${moment(item.startDateTime).format('LT')}`, start: new Date(item.startDateTime), end: new Date(item.endDateTime), item })

    // })
    appointmentList?.forEach((item) => {
        EVENTS.push({
            title: item.status === 'UNAVAILABILITY' ? `Un-Available(${item.staffId == null ? item.providerName : item.StaffName})` : `${item.clientName} ${moment(item.startDateTime).format('LT')}`,
            start: new Date(item.startDateTime),
            end: new Date(item.endDateTime),
            item
        });
    });
    const timesSlot = (day) => {
        setDate(moment(day || calendarValue).format('YYYY-MM-DD'))
        const data = {
            timezone: timeZoneData,
            branchId: userData?.locationId || appointmentDetail.branchId,
            serviceId: bookAppointment.values.services ||  appointmentDetail?.serviceDetail?._id,
            staffId: userData?.userId,
            day: moment(day || calendarValue).format('dddd'),
            selectedDate: moment(day || calendarValue).format('YYYY-MM-DD'),
            availabilityId: staffAvailabilityId,
            providerId: userData?.createdbyId
        }
        axios.post(timeSlotApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSlots(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSlots([])
            }
        }).catch(() => { })
    }
    const getBranchList = () => {
        axios
            .post(staffBranches(), { userId: userData?.userId, providerId: userData?.createdbyId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchListData(resp.data.data)
                    calendarList(userData?.locationId)
                    // setCurrentBranch(resp.data.data[0]?._id)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setBranchListData([])
                    setCurrentBranch('')
                }
            })
            .catch(() => {
                // toast.error(commonMessage.networkError)
            })
    }
    const staffService = () => {
        axios.post(staffServices(), { userId: userData?.userId, providerId: userData?.createdbyId, page: 1, count: 100, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceList(resp.data.data)

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }

        }).catch(() => { })
    }
    const getClientList = () => {
        const data = {
            providerId: userData?.createdbyId,
            isActive: true,
            isAccepted: 'accepted'
        }
        axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setClientData(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const bookAppointment = useFormik({
        initialValues: {
            branch: '',
            clientName: '',
            mobileNumber: '',
            services: '',
            price: '',
            totalAmount: '',
            offerSelect: '',
            serviceName: '',
            offerFinalAmount: 0,
            offerName: '',
            visitType: '',
        },
        validationSchema: staffBookAppointmentValidation,
        onSubmit: (values) => {
            let data =
            {
                appArr: [{
                    // startDateTime: moment(date + '' + moment(slotTime.startTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
                    // endDateTime: moment(date + '' + moment(slotTime.endTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
                    startDateTime: slotTime.startTime,
                    endDateTime: slotTime.endTime,
                    timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData,
                    branchId: values.branch,
                    staffId: userData?.userId,
                    serviceId: values.services,
                    amount: values.totalAmount,
                    createdby_id: userData?.userId,
                    clientId: values.clientName.value,
                    providerId: userData?.createdbyId,
                    price: values.price,
                    offerId: values.offerSelect,
                    visitType: values.visitType,
                    mainofferData: [
                        {
                            offerName: values.offerName,
                            serviceName: values.serviceName,
                            Amount: values.price,
                            finalAmount: values.offerFinalAmount
                        }
                    ]
                }]
            }
            if (values.clientName.length === 0) return toast.warn("Please select client")
            if (values.totalAmount.length === 0) return toast.warn("Please select offer")
            // console.log(data, 'kkkkkkkk')
            // return
            setSpinner(true)
            axios.post(appointmentBookApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    bookAppointment.resetForm()
                    calendarList(appointmentDetail.branchId)
                    setShow(false)
                    setSpinner(false)
                    setStaffAvailabilityId([]);
                    setClientPerPage(1);
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)

                }
            }).catch(() => { })
        },
    })
    const mobFun = (e) => {
        let selectedClient = clientData.find((client) => client._id === e)
        bookAppointment.setFieldValue('mobileNumber', selectedClient.mobileNo)
    }
    const handleSelectEvent = useCallback((event) => {
        if (event.item.status === 'UNAVAILABILITY') {
            setModalView(false);
        } else {
            setModalView(true);
        }
        appointmentView(event.item._id);
        setAppointmentDetail(event.item);
    }, []);
    const appointmentView = (id) => {
        axios.post(appointmentViewApi(), { loggedInUserId: userData?.userId, appointmentId: id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentViewData(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const cancelAppointment = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: cancelAppointmentValidation,
        onSubmit: (values) => {
            const data = {
                appointmentId: appointmentDetail._id,
                timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData,
                startDateTime: appointmentDetail.startDateTime,
                endDateTime: appointmentDetail.endDateTime,
                clientId: appointmentDetail.clientId,
                staffId: appointmentDetail.staffId,
                reason: values.reason,
                status: 'CANCELLED'
            }
            axios.post(cancelappointmnetApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    calendarList(appointmentDetail.branchId)
                    setModalView(false)
                    setShowModal(false)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                }
            }).catch(() => { })
        }
    })
    const appointmentDelete = () => {
        axios.post(appointmentDeleteApi(), { id: appointmentViewData._id, timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                calendarList(appointmentDetail.branchId)
                setDeleteModal(false)
                deleteModal(false)
                setDeleteModal(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const appointmentReschedule = () => {
        const data = {
            appointmentId: appointmentDetail._id,
            // startDateTime: moment(date + '' + moment(slotTime.startTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            // endDateTime: moment(date + '' + moment(slotTime.endTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            startDateTime: slotTime.startTime,
            endDateTime: slotTime.endTime,
            timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData
        }
        setSpinner(true)
        axios.post(rescheduleAppointmentApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                calendarList(appointmentDetail.branchId)
                setShow(false)
                setShowReschedule(false)
                setSpinner(false)
                setSlots([])
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)

            }
        }).catch(() => { })
    }
    const priceFun = (e) => {
        setStaffAvailabilityId([])
        setSlots([])
        setOfferListData([])
        let selectService = serviceList.find((service) => service._id === e.target.value)
        bookAppointment.setFieldValue('price', selectService.variableAmount || selectService.amount)
        bookAppointment.setFieldValue('totalAmount', selectService.variableAmount || selectService.fixedAmount || selectService.amount)
        bookAppointment.setFieldValue('serviceName', selectService.name)
    }
    const getStaffList = (e) => {
        const data = {
            branchId: appointmentDetail?.branchId || userData?.locationId,
            serviceId: e || appointmentDetail?.serviceDetail?._id,
            providerId: userData?.createdbyId,
            userId: userData?.userId
        }
        axios.post(staffApi(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setStaffAvailabilityId(resp.data.data?.availabilityId)
                setShowSlot(true)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else if (resp.data.code === 404 && resp.data.message === 'No record found') {
                toast.warn("Team is not available of this branch")
            }
            else {
                setStaffAvailabilityId([])
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const loadClientOptions = async () => {
        let temp = clientData
        const data = {
            providerId: userData?.createdbyId,
            isActive: true,
            isAccepted: 'accepted',
            page: clientPerPage,
            isDeleted: false,
            count: 10
        }

        const response = await axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }, setClientPerPage(clientPerPage + 1))
        setClientData(response.data.data)
        if (response.data.code === 200) {
            setClientData([...temp, ...response.data.data])
        }
        if (response.data.code === 404) {
            setClientData([...temp])
        }
        else if (response.data.code === statusCodes.tokenExpire) {
            logOutFun()
        }
        return {
            options: response.data.data?.map(item => ({ value: item._id, label: item.name })),
            hasMore: response.data.data.length >= 1
        };

    }
    const calendarList = (e) => {

        const data = {
            staffId: userData?.userId,
            providerId: userData?.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: weekFilterDate.startDateFilter,
            endDateFilter: weekFilterDate.endDateFilter
        }
        const monthData = {
            staffId: userData?.userId,
            providerId: userData?.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: monthFilter.startDateFilter,
            endDateFilter: monthFilter.endDateFilter
        }
        const dayData = {
            staffId: userData?.userId,
            providerId: userData?.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: dayFilter.startDateFilter,
            endDateFilter: dayFilter.endDateFilter
        }
        setSpinner(true)
        axios.post(calendarAppointments(), daySelected === 'month' ? monthData : daySelected === 'day' ? dayData : data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentList(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                // toast.error(resp.data.message)
                setSpinner(false)
                setAppointmentList([])
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    const onRangeChange = (e) => {
        setweekFilterDate((pre) => ({ ...pre, startDateFilter: moment(e[0]).format(), endDateFilter: moment(e[e.length - 1]).format() }))
        setMonthFilter((pre) => ({ ...pre, startDateFilter: moment(e.start).format(), endDateFilter: moment(e.end).format() }))
        setDayFilter({ startDateFilter: moment(e[0]).startOf('day').utc(), endDateFilter: moment(e[0]).endOf('day').utc() })

    }
    const handleSelectSlot = (slotInfo) => {
        // setShow(true)
        setPopup(true)
        getClientList();
        staffService()
        setCalendarValue(slotInfo.start)
    };
    const offerList = (e) => {
        const data = {
            serviceId: e,
            clientId: bookAppointment.values.clientName.value,
            branchId: userData?.locationId
        }
        axios.post(getOffer(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setOfferListData(resp.data.data)
            }
            else {
                setOfferListData([])
            }
        }).catch(() => { })
    }
    const offerApply = (e) => {
        const data = {
            serviceId: bookAppointment.values.services,
            offerId: e,
            dateTime: moment(calendarValue).format(),
            roleTitle: userData?.roleTitle
        }

        axios.post(applyOffer(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                bookAppointment.setFieldValue('totalAmount', resp.data.data)
                bookAppointment.setFieldValue('amount', resp.data.data)
                bookAppointment.setFieldValue('offerFinalAmount', resp.data.data)
                let selectOffer = offerListData.find((item) => item._id === e)
                bookAppointment.setFieldValue('offerName', selectOffer.name)
            }
            else {
                toast.error(resp.data.message)
                bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price)
                bookAppointment.setFieldValue('offerSelect', '')

            }
        }).catch(() => { })
    }
    const timeZone = () => {
        axios.post(timeZoneApi(), { isActive: true, count: 500 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTimeZoneList(resp.data.data)
                setUnavailability.setFieldValue('timeZone', timeZoneData === 'Asia/Calcutta' || timeZoneData === 'Asia/Kolkata' ? resp.data.data[33].name : null)
            }
        }).catch(() => { })
    }
    const showAdditionalTimingsBox = (day) => {
        let formValues = defaultFormValue
        if (formValues[day].isAllowedTimes.first && formValues[day].isAllowedTimes.second) return toast.warn('Maximun 3 times allowed')
        if (!formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.first = true
        else if (formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.second = true
        setFormValue([...formValues])
    }
    const hideAdditionalTimingsBox = (day, index) => {
        let formValues = defaultFormValue
        switch (index) {
            case 1:
                formValues[day].isAllowedTimes.first = false
                break
            case 2:
                formValues[day].isAllowedTimes.second = false
                break
            default:
        }
        setFormValue([...formValues])
    }
    const setUnavailability = useFormik({
        initialValues: {
            timeZone: '',
            branch: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            secondStartTime: '',
            secondEndTime: '',
            thirdStartTime: '',
            thirdEndTime: '',

        },
        validationSchema: unAvailabilityValidation,
        onSubmit: (values) => {
            let data = {
                branchId: values.branch,
                timezone: values.timeZone,
                staffId: userData?.userId,
                providerId: userData?.createdbyId,
                startDate: values.startDate,
                endDate: values.endDate,
                timingArr: [],
            }

            if (setUnavailability.values.startTime > setUnavailability.values.endTime) return toast.warn('Start time can not be greater than end time')
            if (setUnavailability.values.startTime && setUnavailability.values.endTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.startTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.endTime).format('HH:mm'),
                })

            if (setUnavailability.values.secondStartTime > setUnavailability.values.secondEndTime) return toast.warn('Second start time time can not be greater than second end time')
            if (setUnavailability.values.secondStartTime && setUnavailability.values.secondEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.secondStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.secondEndTime).format('HH:mm'),
                })

            if (setUnavailability.values.thirdStartTime > setUnavailability.values.thirdEndTime) return toast.warn('Third start time time can not be greater than third end time')
            if (setUnavailability.values.thirdStartTime && setUnavailability.values.thirdEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.thirdStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.thirdEndTime).format('HH:mm'),
                })
            setSpinner(true)
            axios
                .post(unAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        setUnavailability.resetForm()
                        setSpinner(false)
                        setShowUnAvailability(false)
                        calendarList()

                    }
                    else if (resp.data.code === statusCodes.tokenExpire) {
                        logOutFun()
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)

                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    return {
        onShowMore,
        logOutFun,
        timesSlot,
        getBranchList,
        staffService,
        getClientList,
        mobFun,
        handleSelectEvent,
        appointmentView,
        offerApply,
        offerList,
        handleSelectSlot,
        onRangeChange,
        calendarList,
        loadClientOptions,
        getStaffList,
        priceFun,
        appointmentReschedule,
        appointmentDelete,
        navigate,
        setBranchListData,
        setServiceList,
        setAppointmentList,
        setClientData,
        setShow,
        setSpinner,
        setModalView,
        setAppointmentViewData,
        setAppointmentDetail,
        setShowModal,
        setDeleteModal,
        setDate,
        setStaffAvailabilityId,
        setClientPerPage,
        setweekFilterDate,
        setMonthFilter,
        setDayFilter,
        setCurrentBranch,
        setCalendarValue,
        setDaySelected,
        setShowSlot,
        setOfferListData,
        setSlots,
        setSlotTime,
        setShowReschedule,
        setPopup,
        popup,
        cancelAppointment,
        bookAppointment,
        branchListData,
        serviceList,
        appointmentList,
        timeZoneData,
        show,
        clientData,
        spinner,
        userData,
        EVENTS,
        localizer,
        modalView,
        appointmentViewData,
        showReschedule,
        appointmentDetail,
        showModal,
        deleteModal,
        slots,
        slotTime,
        date,
        staffAvailabilityId,
        clientPerPage,
        weekFilterDate,
        monthFilter,
        dayFilter,
        currentBranch,
        daySelected,
        calendarValue,
        showSlot,
        offerListData,
        setShowUnAvailability,
        timeZone,
        showUnAvailability,
        timeZoneList,
        defaultFormValue,
        setUnavailability,
        setTimeZoneList,
        showAdditionalTimingsBox,
        hideAdditionalTimingsBox,
        userInfo
        // teamMembersUnAvailibility,
        // teamAccordingBranch

    }
}

export default UseCalendar
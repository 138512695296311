/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { cancelAppointmentValidation, unAvailabilityValidationCalendar } from '../../../Context/validation'
import {
    logOutApi, calendarAppointments, appointmentBookApi, branchListApi, multipleServicesListApi, clientListApi, staffApi, timeSlotApi,
    cancelappointmnetApi, appointmentDeleteApi, appointmentViewApi, rescheduleAppointmentApi, serviceListApi, unavailabilityDeleteApi,
    getOffer, applyOffer, staffForCalendar, unAvailability, timeZoneApi
} from '../../../Context/api'
import { statusCodes, commonMessage, initialState, roleNames } from '../../../Constants/generalConfig'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import 'react-calendar/dist/Calendar.css'
import { momentLocalizer } from 'react-big-calendar';
import "../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css"
import { Notifications } from '../../../App'

const UseCalendar = () => {
    const { userInfo } = useContext(Notifications)
    const [branchListData, setBranchListData] = useState([])
    const [staffList, setStaffList] = useState([])
    const [serviceCategory, setServiceCategory] = useState([])
    const [serviceList, setServiceList] = useState()
    const [appointmentList, setAppointmentList] = useState([])
    const [slots, setSlots] = useState([])
    const timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [clientData, setClientData] = useState([])
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [appointmentDetail, setAppointmentDetail] = useState([])
    const [date, setDate] = useState('')
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const EVENTS = []
    const localizer = momentLocalizer(moment)
    const [modalView, setModalView] = useState(false);
    const [showReschedule, setShowReschedule] = useState(false)
    const [appointmentViewData, setAppointmentViewData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [unAvailabilityDelete, setUnAvailabilityDelete] = useState(false)
    const [slotTime, setSlotTime] = useState([])
    const [staffAvailabilityId, setStaffAvailabilityId] = useState('')
    const [clientPerPage, setClientPerPage] = useState(1)
    const [weekFilterDate, setweekFilterDate] = useState({
        startDateFilter: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))).startOf("week").utc(),
        endDateFilter: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6))).endOf("week").utc(),
    })
    const [monthFilter, setMonthFilter] = useState({
        startDateFilter: moment().startOf('month').startOf('week'),
        endDateFilter: moment().endOf('month').endOf('week')
    })
    const [calendarValue, setCalendarValue] = useState(new Date())
    const [staffId, setStaffId] = useState('')
    const [dayFilter, setDayFilter] = useState([])
    const [currentBranch, setCurrentBranch] = useState(userData?.locationId)
    const [daySelected, setDaySelected] = useState('month')
    const [multipleData, setMultipleData] = useState([])
    const [slotSelect, setSlotSelect] = useState([])
    let [multipleAppointments, setMultipleAppointments] = useState(null)
    const [singleSlot, setSingleSlot] = useState('')
    const [offerListData, setOfferListData] = useState([])
    const [teamMember, setTeamMember] = useState([])
    const onShowMore = useCallback((events, date) =>
        setDayFilter({ startDateFilter: moment(events[0].start).startOf('day').utc(), endDateFilter: moment(events[0].end).endOf('day').utc() }),
        []
    )
    const [currentTeam, setCurrentTeam] = useState('')
    const [offerId, setOfferId] = useState('')

    const [popup, setPopup] = useState(false)
    const [showUnAvailability, setShowUnAvailability] = useState(false)
    const [timeZoneList, setTimeZoneList] = useState([])
    const [defaultFormValue, setFormValue] = useState(initialState)
    const [teamAccordingBranch, setTeamAccodingBranch] = useState([])
    const [appointmentSlotModal, setAppointmentSlotModal] = useState({ open: false, data: '', index: '' })
    const [appointmentSlotBookAgain, setAppointmentSlotBookAgain] = useState(false)
    useEffect(() => {
        if (currentBranch) {
            calendarList()
        }
    }, [daySelected, monthFilter, dayFilter, weekFilterDate, currentTeam])

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        getBranchList()
    }, [])

    useEffect(() => {
        if (staffId) {
            timesSlot()
        }
    }, [staffId])

    const teamMembers = (id) => {
        const data = {
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: id,
            count: 100
        }
        axios.post(staffForCalendar(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTeamMember(resp.data.data)
                setCurrentTeam(resp.data.data[0]._id)
            }
            else {
                setTeamMember([])
            }
        })
    }
    const teamMembersUnAvailibility = (id) => {
        const data = {
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: id,
            count: 100
        }
        axios.post(staffForCalendar(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTeamAccodingBranch(resp.data.data)
            }
            else {
                setTeamAccodingBranch([])
            }
        })
    }
    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })

    }
    // appointmentList?.map((item) => {
    //     EVENTS.push({ title: item.status === 'UNAVAILABILITY' ? `Un-Available(${item.staffId == null ? item.providerName : item.StaffName})` : `${item.clientName}  ${moment(item.startDateTime).format('LT')}`, start: new Date(item.startDateTime), end: new Date(item.endDateTime), item })
    // })
    appointmentList?.forEach((item) => {
        EVENTS.push({
            title: item.status === 'UNAVAILABILITY' ? `Un-Available(${item.staffId == null ? item.providerName : item.StaffName})` : `${item.clientName} ${moment(item.startDateTime).format('LT')}`,
            start: new Date(item.startDateTime),
            end: new Date(item.endDateTime),
            item
        });
    });
    const timesSlot = (day) => {
        setDate(moment(day || calendarValue).format('YYYY-MM-DD'))
        const data = {
            timezone: timeZoneData,
            branchId: bookAppointment.values.branch || appointmentDetail.branchId,
            serviceId: bookAppointment.values.services || appointmentDetail?.serviceDetail?._id,
            staffId: bookAppointment.values.availabaleStaff || appointmentDetail.staffId || staffId,
            day: moment(day || calendarValue).format('dddd'),
            selectedDate: moment(day || calendarValue).format('YYYY-MM-DD'),
            availabilityId: staffAvailabilityId,
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId
        }
        axios.post(timeSlotApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                let finalResponse = []
                if (slotSelect) {
                    resp.data.data.forEach((res) => {
                        slotSelect.forEach((obj) => {
                            if (obj.startTime === res.startTime && obj.endTime === res.endTime) {
                                res.status = "Booked"
                                finalResponse.push(res)
                            }
                        })
                    })
                    setSlots(finalResponse)
                }
                setSlots(resp.data.data)
            }
            else if (resp.data.code === 405) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }

    const calendarList = (e) => {
        const data = {
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: weekFilterDate.startDateFilter,
            endDateFilter: weekFilterDate.endDateFilter,
            staffId: currentTeam
        }
        const monthData = {
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: monthFilter.startDateFilter,
            endDateFilter: monthFilter.endDateFilter,
            staffId: currentTeam
        }
        const dayData = {
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: e || currentBranch,
            startDateFilter: dayFilter.startDateFilter,
            endDateFilter: dayFilter.endDateFilter,
            staffId: currentTeam
        }
        setSpinner(true)
        axios.post(calendarAppointments(), daySelected === 'month' ? monthData : daySelected === 'day' ? dayData : data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentList(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
                setAppointmentList([])
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    const onRangeChange = (e) => {
        setweekFilterDate((pre) => ({ ...pre, startDateFilter: moment(e[0]).format(), endDateFilter: moment(e[e.length - 1]).format() }))
        setMonthFilter((pre) => ({ ...pre, startDateFilter: moment(e.start).format(), endDateFilter: moment(e.end).format() }))
        setDayFilter({ startDateFilter: moment(e[0]).startOf('day').utc(), endDateFilter: moment(e[0]).endOf('day').utc() })
    }
    const getBranchList = () => {
        axios
            .post(branchListApi(), { userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchListData(resp.data.data)
                    // setCurrentBranch(resp.data.data[0]?._id)
                    // teamMembers(resp.data.data[0]?._id)
                    teamMembers(userData?.locationId)


                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setBranchListData([])
                    setCurrentBranch("")
                }
            }).catch(() => { })
    }
    const getStaffList = (e) => {
        const data = {
            // branchId: appointmentDetail?.branchId || e,
            branchId: appointmentDetail?.branchId || userData?.locationId,
            // serviceId: e || appointmentDetail?.serviceId,
            serviceId: e || appointmentDetail?.serviceDetail?._id,
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId
        }
        axios.post(staffApi(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setStaffList(resp.data.data)
                if (appointmentDetail?.branchId) {
                    let availability = resp?.data?.data?.find((item) => item?.staffId === appointmentDetail?.staffId?.[0])
                    setStaffAvailabilityId(availability?.availabilityId)
                }

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else if (resp.data.code === 404 && resp.data.message === 'No record found') {
                toast.warn("Team is not available of this branch")
            }
            else {
                // toast.warn("Team is not available of this branch")
                // toast.error(resp.data.message)
                setStaffList([])
                setSlots([])
                bookAppointment.setFieldValue('availabaleStaff', '')
            }

        }).catch(() => { })
    }
// console.log(appointmentDetail,'appointmentDetail')
console.log(appointmentViewData,'appointmentViewData')
    const multipleServicesList = () => {
        axios.post(multipleServicesListApi(), { userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, count: 100, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceCategory(resp.data.data)
            }
        }).catch(() => { })
    }
    const bookAppointment = useFormik({
        initialValues: {
            branch: '',
            clientName: '',
            mobileNumber: '',
            serviceCategory: '',
            services: '',
            price: '',
            availabaleStaff: '',
            totalAmount: '',
            offerSelect: '',
            serviceName: '',
            visitType: ''
        },
        onSubmit: (values) => {
            // console.log(multipleAppointments, 'multipleAppointments')
            if (values.totalAmount.length === 0) return toast.warn("Please select offer")
            // console.log({ appArr: [...multipleData, multipleAppointments] }, 'AAAAAAAAAAA')
            // return
            setSpinner(true)
            axios.post(appointmentBookApi(), { appArr: [...multipleData, multipleAppointments] }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    bookAppointment.resetForm()
                    setMultipleData([])
                    setSlotSelect([])
                    setShow(false)
                    setSpinner(false)
                    calendarList(appointmentDetail.branchId)
                    setSlotTime([])
                    setSlots([])
                    setClientPerPage(1)
                    setStaffId('')
                    setMultipleAppointments("")
                    setAppointmentSlotBookAgain(false)

                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setMultipleData((pre) => (pre))
                    toast.error(resp.data.message)
                    setSpinner(false)

                }
            })
                .catch(() => {
                    setMultipleData((pre) => (pre))
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const dataFun = (item) => {
        let data = {
            // startDateTime: moment(date + '' + moment(item.startTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            // endDateTime: moment(date + '' + moment(item.endTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            startDateTime: item.startTime,
            endDateTime: item.endTime,
            timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData,
            branchId: bookAppointment.values.branch,
            staffId: bookAppointment.values.availabaleStaff,
            serviceId: bookAppointment.values.services,
            amount: bookAppointment.values.totalAmount,
            createdby_id: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            clientId: bookAppointment.values.clientName.value,
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            price: bookAppointment.values.price,
            visitType: bookAppointment.values.visitType,
            offerId: [],
            mainofferData: [
                {
                    offerName: '',
                    serviceName: bookAppointment.values.serviceName,
                    Amount: bookAppointment.values.price,
                    finalAmount: 0
                }
            ]
        }
        setSingleSlot({ startTime: item.startTime, endTime: item.endTime })
        setMultipleAppointments(data)

    }

    const handleSelectEvent = useCallback((event) => {
        if (event.item.status === 'UNAVAILABILITY') {
            setUnAvailabilityDelete(true);
            setModalView(false);
        } else {
            setUnAvailabilityDelete(false);
            setModalView(true);
        }
        appointmentView(event.item._id);
        setAppointmentDetail(event.item);
    }, []);

    const cancelAppointment = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: cancelAppointmentValidation,
        onSubmit: (values) => {
            const data = {
                appointmentId: appointmentDetail._id,
                timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData,
                startDateTime: appointmentDetail.startDateTime,
                endDateTime: appointmentDetail.endDateTime,
                clientId: appointmentDetail.clientId,
                staffId: appointmentDetail.staffId,
                reason: values.reason,
                status: 'CANCELLED'
            }
            setSpinner(true)
            axios.post(cancelappointmnetApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    calendarList(appointmentDetail.branchId)
                    cancelAppointment.resetForm()
                    setModalView(false)
                    setShowModal(false)
                    setSpinner(false)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            }).catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
        }
    })
    const appointmentDelete = () => {
        axios.post(appointmentDeleteApi(), { id: appointmentViewData._id, timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                calendarList(appointmentDetail.branchId)
                setDeleteModal()
                setDeleteModal(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                localStorage.removeItem('userData')
                navigate('/')
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const appointmentView = (id) => {
        axios.post(appointmentViewApi(), { loggedInUserId: userData?.userId, appointmentId: id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentViewData(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                localStorage.removeItem('userData')
                navigate('/')
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const appointmentReschedule = () => {
        const data = {
            appointmentId: appointmentDetail._id,
            // startDateTime: moment(date + '' + moment(slotTime.startTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            // endDateTime: moment(date + '' + moment(slotTime.endTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            startDateTime:slotTime.startTime,
            endDateTime:slotTime.endTime,
            timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData
        }
        setSpinner(true)
        axios.post(rescheduleAppointmentApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                calendarList(appointmentDetail.branchId)
                setShow(false)
                setShowReschedule(false)
                setSpinner(false)
                setSlots([])
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)

            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const mobFun = (e) => {
        let selectedClient = clientData.find((client) => client._id === e)
        bookAppointment.setFieldValue('mobileNumber', selectedClient.mobileNo)
    }
    const priceFun = (e) => {
        let selectService = serviceList.find((service) => service._id === e.target.value)
        bookAppointment.setFieldValue('totalAmount', selectService.variableAmount ? selectService.variableAmount : selectService.fixedAmount)
        bookAppointment.setFieldValue('price', selectService.variableAmount || selectService.fixedAmount)
        // appointmentReschedule.setFieldValue('price', selectService.variableAmount || selectService.fixedAmount)
        bookAppointment.setFieldValue('serviceName', selectService.name)
    }
    const servicesList = (e) => {
        const data = {
            categoryId: e,
            isActive: true,
            count: 100
        }
        axios.post(serviceListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceList(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const unavailabilityDelete = () => {
        axios.post(unavailabilityDeleteApi(), { unAvailabilityId: appointmentViewData.appointment_number }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setUnAvailabilityDelete(false)
                calendarList(appointmentDetail.branchId)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const teamAvailableId = (e) => {
        let selectedStaff = staffList.find((staff) => staff.staffId === e.target.value)
        setStaffAvailabilityId(selectedStaff.availabilityId)
    }
    const loadClientOptions = async () => {
        let temp = clientData
        const data = {
            userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            isActive: true,
            isAccepted: 'accepted',
            page: clientPerPage,
            isDeleted: false,
            isProviderClient: true,
            count: 10
        }
        const response = await axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }, setClientPerPage(clientPerPage + 1))
        setClientData(response.data.data)
        if (response.data.code === 200) {
            setClientData([...temp, ...response.data.data])
        }
        if (response.data.code === 404) {
            setClientData([...temp])
        }
        else if (response.data.code === statusCodes.tokenExpire) {
            logOutFun()
        }
        return {
            options: response.data.data?.map(item => ({ value: item._id, label: item.name })),
            hasMore: response.data.data.length >= 1
        };

    }
    const handleSelectSlot = (slotInfo) => {
        // setShow(true)
        setPopup(true)
        multipleServicesList()
        setCalendarValue(slotInfo.start)
    };
    const offerList = (e) => {
        const data = {
            serviceId: e,
            clientId: bookAppointment.values.clientName.value,
            branchId: userData?.locationId
        }
        axios.post(getOffer(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setOfferListData(resp.data.data)
            }
            else {
                setOfferListData([])
                // bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price)
            }
        }).catch(() => { })
    }
    const offerApply = (e) => {
        const data = {
            serviceId: bookAppointment.values.services,
            offerId: e,
            dateTime: moment(calendarValue).format(),
            roleTitle: userData?.roleTitle
        }
        axios.post(applyOffer(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                bookAppointment.setFieldValue('totalAmount', resp.data.data)
                let selectOffer = offerListData.find((item) => item._id === e)
                setMultipleAppointments((pre) => ({
                    ...pre, amount: resp.data.data, mainofferData: pre?.mainofferData?.map((item) => ({
                        ...item,
                        finalAmount: resp.data.data,
                        offerName: selectOffer.name
                    })),
                    offerId: [selectOffer._id]
                }));
                setOfferId(e)
            }
            else {
                toast.error(resp.data.message)
                bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price)
                bookAppointment.setFieldValue('offerSelect', '')
            }
        }).catch(() => { })
    }
    // Un-Availability
    const setUnavailability = useFormik({
        initialValues: {
            timeZone: '',
            branch: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            secondStartTime: '',
            secondEndTime: '',
            thirdStartTime: '',
            thirdEndTime: '',
            team: ''

        },
        validationSchema: unAvailabilityValidationCalendar,
        onSubmit: (values) => {
            let data = {
                branchId: values.branch,
                timezone: values.timeZone,
                staffId: values.team,
                // createdby_id: userData?.userId,
                providerId: userData?.userId,
                startDate: values.startDate,
                endDate: values.endDate,
                timingArr: [],
            }
            if (setUnavailability.values.startTime > setUnavailability.values.endTime) return toast.warn('Start time can not be greater than end time')
            if (setUnavailability.values.startTime && setUnavailability.values.endTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.startTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.endTime).format('HH:mm'),
                })

            if (setUnavailability.values.secondStartTime > setUnavailability.values.secondEndTime) return toast.warn('Second start time time can not be greater than second end time')
            if (setUnavailability.values.secondStartTime && setUnavailability.values.secondEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.secondStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.secondEndTime).format('HH:mm'),
                })

            if (setUnavailability.values.thirdStartTime > setUnavailability.values.thirdEndTime) return toast.warn('Third start time time can not be greater than third end time')
            if (setUnavailability.values.thirdStartTime && setUnavailability.values.thirdEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.thirdStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.thirdEndTime).format('HH:mm'),
                })
            // console.log('data',data)
            setSpinner(true)
            axios
                .post(unAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        setUnavailability.resetForm()
                        setSpinner(false)
                        setShowUnAvailability(false)
                        calendarList()

                    }
                    else if (resp.data.code === statusCodes.tokenExpire) {
                        logOutFun()
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)

                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const timeZone = () => {
        axios.post(timeZoneApi(), { isActive: true, count: 500 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTimeZoneList(resp.data.data)
                setUnavailability.setFieldValue('timeZone', timeZoneData === 'Asia/Calcutta' || timeZoneData === 'Asia/Kolkata' ? resp.data.data[33].name : null)
            }
        }).catch(() => { })
    }
    const showAdditionalTimingsBox = (day) => {
        let formValues = defaultFormValue
        if (formValues[day].isAllowedTimes.first && formValues[day].isAllowedTimes.second) return toast.warn('Maximun 3 times allowed')
        if (!formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.first = true
        else if (formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.second = true
        setFormValue([...formValues])
    }
    const hideAdditionalTimingsBox = (day, index) => {
        let formValues = defaultFormValue
        switch (index) {
            case 1:
                formValues[day].isAllowedTimes.first = false
                break
            case 2:
                formValues[day].isAllowedTimes.second = false
                break
            default:
        }
        setFormValue([...formValues])
    }
    // console.log(bookAppointment.values.branch,'bookAppointment.values.branch')
    return {
        logOutFun,
        teamMembers,
        timesSlot,
        calendarList,
        onRangeChange,
        getBranchList,
        getStaffList,
        multipleServicesList,
        handleSelectEvent,
        dataFun,
        offerApply,
        offerList,
        handleSelectSlot,
        loadClientOptions,
        teamAvailableId,
        unavailabilityDelete,
        servicesList,
        priceFun,
        mobFun,
        appointmentReschedule,
        appointmentView,
        appointmentDelete,
        navigate,
        setBranchListData,
        setStaffList,
        setServiceList,
        setServiceCategory,
        setAppointmentList,
        setSlots,
        setClientData,
        setModalView,
        setShowReschedule,
        setAppointmentViewData,
        setDeleteModal,
        setUnAvailabilityDelete,
        setSlotTime,
        setStaffAvailabilityId,
        setShow,
        setShowModal,
        setSpinner,
        setAppointmentDetail,
        setDate,
        setClientPerPage,
        setweekFilterDate,
        setMonthFilter,
        setCalendarValue,
        setStaffId,
        setDayFilter,
        setSlotSelect,
        setMultipleAppointments,
        setSingleSlot,
        setOfferListData,
        setTeamMember,
        onShowMore,
        setCurrentTeam,
        setOfferId,
        setCurrentBranch,
        setDaySelected,
        setMultipleData,
        setPopup,
        setAppointmentSlotModal,
        setAppointmentSlotBookAgain,
        appointmentSlotBookAgain,
        appointmentSlotModal,
        popup,
        showModal,
        slots,
        timeZoneData,
        bookAppointment,
        cancelAppointment,
        branchListData,
        staffList,
        serviceCategory,
        serviceList,
        appointmentList,
        clientData,
        show,
        spinner,
        appointmentDetail,
        date,
        userData,
        EVENTS,
        localizer,
        modalView,
        showReschedule,
        appointmentViewData,
        deleteModal,
        unAvailabilityDelete,
        slotTime,
        staffAvailabilityId,
        clientPerPage,
        weekFilterDate,
        monthFilter,
        calendarValue,
        staffId,
        dayFilter,
        currentBranch,
        daySelected,
        multipleData,
        slotSelect,
        multipleAppointments,
        singleSlot,
        offerListData,
        teamMember,
        currentTeam,
        offerId,
        setUnavailability,
        showUnAvailability,
        setShowUnAvailability,
        timeZone,
        timeZoneList,
        defaultFormValue,
        setTimeZoneList,
        showAdditionalTimingsBox,
        hideAdditionalTimingsBox,
        teamMembersUnAvailibility,
        teamAccordingBranch,
        userInfo
    }
}

export default UseCalendar
import React, { useEffect, useState } from 'react'
import Master from '../../Assets/images/Mastercard-logo.svg.png';
import Visa from '../../Assets/images/Visa-Symbol.png';
import American from '../../Assets/images/americanExpress.png';
import { checkTstripeToken, stripePayment, getSavedCard, stripePaymentSavedCard } from '../../Context/api'
import { paymentValidation } from "../../Context/validation";
import Spinner from '../../Components/spinner/spinner'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import ValidationMessage from '../ValidationMessage/validationMessage';
import { useNavigate, useParams } from 'react-router-dom';
import CardChip from '../../Assets/images/cardChip.png';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const StripePayment = () => {
  const navigate = useNavigate()
  const [totalAmount, setTotalAmount] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [cardData, setCardData] = useState({})

  const userData = JSON.parse(localStorage.getItem('userData'))
  const { token } = useParams()
  const [paymentMethod, setPaymentMethod] = React.useState('NEWCARD');


  useEffect(() => {
    tokenCheck()

  }, [])


  const tokenCheck = () => {
    setSpinner(true)
    axios.post(checkTstripeToken(), { token: token }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTotalAmount(resp.data)
        setSpinner(false)
      } else {
        setSpinner(false)
        navigate('wrong-token')
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const amount = useFormik({
    initialValues: {
      card: '',
      expMonth: '',
      expYear: '',
      cvv: '',
    },
    validationSchema: paymentValidation,
    onSubmit: (values) => {
      const data = {
        number: values.card.replace(/ /g, ''),
        exp_year: values.expYear,
        exp_month: values.expMonth,
        cvc: values.cvv,
        token: token
      }
      // console.log(data,'lllllll')
      setSpinner(true)
      axios.post(stripePayment(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setSpinner(false)
          toast.success(resp.data.message)
          amount.resetForm()
          setTimeout(() => {
            navigate('/')
          }, 1000);

        } else {
          setSpinner(false)
          toast.error(resp.data.message)
        }
      })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    }
  })
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\s/g, ''); // Remove existing spaces
    const formattedValue = value.replace(/(.{4})/g, '$1 ').trim(); // Add space after every 4 characters
    amount.setFieldValue('card', formattedValue)
  };
  const getCard = () => {
    setSpinner(true)
    axios.post(getSavedCard(), { token: token }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCardData(resp.data.data)
        setSpinner(false)
      } else {
        setSpinner(false)
        setCardData([])
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const paymentWithCard = () => {
    setSpinner(true)
    axios.post(stripePaymentSavedCard(), { token: token }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTimeout(() => {
          navigate('/')
        }, 1000);
        setSpinner(false)
      } else {
        setSpinner(false)
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }

  const handleChange = (event) => {
    if (event.target.value === 'SAVEDCARD') {
      getCard()
    }
    setPaymentMethod(event.target.value);
  };
  return (
    <div>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className='payment'>
        <div className="d-flex justify-content-around p-3 _paymentCard">
          <h5 className='fw-bold mt-2'>Amount</h5>
          <h5 className='fw-bold mt-2'>{totalAmount?.totalCount} {totalAmount?.data}</h5>
        </div>
        <div>
          <div className='d-flex justify-content-center mt-3'>
            <FormControlLabel value="NEWCARD" control={<Radio onClick={handleChange} checked={paymentMethod === 'NEWCARD'} />} label="New Card" />
            <FormControlLabel value="SAVEDCARD" control={<Radio onClick={handleChange} checked={paymentMethod === 'SAVEDCARD'} />} label="Saved Card" />
          </div>
        </div>
        {
          paymentMethod === 'NEWCARD' &&
          <>
            <hr />
            <h4 className='fw-bold text-center'>Card Details</h4>
            <div className='d-flex justify-content-around'>
              <img className='mt-4' src={Master} alt="Master Card" height={45} width={60} />
              <img className='mt-4' src={Visa} alt="Visa Card" height={50} width={60} />
              <img className='mt-1' src={American} alt="American Card" height={80} width={100} />
            </div>
            <div className='mt-5 mb-3'>
              <input type="text" maxLength={19} className='form-control mt-4' placeholder='Card number' name="card" value={amount.values.card} onChange={handleInputChange}
              />
              <ValidationMessage formName={amount} name={'card'} />
              <div className='row mt-4'>
                <div className="col-sm-4">
                  <input type="number" className='form-control' maxLength={2} placeholder="MM" data-slots="my" name='expMonth' value={amount.values.expMonth} onChange={amount.handleChange}
                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                  <ValidationMessage formName={amount} name={'expMonth'} />
                </div>
                <div className="col-sm-4">
                  <input type="number" className='form-control' maxLength={2} placeholder="YY" data-slots="my" name='expYear' value={amount.values.expYear} onChange={amount.handleChange}
                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                  <ValidationMessage formName={amount} name={'expYear'} />
                </div>
                <div className="col-sm-4">
                  <input type="number" className='form-control' placeholder="CVV" data-slots="my" name='cvv' value={amount.values.cvv} onChange={amount.handleChange}
                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={4} />
                  <ValidationMessage formName={amount} name={'cvv'} />
                </div>
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <button className='btn btn_Style ps-5 pe-5 mt-4' type='submit' onClick={amount.handleSubmit}>Pay</button>
              </div>
            </div>
          </>
        }
        {
          paymentMethod === 'SAVEDCARD' &&
          <>
            <div className="height-100 d-flex mt-5">
              <div className="cards p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <img src={CardChip} width="50" alt='' />
                  <h2>{cardData?.brand?.toUpperCase()}</h2>
                </div>
                <div className="px-2 number mt-3 d-flex justify-content-center">
                  <span>**** **** **** {cardData.last4}</span>
                </div>
                <div className="p-4 card-border mt-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="cardholder">Card Holder</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="name">{cardData.name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <button className='btn btn_Style ps-5 pe-5 mt-4' type='submit' onClick={paymentWithCard}>Pay</button>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default StripePayment
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { appointmentListApi, appointmentDeleteApi, filterListApi, cancelappointmnetApi, rescheduleAppointmentApi, timeSlotApi, logOutApi, staffApi, clientComboList, comboImgPath } from '../../../Context/api'
import { cancelAppointmentValidation } from '../../../Context/validation'
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { useContext, useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment/moment'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App'

const UseAppointmentList = () => {
  const { userInfo } = useContext(Notifications)
  const [appointmentListData, setAppointmentListData] = useState([])
  const userData = JSON.parse(localStorage.getItem('userData'))
  const status = JSON.parse(localStorage.getItem('status'))
  const [modalData, setModalData] = useState(' ')
  const timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [branchList, setBranchList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [clientList, setClientList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const PER_PAGE = 10
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [slotTime, setSlotTime] = useState([])
  const [slots, setSlots] = useState([])
  const [date, setDate] = useState('')
  const [tab, setTab] = useState(1)
  const [clientComboData, setClientComboData] = useState([])

  const navigate = useNavigate()
  const [statusNames, setStatusNames] = useState([
    {
      name: 'Accepted',
      value: 'ACCEPTED',
      _id: 1
    },
    {
      name: 'Pending',
      value: 'PENDING',
      _id: 2
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: 3
    },
  ])
  const [val, setVal] = useState({
    branchFil: '',
    teamFil: '',
    clientFil: '',
    serviceFil: '',
    appointmentFilter: '',
  })
  const [dateFilter, setDateFilter] = useState('')
  const [filterInput, setFilterInput] = useState({
    team: [],
    branch: [],
    client: [],
    status: [],
    service: [],
    endDate: '',
    startDate: '',
  })
  const [availabilityId, setAvailabilityId] = useState('')

  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      if (tab === 1) {
        getAppointmentData()
        filterList()
      }
      if (tab === 2) {
        clientCombo()
      }
    }
  }, [page])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }
  const filterList = () => {
    axios.post(filterListApi(), { userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setBranchList(resp.data.data.branchName)
        setClientList(resp.data.data.clientData)
        setServiceList(resp.data.data.serviceName)
        setTeamList(resp.data.data.staffData)
      }
      else {
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  const cancelAppointment = useFormik({
    initialValues: { reason: '' },
    validationSchema: cancelAppointmentValidation,
    onSubmit: (values) => {
      const data = {
        appointmentId: modalData._id,
        timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData,
        startDateTime: modalData.startDateTime,
        endDateTime: modalData.endDateTime,
        clientId: modalData.clientId,
        staffId: modalData.staffId,
        reason: values.reason,
        status: 'CANCELLED'
      }
      setSpinner(true)
      axios.post(cancelappointmnetApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          getAppointmentData()
          setShowModal(false)
          cancelAppointment.resetForm()
          setSpinner(false)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
          setSpinner(false)
        }
      }).catch(() => { toast.error(commonMessage.networkError); setSpinner(false) })
    }

  })
  const getAppointmentData = () => {
    let data = {
      page: page,
      count: PER_PAGE,
      isDeleted: false,
      providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
      getDataOf: dateFilter.length || status?.status ? 'CUSTOM' : 'UPCOMING_APPOINTMENTS',
      branches: filterInput.branch.length ? filterInput.branch : [],
      clients: filterInput.client.length ? filterInput.client : [],
      staffs: filterInput.team.length ? filterInput.team : [],
      services: filterInput.service.length ? filterInput.service : [],
      status: filterInput.status.length ? filterInput.status : [],
      branchId: userData?.locationId
    }
    if (status?.status) {
      data.status.push(status?.status)
    }
    if (dateFilter === 'TODAY') {
      data.startDateFilter = moment().startOf('day').utc()
      data.endDateFilter = moment().endOf('day').utc()
    } else if (dateFilter === 'LAST7') {
      data.startDateFilter = moment().startOf('day').subtract(7, 'days').utc()
      data.endDateFilter = moment().startOf('day').utc()
    } else if (dateFilter === 'LAST30') {
      data.startDateFilter = moment().startOf('day').subtract(30, 'days').utc()
      data.endDateFilter = moment().startOf('day').utc()
    }
    if (status?.day === 'TODAY') {
      data.startDateFilter = moment().startOf('day').utc()
      data.endDateFilter = moment().endOf('day').utc()
    }
    if (status?.day === 'LAST15') {
      data.startDateFilter = moment().startOf('day').subtract(15, 'days').utc()
      data.endDateFilter = moment().startOf('day').utc()
    }
    if (status?.day === 'LAST_MONTH') {
      data.startDateFilter = moment().startOf('day').subtract(30, 'days').utc()
      data.endDateFilter = moment().startOf('day').utc()
    }
    if (dateFilter === 'CUSTOM' && filterInput.startDate === '' && filterInput.endDate === '') return toast.warn('Please select start date and end date ')
    if (dateFilter === 'CUSTOM' && filterInput.startDate === '') return toast.warn('Please select start date')
    if (dateFilter === 'CUSTOM' && filterInput.endDate === '') return toast.warn('Please select end date')
    if (filterInput.startDate > filterInput.endDate) return toast.warn('Start date can not be greater than end date ')
    else if (dateFilter === 'CUSTOM') {
      data.startDateFilter = moment(filterInput.startDate).startOf('day').utc()
      data.endDateFilter = moment(filterInput.endDate).endOf('day').utc()
    }
    setSpinner(true)
    axios.post(appointmentListApi(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setAppointmentListData(resp.data)
        setSpinner(false)
        setCount(resp.data.totalCount)
        setShowFilter(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setCount([])
        setAppointmentListData([])
        setSpinner(false)
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault()
    setFilterInput((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  const setBranches = (event, branchId, i) => {
    let temp = branchList
    let filterInputs = filterInput
    if (event.target.checked) {
      filterInputs.branch.push(branchId)
      temp[i]['isChecked'] = true
    }
    else {
      filterInputs.branch = filterInputs.branch.filter((id) => id !== branchId)
      temp[i]['isChecked'] = false
    }
    setBranchList([...temp])
    setFilterInput(filterInputs)
  }
  const setTeam = (event, teamId, i) => {
    let temp = teamList
    let filterInputs = filterInput
    if (event.target.checked) {
      filterInputs.team.push(teamId)
      temp[i]['isChecked'] = true
    }
    else {
      filterInputs.team = filterInputs.team.filter((id) => id !== teamId)
      temp[i]['isChecked'] = false
    }
    setTeamList([...temp])
    setFilterInput(filterInputs)
  }
  const setClient = (event, clientId, i) => {
    let temp = clientList
    let filterInputs = filterInput
    if (event.target.checked) {
      filterInputs.client.push(clientId)
      temp[i]['isChecked'] = true
    }
    else {
      filterInputs.client = filterInputs.client.filter((id) => id !== clientId)
      temp[i]['isChecked'] = false
    }
    setClientList([...temp])
    setFilterInput(filterInputs)

  }
  const setService = (event, serviceId, i) => {
    let temp = serviceList
    let filterInputs = filterInput
    if (event.target.checked) {
      filterInputs.service.push(serviceId)
      temp[i]['isChecked'] = true
    }
    else {
      filterInputs.service = filterInputs.service.filter((id) => id !== serviceId)
      temp[i]['isChecked'] = false
    }
    setServiceList([...temp])
    setFilterInput(filterInputs)
  }
  const setStatus = (event, statusId, i) => {
    let temp = statusNames
    let filterInputs = filterInput
    if (event.target.checked) {
      filterInputs.status.push(statusId)
      temp[i]['isChecked'] = true
    }
    else {
      filterInputs.status = filterInputs.status.filter((id) => id !== statusId)
      temp[i]['isChecked'] = false
    }
    setStatusNames([...temp])
    setFilterInput(filterInputs)
  }
  const clearAllFilter = () => {
    setFilterInput({ team: [], branch: [], client: [], status: [], service: [], endDate: '', startDate: '', })
    setDateFilter('')


    let branch = branchList
    branch.map((branch) => (branch['isChecked'] = false))
    setBranchList([...branch])

    let team = teamList
    team.map((team) => team.isChecked && (team.isChecked = false))
    setTeamList(team)

    let client = clientList
    client.map((client) => client.isChecked && (client.isChecked = false))
    setClientList(client)

    let service = serviceList
    service.map((service) => service.isChecked && (service.isChecked = false))
    setServiceList(service)

    let status = statusNames
    status.map((item) => item.isChecked && (item.isChecked = false))
    setStatusNames(status)
  }
  const timesSlot = (day) => {
    setDate(moment(day).format('YYYY-MM-DD'))
    const data = {
      timezone: timeZoneData,
      branchId: modalData.branchId,
      serviceId: modalData.serviceId,
      staffId: modalData.staffId,
      day: moment(day).format('dddd'),
      selectedDate: moment(day).format('YYYY-MM-DD'),
      availabilityId: availabilityId,
      providerId: userData?.userId
    }
    axios.post(timeSlotApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setSlots(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  const appointmentReschedule = () => {
    const data = {
      appointmentId: modalData._id,
      startDateTime: moment(date + '' + moment(slotTime.startTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
      endDateTime: moment(date + '' + moment(slotTime.endTime).format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
      timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData
    }
    setSpinner(true)
    axios.post(rescheduleAppointmentApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        toast.success(resp.data.message)
        getAppointmentData()
        setShow(false)
        // appointmentReschedule.resetForm()
        setSpinner(false)
        setSlotTime([])
        setSlots([])
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
        setSpinner(false)
      }
    }).catch(() => {
      toast.error(commonMessage.networkError)
      setSpinner(false)
    })
  }
  const handleChange = (e) => {
    setVal({ ...val, [e.target.name]: e.target.value })
  }
  const appointmentDelete = () => {
    setSpinner(true)
    axios.post(appointmentDeleteApi(), { id: modalData._id, timezone: timeZoneData === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZoneData }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        toast.success(resp.data.message)
        getAppointmentData()
        setShowDelete(false)
        setSpinner(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
        setSpinner(false)
      }
    }).catch(() => {
      setSpinner(false)
      toast.error(commonMessage.networkError)
    })
  }

  const getStaffList = (item) => {
    const data = {
      branchId: item.branchId,
      serviceId: item.serviceId,
      providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId
    }
    axios.post(staffApi(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        let availabilityId = resp.data.data.find((data) => data.staffId === item?.staffId?.[0])
        setAvailabilityId(availabilityId?.availabilityId)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else if (resp.data.code === 404 && resp.data.message === 'No record found') {
        toast.warn("Team is not available of this branch")
      }

      else {
        setAvailabilityId('')
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }

  const clientCombo = () => {
    setSpinner(true)
    axios.post(clientComboList(), { page: page, count: PER_PAGE }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setClientComboData(resp.data.data)
        setCount(resp.data.totalCount)
        setSpinner(false)
      }
      else {
        setClientComboData([])
        setSpinner(false)
      }
    })
  }

  return {
    appointmentDelete,
    handleChange,
    appointmentReschedule,
    timesSlot,
    clearAllFilter,
    setStatus,
    setService,
    setClient,
    setTeam,
    setBranches,
    handleInputChange,
    getAppointmentData,
    setAppointmentListData,
    setModalData,
    setBranchList,
    setTeamList,
    setClientList,
    setServiceList,
    setSpinner,
    setCount,
    setPage,
    setShow,
    setShowModal,
    filterList,
    logOutFun,
    setShowFilter,
    setSlotTime,
    setSlots,
    setDate,
    navigate,
    setStatusNames,
    setVal,
    setDateFilter,
    setFilterInput,
    getStaffList,
    setShowDelete,
    setTab,
    clientCombo,
    tab,
    comboImgPath,
    clientComboData,
    showDelete,
    cancelAppointment,
    appointmentListData,
    userData,
    status,
    modalData,
    timeZoneData,
    branchList,
    teamList,
    clientList,
    serviceList,
    spinner,
    count,
    page,
    PER_PAGE,
    show,
    showModal,
    showFilter,
    slotTime,
    slots,
    date,
    statusNames,
    val,
    dateFilter,
    filterInput,
    userInfo
  }
}

export default UseAppointmentList
import React from 'react'
import { ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import 'react-calendar/dist/Calendar.css'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Spinner from '../../Components/spinner/spinner'
import ValidationMessage from '../ValidationMessage/validationMessage';
import { Calendar } from 'react-big-calendar';
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css"
import { Modal } from 'react-bootstrap'
import Calendars from 'react-calendar';
import ScrollBars from 'react-scrollbar'
import { AsyncPaginate } from "react-select-async-paginate";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UseCalendar from './Hooks/useCalendar'
import AddTeamUnAvailability from '../Team/addTeamUnAvailability'
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { intialRole } from '../../Utils/Utils'

const AddAppointment = () => {
    const { onShowMore,
        timesSlot,
        getBranchList,
        staffService,
        getClientList,
        mobFun,
        handleSelectEvent,
        offerApply,
        offerList,
        handleSelectSlot,
        onRangeChange,
        calendarList,
        loadClientOptions,
        getStaffList,
        priceFun,
        appointmentReschedule,
        appointmentDelete,
        navigate,
        setShow,
        setModalView,
        setShowModal,
        setDeleteModal,
        setStaffAvailabilityId,
        setClientPerPage,
        setCurrentBranch,
        setCalendarValue,
        setDaySelected,
        setOfferListData,
        setSlots,
        setSlotTime,
        setShowReschedule,
        setPopup,
        popup,
        cancelAppointment,
        bookAppointment,
        branchListData,
        serviceList,
        show,
        spinner,
        EVENTS,
        localizer,
        modalView,
        appointmentViewData,
        showReschedule,
        showModal,
        deleteModal,
        slots,
        slotTime,
        staffAvailabilityId,
        currentBranch,
        calendarValue,
        offerListData,
        showUnAvailability,
        setShowUnAvailability,
        timeZone,
        setUnavailability,
        defaultFormValue,
        timeZoneList,
        showAdditionalTimingsBox,
        hideAdditionalTimingsBox,
        userInfo,
        userData
    } = UseCalendar()


    return (
        <>
            <ToastContainer />
            <section>
                <div className="m-t-3 cus-padd cusheight">
                    {spinner ? <Spinner /> : null}
                    <h2 className="page-tittle">
                        <i className="fa-solid fa-angle-left cusgap" onClick={() => navigate(-1)}></i>Calendar
                    </h2>
                    <hr />
                    {
                        intialRole({ permission: "appointment", userData: userInfo, section: 'viewCalender' }) ?
                            <>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <select className='mb-2 form-control' value={currentBranch} disabled onChange={(e) => { calendarList(e.target.value); setCurrentBranch(e.target.value) }} aria-label="Default select example" name="branch">
                                            <option hidden>Select Branch</option>
                                            {branchListData?.map((item) => (
                                                <option value={item._id} key={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <Calendar
                                    localizer={localizer}
                                    events={EVENTS}
                                    step={15}
                                    // popup
                                    onShowMore={onShowMore}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 620 }}
                                    views={{ week: true, month: true, day: true }}
                                    defaultView="month"
                                    onSelectEvent={handleSelectEvent}
                                    onSelectSlot={handleSelectSlot}
                                    selectable
                                    eventPropGetter={(event) => {
                                        const backgroundColor = event.item.status === 'UNAVAILABILITY' ? '#ABB2B9' : '#fbbf9e';
                                        return { style: { backgroundColor } }
                                    }}
                                    onView={(e) => { setDaySelected(e) }}
                                    onRangeChange={(e) => { onRangeChange(e) }}

                                />
                            </>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>

                {
                    intialRole({ permission: "appointment", userData: userInfo, section: 'createAppointment' }) ?
                        <div className="d-flex justify-content-end position-relative">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Create Appointment</Tooltip>}>
                                <button className="btn addbtn2">
                                    <i className="fa-solid fa-plus" onClick={() => { getClientList(); staffService(); setShow(true); bookAppointment.setFieldValue('branch', userData?.locationId) }}></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                        : null
                }
            </section>

            {/* Create Appointment Start */}
            {/* <Backdrop open={show}> */}
            <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                <div style={{ padding: '10px' }}>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); bookAppointment.resetForm(); setStaffAvailabilityId([]); setClientPerPage(1); setOfferListData([]) }}></button>
                    <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                        Create Appointment
                    </h1>
                </div>
                <div className="offcanvas-body">
                    <form className="cus-form" onSubmit={bookAppointment.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Client Name *</label>
                            <AsyncPaginate loadOptions={loadClientOptions} name="clientName" value={bookAppointment.values.clientName}
                                onChange={(e) => {
                                    bookAppointment.setFieldValue('clientName', e)
                                    mobFun(e.value)
                                    // offerList(e.value)
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Booking Type *</label>
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                name="visitType"
                                value={bookAppointment.values.visitType}
                                onChange={bookAppointment.handleChange}
                                disabled={bookAppointment.values.clientName.value?.length ? false : true}
                            >
                                <option hidden>Select Booking Type</option>
                                <option value='INOFFICE'>In-Office</option>
                                <option value='HOME'>Home</option>
                                <option value='ONLINE'>Online</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Services *</label>
                            <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                name="services"
                                disabled={bookAppointment.values.visitType?.length ? false : true}
                                value={bookAppointment.values.services}
                                onChange={(e) => {
                                    bookAppointment.setFieldValue('services', e.target.value)
                                    offerList(e.target.value)
                                    priceFun(e)
                                    getStaffList(e.target.value)

                                }}
                            >
                                <option hidden>Select Service </option>
                                {serviceList?.map((item) => (
                                    <option value={item._id} key={item.id}>
                                        {item.name} {`(${item.duration} Minutes)`}
                                    </option>
                                ))}
                            </select>
                            <ValidationMessage formName={bookAppointment} name={'services'} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Price *</label>
                            <input type="number" className="form-control" name="price" value={bookAppointment.values.price} onChange={bookAppointment.handleChange}
                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={6} />
                            <ValidationMessage formName={bookAppointment} name={'price'} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Branch *</label>
                            <select className="form-control" aria-label="Default select example" name="branch" value={bookAppointment.values.branch} disabled>
                                <option hidden>Select Branch</option>
                                {branchListData?.map((item, index) => (
                                    <option value={item._id} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <ValidationMessage formName={bookAppointment} name={'branch'} />
                        </div>
                        {
                            !bookAppointment?.values?.mobileNumber?.length ? null
                                :
                                <div className="mb-3">
                                    <label className="form-label">Mobile Number</label>
                                    <input type="text" className="form-control text-muted" name="mobileNumber" disabled value={bookAppointment.values.mobileNumber} onChange={bookAppointment.handleChange} />
                                </div>
                        }
                        {
                            staffAvailabilityId?.length ?
                                <>
                                    <div>
                                        <Calendars onChange={(e) => {
                                            timesSlot(e); setSlotTime([]); setCalendarValue(e); bookAppointment.setFieldValue('offerSelect', [])
                                            bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price)
                                        }} value={calendarValue} />
                                        <ScrollBars horizontal smoothScrolling style={{ height: '200px', width: '100%', marginTop: '10px' }}>
                                            <div className="form-check mt-2">
                                                {slots.length ? null : <h4 className='text-center'>No Slots Available</h4>}
                                                {slots?.map((item, index) => (
                                                    <>
                                                        <div className='row'>
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor:  (item.isActive ? '#f47d3a' : '#fff'),
                                                                    color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (item.isActive ? '#fff' : '#f47d3a')), width: '300px', marginLeft: '2px',
                                                                    border:(item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #f47d3a')
                                                                }}
                                                                className="btn me-5 mb-2 col-sm-12"
                                                                disabled={item.status === 'Booked'||item.status === 'Unavailable' ? true : false}
                                                                onClick={() => {
                                                                        setSlots((prevState) => {
                                                                            const newData = prevState.map((el, i) => {
                                                                                if (i === index) return { ...el, isActive: true }
                                                                                else return { ...el, isActive: false }
                                                                            })
                                                                            return [...newData]
                                                                        })
                                                                        setSlotTime(item)
                                                                    
                                                                }}
                                                            >
                                                                {moment(item.startTime).format('LT')} - {moment(item.endTime).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                                                            </button>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </ScrollBars>
                                        {
                                            !offerListData.length ? null
                                                :

                                                <div className="mb-3 mt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">Offer List</label>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-10 px-0">
                                                            <select className="form-select form-control ms-3" aria-label="Default select example" name='offerSelect' value={bookAppointment.values.offerSelect}
                                                                onChange={(e) => {
                                                                    offerApply(e.target.value)
                                                                    // console.log(e.target.value,'YYYYYYY')
                                                                    bookAppointment.setFieldValue('offerSelect', [e.target.value])
                                                                }}
                                                            >
                                                                <option hidden>Select Offer</option>
                                                                {offerListData?.map((item) => (
                                                                    <option value={item._id} key={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className={`col-1 ms-4 ${bookAppointment.values.offerSelect.length ? "delete_offer" : "delete_offer_disable"}`}>
                                                            <i className={`fa-solid fa-xmark ${bookAppointment.values.offerSelect.length ? "cursor" : null} `} style={{ marginTop: '10px' }} onClick={() =>
                                                                bookAppointment.values.offerSelect.length ? (
                                                                    bookAppointment.setFieldValue('offerSelect', []),
                                                                    bookAppointment.setFieldValue('offerFinalAmount', 0),
                                                                    bookAppointment.setFieldValue('offerName', ''),
                                                                    bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price)
                                                                ) : null}></i>
                                                        </div>

                                                        {/* <div className={`col-1 ms-4 ${bookAppointment.values.offerSelect.length ? "delete_offer" : "delete_offer_disable"}`}>
                                                            <i className={`fa-solid fa-xmark ${bookAppointment.values.offerSelect.length ? "cursor" : null} `} style={{ marginTop: '10px' }} onClick={() =>
                                                                bookAppointment.values.offerSelect.length ? 
                                                                (bookAppointment.setFieldValue('offerSelect', ''))
                                                                (bookAppointment.setFieldValue('totalAmount', bookAppointment.values.price))
                                                                    : null}></i>
                                                        </div> */}
                                                    </div>
                                                </div>
                                        }
                                        <div className="mb-5">
                                            <label className="form-label">Total Amount *</label>
                                            <input type="text" className="form-control text-muted" name="totalAmount" disabled value={bookAppointment.values.totalAmount} />
                                        </div>
                                    </div>
                                    <div className="d-grid  col-6 mx-auto mb-5">
                                        <button type="submit" disabled={slotTime.length === 0 ? true : false} className="btn colorbtun popupbtn px">
                                            Book Appointment
                                        </button>
                                    </div>
                                </>
                                : null}
                    </form>
                </div>
            </Offcanvas>
            {/* </Backdrop> */}
            {/* Create Appointment End */}

            {/* Appointment View Start*/}
            <Modal show={modalView} onHide={() => setModalView(false)} centered>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div></div>
                        <div>
                            <h2 className="text-center mb-4">Appointment Summary</h2>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn-close mt-2"
                                onClick={() => setModalView(false)}
                            ></button>
                        </div>
                    </div>
                    <div className="modal-body viewdetail">
                        <div className="row">
                            <form className="row cus-form">
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Appointment ID</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.appointment_number} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Date</label>
                                        <input type="text" className="form-control alreadytext" value={moment(appointmentViewData?.startDateTime).format('DD-MM-YYYY')} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Time</label>
                                        <input
                                            type="text"
                                            className="form-control alreadytext"
                                            value={`${moment(appointmentViewData?.startDateTime).format('LT')} - ${moment(appointmentViewData?.endDateTime).format('LT')}`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Branch</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.branchName} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Client Name</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.clientName} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Mobile Number</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.clientMoblieNo} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label className="form-label">Service</label>
                                    <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', borderRadius: '5px', color: "#867d7d" }}>
                                        <ul className='mt-1 ms-1'>
                                            {
                                                appointmentViewData?.serviceDetail?.map((item) =>
                                                    <li class="list-group-item">{item.name}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {
                                    appointmentViewData?.visitType &&
                                    <div className="col-md-6">
                                        <div className="mb-1">
                                            <label className="form-label">Booking Type</label>
                                            <input
                                                type="text"
                                                className="form-control alreadytext"
                                                value={appointmentViewData?.visitType}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6">
                                    <label className="form-label">Team Member</label>
                                    <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', borderRadius: '5px', color: '#867d7d' }}>
                                        <ul className='mt-1 ms-1'>
                                            {
                                                appointmentViewData?.staffDetail?.map((item) =>
                                                    <li class="list-group-item">{item.name}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">
                                        <label className="form-label">Status</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.status} disabled={true} />
                                    </div>
                                </div>
                                {
                                    !appointmentViewData?.isComboPackage &&
                                    <div className="col-md-6 mt-2">
                                        <div className="mb-1">
                                            <label className="form-label">Price</label>
                                            <input type="text" className="form-control alreadytext" value={appointmentViewData?.amount} disabled={true} />
                                        </div>
                                    </div>
                                }
                            </form>
                            <div className='d-flex justify-content-center mt-3'>
                                {
                                    !appointmentViewData?.isComboPackage &&
                                        intialRole({ permission: "appointment", userData: userInfo, section: 'reschedule' }) ?
                                        appointmentViewData?.reschedule?.status === false ?

                                            <div>
                                                <button className='btn apptButton' onClick={() => {

                                                    setShowReschedule(true); setModalView(false); getBranchList(); staffService(); getStaffList()
                                                }}>
                                                    Reschedule</button>
                                            </div>
                                            :
                                            null
                                        : null
                                }
                                {
                                    intialRole({ permission: "appointment", userData: userInfo, section: 'cancel' }) ?
                                        <div className="ms-2">
                                            <button className='btn  px-4 apptButton' onClick={() => { setShowModal(true); setModalView(false) }}>Cancel</button>
                                        </div>
                                        : null
                                }
                                {
                                    intialRole({ permission: "appointment", userData: userInfo, section: 'delete' }) ?
                                        <div className='ms-2'>
                                            <button className='btn  px-4 apptButton' onClick={() => { setModalView(false); setDeleteModal(true) }}>Delete</button>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Appointment View End*/}

            {/* Appointment Cancel Start*/}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <div className="modal-body">
                    <p className="text-center">Are You Sure !</p>
                    <p className="text-center">You want to cancel this appointment</p>
                    <div className="mb-3">
                        <label className="form-label">Reason *</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='reason' value={cancelAppointment.values.reason} onChange={cancelAppointment.handleChange}></textarea>
                        <ValidationMessage formName={cancelAppointment} name={'reason'} />
                    </div>
                    <button
                        type="submit"
                        className="btn nobtn"
                        onClick={cancelAppointment.handleSubmit}
                        style={{ marginLeft: '20%' }}
                    >
                        Yes
                    </button>
                    <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>
                        No
                    </button>
                </div>
            </Modal>
            {/* Appointment Cancel End*/}

            {/* Appointment Delete Start*/}
            <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
                <h5 className="text-center mt-3">Are You Sure !</h5>
                <p className="text-center">You want to delete this appointment</p>
                <div className='modal-body text-center'>
                    <button className='btn nobtn' onClick={() => { appointmentDelete() }}>Yes</button>
                    <button className='btn yesbtn ms-2' onClick={() => setDeleteModal(false)}>No</button>
                </div>
            </Modal>
            {/* Appointment Delete End*/}

            {/* Reschedule Appointment Start */}
            <Offcanvas show={showReschedule} onHide={() => setShowReschedule(false)} placement="end">
                <div style={{ padding: '10px' }}>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowReschedule(false); setSlots([]); }}></button>
                    <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                        Reschedule Appointment
                    </h1>
                </div>
                <div className="offcanvas-body">
                    <div className="cus-form">
                        <div className="mb-3">
                            <label className="form-label">Client Name *</label>
                            <input type="text" className="form-control" value={appointmentViewData.clientName} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Booking Type *</label>
                            <select className="form-control" name="visitType" value={appointmentViewData.visitType} disabled>
                                <option hidden>Select Booking Type</option>
                                <option value='INOFFICE'>In-Office</option>
                                <option value='HOME'>Home</option>
                                <option value='ONLINE'>Online</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Service *</label>
                            <input type="text" className="form-control" value={appointmentViewData.serviceDetail?.[0]?.name} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Price</label>
                            <input type="number" className="form-control" disabled name="price" value={appointmentViewData.amount || appointmentViewData.servicePrice} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Branch *</label>
                            <input type="text" className="form-control" value={appointmentViewData.branchName} />
                        </div>
                        <Calendars onChange={(e) => { timesSlot(e); setSlotTime([]) }} />
                        <ScrollBars horizontal style={{ height: '200px', width: '100%', marginTop: '10px' }} smoothScrolling={true}>
                            <div className="form-check mt-2">
                                {slots?.length ? null : <h4 className='text-center'>No Slots Available</h4>}
                                {slots?.map((item, index) => (
                                    <>
                                        <div className='row'>
                                            <button
                                                type="button"
                                                style={{ backgroundColor: (item.isActive ? '#f47d3a' : '#fff'), color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (item.isActive ? '#fff' : '#f47d3a')), width: '300px', marginLeft: '2px', border: (item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #f47d3a') }}
                                                className="btn me-5 mb-2 col-sm-12"
                                                disabled={item.status === 'Booked' || item.status === 'Unavailable' ? true : false}
                                                onClick={() => {
                                                    setSlots((prevState) => {
                                                        const newData = prevState.map((el, i) => {
                                                            if (i === index) return { ...el, isActive: true }
                                                            else return { ...el, isActive: false }
                                                        })
                                                        return [...newData]
                                                    })
                                                    setSlotTime(item)
                                                }}
                                            >
                                                {moment(item.startTime).format('LT')} - {moment(item.endTime).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </ScrollBars>
                        <div className="d-grid  col-6 mx-auto mb-4">
                            <button disabled={slotTime.length === 0 ? true : false} className="btn colorbtun popupbtn px" onClick={() => appointmentReschedule()}>
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </Offcanvas>
            {/* Reschedule Appointment End */}

            {/* Popup Start*/}
            {
                !intialRole({ permission: "appointment", userData: userInfo, section: 'bookAppointmentFromCalender' }) && !intialRole({ permission: "UnAvailability", userData: userInfo, section: 'add' }) ?
                    null
                    :
                    <Modal show={popup} size='sm' onHide={() => setPopup(false)} centered>
                        <div className='modal-body text-center'>
                            {
                                intialRole({ permission: "appointment", userData: userInfo, section: 'bookAppointmentFromCalender' }) ?
                                    <Button variant="outlined" sx={{ background: '#f47d3a', color: 'white', border: '1px solid #f47d3a', ":hover": { bgcolor: "#f47d3a", border: '1px solid #f47d3a', color: "white" } }}
                                        startIcon={<CalendarMonthIcon />} onClick={() => { setShow(true); setPopup(false); bookAppointment.setFieldValue('branch', userData?.locationId) }}>
                                        Book Appointment
                                    </Button>
                                    : null
                            }
                            {
                                intialRole({ permission: "UnAvailability", userData: userInfo, section: 'add' }) ?
                                    <Button variant="outlined" sx={{ background: '#f47d3a', color: 'white', border: '1px solid #f47d3a', ":hover": { bgcolor: "#f47d3a", border: '1px solid #f47d3a', color: "white" } }}
                                        className='mt-2' startIcon={<WatchLaterIcon />} onClick={() => { setShowUnAvailability(true); setPopup(false); timeZone() }}>
                                        Set Un-Availability
                                    </Button>
                                    : null
                            }
                        </div>
                    </Modal>
            }
            {/* Popup End*/}

            <AddTeamUnAvailability showUnAvailability={showUnAvailability} setShowUnAvailability={setShowUnAvailability} setUnavailability={setUnavailability} timeZoneData={timeZoneList} staffBranchesData={branchListData}
                showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} defaultFormValue={defaultFormValue} />
        </>
    )
}

export default AddAppointment;
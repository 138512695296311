import { environment } from './environment'
import axios from 'axios'
import { toast } from 'react-toastify'
import { statusCodes } from '../Constants/generalConfig'
import { useNavigate } from 'react-router-dom'


/* Authentication */

/* Multiple Authentication */
export const signUpApi = () => environment.baseUrl + '/api/v2/user/signUp'
export const enterOtpApi = () => environment.baseUrl + '/api/v2/user/verifyOtp'
export const createPinApi = () => environment.baseUrl + '/api/v2/user/createPin'
export const forgotPasswordApi = () => environment.baseUrl + '/api/v2/user/forgotPassword'
export const resendOTPApi = () => environment.baseUrl + '/api/v2/user/resendOtp'
export const resetPinApi = () => environment.baseUrl + '/api/v2/user/resetPassword'
export const signInApi = () => environment.baseUrl + '/api/v2/user/login'
export const allProfile = () => environment.baseUrl + '/api/v2/user/allProfile'
export const switchRole = () => environment.baseUrl + '/api/v2/user/switchRole'
export const selectProviderLinkedStaff = () => environment.baseUrl + '/api/v2/user/selectProviderLinkedStaff'




/* Profile */
export const getBasicProfileDetailsApi = () => environment.baseUrl + '/api/v1/user/getBasicDetails'
export const updateProfileApi = () => environment.baseUrl + '/api/v1/user/updateProfile'
export const createBranchApi = () => environment.baseUrl + '/api/v1/branch/create'
export const branchListApi = () => environment.baseUrl + '/api/v1/branch/list'
export const branchListDataApi = () => environment.baseUrl + '/api/v1/branch/listData'
export const branchDeleteApi = () => environment.baseUrl + '/api/v1/branch/changeStatus'
export const businessDetailsApi = () => environment.baseUrl + '/api/v2/user/updateBusinessDetails'
export const getBranchDetails = () => environment.baseUrl + '/api/v1/branch/getDetails'
export const countryListApi = () => environment.baseUrl + '/api/v1/country/list'
export const stateListApi = () => environment.baseUrl + '/api/v1/state/list'
export const businessCategoryListApi = () => environment.baseUrl + '/api/v1/businessCategory/list';
export const getBusinessDetailsApi = () => environment.baseUrl + '/api/v1/user/getBusinessDetails'
export const imageUploadApi = () => environment.baseUrl + '/api/v1/user/uploadImage'
export const imgData = environment.baseUrl + '/upload/profiles/thumbnail/'
export const branchImgPath = environment.baseUrl + '/upload/branch/thumbnail/'

export const branchUpdateApi = () => environment.baseUrl + '/api/v1/branch/update'

/* QrCode */
export const qrCodeApi = () => environment.baseUrl + '/api/v1/user/qrCode'

/* Availability */
export const setAvailability = () => environment.baseUrl + '/api/v1/availability/setAvailability'
export const getAvailabilityListApi = () => environment.baseUrl + '/api/v1/availability/listAvailability'
export const getAvailabilityApi = () => environment.baseUrl + '/api/v1/availability/getAvailability'
export const updateAvailabilityApi = () => environment.baseUrl + '/api/v1/availability/updateAvailability';

/* UnAvailability */
export const unAvailability = () => environment.baseUrl + '/api/v1/availability/setUnavailability';
export const unAvailabilityLists = () => environment.baseUrl + '/api/v1/availability/getUnavailabilityList';
export const unavailabilityDeleteApi = () => environment.baseUrl + '/api/v1/availability/deleteUnavailability';

/* TimeZone */
export const timeZoneApi = () => environment.baseUrl + '/api/v1/timezone/list'

/* Policies */
export const uploadPoliciesApi = () => environment.baseUrl + '/api/v1/settings/uploadPdf'
export const getPoliciesApi = () => environment.baseUrl + '/api/v1/settings/getUploadPdfStatus'
export const updatePoliciesApi = () => environment.baseUrl + '/api/v1/settings/updatePolicyPdf'
export const pdf = environment.baseUrl + '/upload/bookingPolicy/'
export const deletePoliciesApi = () => environment.baseUrl + '/api/v1/settings/deletePolicyPdf'
export const updateCancelPolicy = () => environment.baseUrl + '/api/v1/provider/updatePolicy';
export const getPolicie = () => environment.baseUrl + '/api/v1/provider/getPolicy';
const userData = JSON.parse(localStorage.getItem('userData'))

export const updatePdfAPI = async (data) => {
  let resp = await axios.post(updatePoliciesApi(), data, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
    if (resp.data.code === statusCodes.tokenExpire) {
      logOutFun()
    }
    return resp
  })
  return resp.data
}
const logOutFun = () => {
  axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData.userId, type: userData.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
    if (resp.data.code === statusCodes.ok) {
      localStorage.removeItem('userData')
      const navigate = useNavigate()
      navigate('/')
    }
  })
}
export const deletePdfApi = async (data) => {
  let resp = await axios.post(deletePoliciesApi(), data, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
    if (resp.data.code === 200) {
      toast.success(resp.data.message)
    }
    else if (resp.data.code === statusCodes.tokenExpire) {
      logOutFun()
    }
    return resp
  })
  return resp.data
}

/* Change Pin */
export const changePinApi = () => environment.baseUrl + '/api/v2/user/changePin'

/* Currency */
export const currencyUpdateApi = () => environment.baseUrl + '/api/v1/settings/updateUserCurrency'
export const currencyListApi = () => environment.baseUrl + '/api/v1/currency/list'

export const getProviderPricePermission = () => environment.baseUrl + '/api/v2/user/getProviderPricePermission'
export const showPriceToClient = () => environment.baseUrl + '/api/v2/user/showPriceToClient '


/* FAQ */
export const faqCreateApi = () => environment.baseUrl + '/api/v1/faq/create'
export const faqListApi = () => environment.baseUrl + '/api/v1/faq/list'
export const faqApi = () => environment.baseUrl + '/api/v1/faq/view'
export const faqUpdateApi = () => environment.baseUrl + '/api/v1/faq/update'

/* Notification */
export const getNotificationApi = () => environment.baseUrl + '/api/v1/settings/getNotificationStatus'
export const updateNotificationApi = () => environment.baseUrl + '/api/v1/settings/updateNotificationStatus'

/* Services Category */
export const addServicesCategoryApi = () => environment.baseUrl + '/api/v2/servicesCategory/create'
export const servicesCategoryListApi = () => environment.baseUrl + '/api/v2/servicesCategory/list'
export const serviceCategoryViewApi = () => environment.baseUrl + '/api/v1/servicesCategory/getDetails'
export const editServiceCategoryApi = () => environment.baseUrl + '/api/v1/servicesCategory/update'
export const changeServiceCategoryStatusApi = () => environment.baseUrl + '/api/v1/servicesCategory/changeStatus';

/* Services */
export const addServiceApi = () => environment.baseUrl + '/api/v2/services/create'
export const serviceListApi = () => environment.baseUrl + '/api/v1/services/list'
export const imgPath = environment.baseUrl + '/upload/services/thumbnail/'
export const comboImgPath = environment.baseUrl + '/upload/comboPackage/thumbnail/'
export const ServiceViewApi = () => environment.baseUrl + '/api/v1/services/getDetails'
export const editServiceApi = () => environment.baseUrl + '/api/v1/services/update'
export const changeServiceStatusApi = () => environment.baseUrl + '/api/v1/services/changeStatus'
export const currency = () => environment.baseUrl + '/api/v1/settings/getCurrency';
export const listServices = () => environment.baseUrl + '/api/v2/comboPackages/listServices';
export const comboCreate = () => environment.baseUrl + '/api/v2/comboPackages/create';
export const comboList = () => environment.baseUrl + '/api/v2/comboPackages/getList';
export const changeComboStatus = () => environment.baseUrl + '/api/v2/comboPackages/changeStatus';
export const getDetailCombo = () => environment.baseUrl + '/api/v2/comboPackages/getDetail';
export const comboEdit = () => environment.baseUrl + '/api/v2/comboPackages/edit';
export const purchaseComboPackage = () => environment.baseUrl + '/api/v2/comboPackages/PurchaseComboPackage';
export const comboBookAppointment = () => environment.baseUrl + '/api/v2/comboPackages/bookAppointment';
export const downloadServieCate = () => environment.baseUrl + '/api/v2/services/exportExcel';
export const exportServieCate = () => environment.baseUrl + '/api/v2/services/createthroughExcel';


 

/* Client */
export const addClientApi = () => environment.baseUrl + '/api/v2/user/addClient'
export const clientListApi = () => environment.baseUrl + '/api/v1/client/list'
export const clientViewApi = () => environment.baseUrl + '/api/v1/client/view'
export const updateClientApi = () => environment.baseUrl + '/api/v1/client/edit'
export const changeClientStatusApi = () => environment.baseUrl + '/api/v1/client/statusChange'
export const reSendInvitation = () => environment.baseUrl + '/api/v2/resendInvitation';
export const clientMember = () => environment.baseUrl + '/api/v1/client/getMembers';
export const listClientChat = () => environment.baseUrl + '/api/v1/client/listClientChat';
export const clientComboList = () => environment.baseUrl + '/api/v2/comboPackages/comboPackagePurchasedList';
export const clientPurchaseList = () => environment.baseUrl + '/api/v2/comboPackages/clientPurchaseListByprovider';



/* Team */
export const addStaffApi = () => environment.baseUrl + '/api/v2/user/addStaff'


export const staffListApi = () => environment.baseUrl + '/api/v2/listStaff'
export const changeStaffStatusApi = () => environment.baseUrl + '/api/v2/user/changeStatus'
export const multipleServicesListApi = () => environment.baseUrl + '/api/v2/services/listMultipleServices'
export const staffViewApi = () => environment.baseUrl + '/api/v1/staff/view'
export const staffProfileUpdate = () => environment.baseUrl + '/api/v1/staff/updateStaff'
export const staffPermission = () => environment.baseUrl + '/api/v1/user/changePermission';
export const staffReSendInvitation = () => environment.baseUrl + '/api/v1/staff/reSendInvitationMail';

/* Appointment */
export const appointmentDeleteApi = () => environment.baseUrl + '/api/v1/appointment/deleteAppointment'
export const appointmentBookApi = () => environment.baseUrl + '/api/v1/appointment/bookNew';
export const appointmentListForCalendar = () => environment.baseUrl + '/api/v1/appointment/listAppointmentForCalender';
export const appointmentListApi = () => environment.baseUrl + '/api/v1/appointment/listAppointment'
export const appointmentViewApi = () => environment.baseUrl + '/api/v1/appointment/getappointment'
export const timeSlotApi = () => environment.baseUrl + '/api/v1/availability/getTimeSlot'
export const filterListApi = () => environment.baseUrl + '/api/v1/appointment/appointmentFilterList';
export const cancelappointmnetApi = () => environment.baseUrl + '/api/v1/appointment/cancelAppointment'
export const rescheduleAppointmentApi = () => environment.baseUrl + '/api/v1/appointment/rescheduleAppointment'
export const staffApi = () => environment.baseUrl + '/api/v1/availability/getAvailableStaff'
export const calendarAppointments = () => environment.baseUrl + '/api/v1/appointment/calenderList'
export const acceptedBooking = () => environment.baseUrl + '/api/v1/appointment/acceptedBooking';
export const checkout = () => environment.baseUrl + '/api/v1/appointment/checkout';
export const followUpList = () => environment.baseUrl + '/api/v1/appointment/followUpList';


export const getOffer = () => environment.baseUrl + '/api/v2/offers/listForProviderStaff';
export const applyOffer = () => environment.baseUrl + '/api/v1/offers/apply';
export const staffForCalendar = () => environment.baseUrl + '/api/v1/staff/listStaffForCalender';


// Staff Appointment
export const staffServices = () => environment.baseUrl + '/api/v1/staffServiceData/list';
export const staffBranches = () => environment.baseUrl + '/api/v1/staffBranchData/list';

/* Offers */
export const createOfferApi = () => environment.baseUrl + '/api/v2/offers/create'
export const offerListApi = () => environment.baseUrl + '/api/v2/offers/list'
export const imgFolder = environment.baseUrl + '/upload/offer/thumbnail/'
export const offerViewApi = () => environment.baseUrl + '/api/v1/offers/viewOffer'

export const editOfferApi = () => environment.baseUrl + '/api/v1/offers/edit'
export const deleteOffer = () => environment.baseUrl + '/api/v1/offers/delete'

/* Waiting List */
export const createWaitingListApi = () => environment.baseUrl + '/api/v1/waitinglist/create'
export const waitinglistListingApi = () => environment.baseUrl + '/api/v1/waitinglist/list'
export const removeWaitingListApi = () => environment.baseUrl + '/api/v1/waitinglist/removeWaitingList'
export const bookApptWaitingListApi = () => environment.baseUrl + '/api/v1/waitinglist/bookingApptWaitingList'
export const timeSlotBookApi = () => environment.baseUrl + '/api/v1/availability/getTimeSlotBooking'
export const branchSlot = () => environment.baseUrl + '/api/v1/branch/listAvalSlot';



// Contact
export const contactCreateApi = () => environment.baseUrl + '/api/v1/contactUs/create';

// AcceptInvitation
export const acceptInvitation = () => environment.baseUrl + '/api/v2/acceptInvitation';
export const rejectInvitation = () => environment.baseUrl + '/api/v2/declineInvitation';

// Duration
export const durationAdd = () => environment.baseUrl + '/api/v2/duration/create';
export const durationList = () => environment.baseUrl + '/api/v2/duration/list';
export const durationDelete = () => environment.baseUrl + '/api/v1/duration/delete';
export const durationStatus = () => environment.baseUrl + '/api/v1/duration/changeStatus';

// Booking Dteail
export const pieChart = () => environment.baseUrl + '/api/v1/insight/appointmentTypePiechart';
export const appointmentBook = () => environment.baseUrl + '/api/v1/insight/appointmentBooked';
export const waitinglistGraph = () => environment.baseUrl + '/api/v1/insight/waitingListGraph';
export const upcommingAppointment = () => environment.baseUrl + '/api/v1/insight/upCommingAppointmentsGraph';
export const visitGrowth = () => environment.baseUrl + '/api/v1/insight/visitgraph';

//Client Report 
export const totalBookingRevenue = () => environment.baseUrl + '/api/v1/insight/reveneuAndTotalBooking';
export const clients = () => environment.baseUrl + '/api/v1/insight/clientReportByUser';
export const clientsDetail = () => environment.baseUrl + '/api/v1/insight/clientDetail';
export const clientsExport = () => environment.baseUrl + '/api/v1/insight/clientReportByUserExcel';
export const serviceTakenByClient = () => environment.baseUrl + '/api/v1/insight/serviceTakenByClient';


// Team Report
export const teamBookingRevenue = () => environment.baseUrl + '/api/v1/insight/reveneuAndTotalBooking';
export const teamRevenue = () => environment.baseUrl + '/api/v1/insight/teamRevenueEarned';
export const teamReport = () => environment.baseUrl + '/api/v1/insight/teamReports';
export const teamExport = () => environment.baseUrl + '/api/v1/insight/teamReportsExcel';
export const serviceGivenByTeam = () => environment.baseUrl + '/api/v1/insight/serviceGivenByTeam';

// Service Report
export const serviceReport = () => environment.baseUrl + '/api/v1/insight/servicesReportByUser';
export const serviceDetail = () => environment.baseUrl + '/api/v1/insight/serviceDetail';
export const serviceExport = () => environment.baseUrl + '/api/v1/insight/servicesReportByUserExcel';

// Demographics
export const demographics = () => environment.baseUrl + '/api/v1/insight/demoGraphic';

// FeedBack
export const feedBackList = () => environment.baseUrl + '/api/v1/review/ratingList';

// Combo Report
export const comboPackagesTotalEarning = () => environment.baseUrl + '/api/v1/insight/comboPackagesTotalEarning';
export const clientEarning = () => environment.baseUrl + '/api/v1/insight/clientEarning';
export const getIndividualClientPackages = () => environment.baseUrl + '/api/v1/insight/getIndividualClientPackages';
export const combopackagesCount = () => environment.baseUrl + '/api/v1/insight/combopackagesCount';
export const higestEarningCombo = () => environment.baseUrl + '/api/v1/insight/higestEarningCombo';


 

//Dashboard
export const dashboardAppointment = () => environment.baseUrl + '/api/v1/dashboard/appointmentAndServices';
export const dashboardRevenue = () => environment.baseUrl + '/api/v1/dashboard/revenue';
export const rushHours = () => environment.baseUrl + '/api/v1/dashboard/rushHour';
export const topServices = () => environment.baseUrl + '/api/v1/dashboard/topServices';

//logout
export const logOutApi = () => environment.baseUrl + '/api/v1/user/logout';

//Notifications
export const notificationList = () => environment.baseUrl + '/api/v1/notification/list';
export const notificationReadSingle = () => environment.baseUrl + '/api/v1/notification/readSingle';
export const notificationReadAll = () => environment.baseUrl + '/api/v1/notification/readAll';


// Time Limit
export const timeLimit = () => environment.baseUrl + '/api/v1/settings/updateBooKingTimeLimit';
export const getTimeLimit = () => environment.baseUrl + '/api/v1/settings/getBookingTimeLimit';

// Chat
export const getTeamMembers = () => environment.baseUrl + '/api/v1/conversation/getTeamMembers';
export const newConversation = () => environment.baseUrl + '/api/v1/conversation/newConversation';
export const getMyConversations = () => environment.baseUrl + '/api/v1/conversation/getMyConversations';
export const deleteConversations = () => environment.baseUrl + '/api/v1/conversation/deleteMsg';
export const blockConversations = () => environment.baseUrl + '/api/v1/conversation/blockUser';
export const getMessages = () => environment.baseUrl + '/api/v1/conversation/getMessages';
export const getMyConversationDetails = () => environment.baseUrl + '/api/v1/conversation/getMyConversationDetails';
export const getGroupDetails = () => environment.baseUrl + '/api/v1/conversation/getGroupDetailWithMembers';
export const addMemberGroup = () => environment.baseUrl + '/api/v1/conversation/addMember';
export const removeMemberGroup = () => environment.baseUrl + '/api/v1/conversation/leftOrRemove';
export const adminSetting = () => environment.baseUrl + '/api/v1/conversation/adminSetting';
export const updateGroup = () => environment.baseUrl + '/api/v1/conversation/update';
export const updateGroupSetting = () => environment.baseUrl + '/api/v1/conversation/updateSetting';
export const uploadDoc = () => environment.baseUrl + '/api/v1/conversation/uploadDoc';
export const deleteDoc = () => environment.baseUrl + '/api/v1/conversation/deleteDoc';
export const chatDocs = environment.baseUrl + '/upload/chatDocs/'


export const chatPermission = () => environment.baseUrl + '/api/v1/user/getAssignedPrmission';


// Ticket
export const ticketAdd = () => environment.ticketBaseUrl + '/api/external/ticket';
export const ticketList = () => environment.ticketBaseUrl + '/api/external/ticket/list';
export const ticketReply = () => environment.ticketBaseUrl + '/api/external/reply';
export const ticketAttachment = environment.ticketBaseUrl + '/upload/tickets/';


export const customContact = () => environment.baseUrl + '/api/v1/leadsManagement/createLeads';
export const stripePayment = () => environment.baseUrl + '/api/v1/purchasePlan/stripePayment';
export const checkPlanExpiredDays = () => environment.baseUrl + '/api/v1/purchasePlan/checkPlanExpiredDays';
export const getSavedCard = () => environment.baseUrl + '/api/v1/card/getDefaultCard';
export const stripePaymentSavedCard = () => environment.baseUrl + '/api/v1/purchasePlan/stripePaymentSavedCard';



// My Plan
export const myPlan = () => environment.baseUrl + '/api/v1/subscriptionplan/getMyPlan';
export const myPlanHistory = () => environment.baseUrl + '/api/v1/purchasePlan/myPlanHistory';
export const paymentHistoryDetail = () => environment.baseUrl + '/api/v1/purchasePlan/paymentHistoryDetail';
export const checkRefundPrice = () => environment.baseUrl + '/api/v1/purchasePlan/checkRefundPrice';
export const CancelPlan = () => environment.baseUrl + '/api/v1/purchasePlan/CancelPlan';
export const CancelPlanStripe = () => environment.baseUrl + '/api/v1/purchasePlan/CancelPlanStripe';

// My Card

export const createCard = () => environment.baseUrl + '/api/v1/card/create';
export const cardList = () => environment.baseUrl + '/api/v1/card/list';
export const cardDelete = () => environment.baseUrl + '/api/v1/card/deleteCard';
export const makeCardDefault = () => environment.baseUrl + '/api/v1/card/makeCardDefault';


export const verifyOtpByToken = () => environment.baseUrl + '/api/v2/user/verifyOtpByToken';
export const checkTokenProvider = () => environment.baseUrl + '/api/v2/user/checkTokenProvider';
export const checkTstripeToken = () => environment.baseUrl + '/api/v1/subscriptionplan/checkStripeToken';


export const appointmentHistory = () => environment.baseUrl + '/api/v1/appointment/listAppointment';

export const checkTokensExpire = () => environment.baseUrl + '/api/v2/user/checkTokensExpire';
export const changeEmail = () => environment.baseUrl + '/api/v2/user/changeEmail';
export const changeEmailVerify = () => environment.baseUrl + '/api/v2/user/changeEmailVerify';


export const checkIsMobileExist = () => environment.baseUrl + '/api/v2/user/checkIsMobileExist';
export const deleteImage = () => environment.baseUrl + '/api/v2/user/deleteImage';
export const deleteImageFromFolder = () => environment.baseUrl + '/api/v2/user/deleteImageFromFolder';
export const listRole = () => environment.baseUrl + '/api/v2/user/staffListRole';

export const permissionsForStaff = () => environment.baseUrl + '/api/v2/user/staffPermission';

export const allLocation = () => environment.baseUrl + '/api/v2/user/allLocation';
export const selectLocationProvider = () => environment.baseUrl + '/api/v2/user/selectLocationProvider';  


export const getAllPermission = () => environment.baseUrl + '/api/v1/user/getAllPermission';

// Rewards
export const createCoinAppointment = () => environment.baseUrl + '/api/v2/spinWheel/createCoinAppointment';
export const coinList = () => environment.baseUrl + '/api/v2/spinWheel/coinList';
export const coinView = () => environment.baseUrl + '/api/v2/spinWheel/coinView';
export const coinAppointmentUpdate = () => environment.baseUrl + '/api/v2/spinWheel/coinAppointmentUpdate';


export const spinCondition = () => environment.baseUrl + '/api/v2/spinWheel/createSpinCondition';
export const conditionUpdate = () => environment.baseUrl + '/api/v2/spinWheel/SpinConditionUpdate';
export const conditionList = () => environment.baseUrl + '/api/v2/spinWheel/SpinConditionList';
export const conditionView = () => environment.baseUrl + '/api/v2/spinWheel/SpinConditionView';


export const coinDuration = () => environment.baseUrl + '/api/v2/spinWheel/createCoinDuration';
export const coinDurationList = () => environment.baseUrl + '/api/v2/spinWheel/coinDurationList';
export const durationView = () => environment.baseUrl + '/api/v2/spinWheel/coinDurationView';
export const durationUpdate = () => environment.baseUrl + '/api/v2/spinWheel/coinDurationUpdate'; 

// Payment Success
export const payment = () => environment.baseUrl + '/api/v1/purchasePlan/razorPayResponse  '; 

import { useContext, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import axios from 'axios'
import { statusCodes, commonMessage, roleNames, initialState, availabilityInitialValues, initialValues } from '../../../Constants/generalConfig'
import {
    staffViewApi,
    imgData,
    multipleServicesListApi,
    setAvailability,
    getAvailabilityListApi,
    getAvailabilityApi,
    updateAvailabilityApi,
    unAvailability,
    unAvailabilityLists,
    unavailabilityDeleteApi,
    timeZoneApi,
    staffProfileUpdate,
    imageUploadApi,
    staffBranches,
    branchListApi,
    logOutApi,
    listRole,
    getAllPermission
} from '../../../Context/api'
import { availabilityValidation, unAvailabilityValidation, teamProfileValidation } from '../../../Context/validation'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment/moment'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Notifications } from '../../../App'


const UseTeamProfile = () => {
    const { userInfo } = useContext(Notifications)
    const { staffId } = useParams()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [defaultFormValue, setFormValue] = useState(initialState)
    const [listData, setListData] = useState([])
    const [availablityData, setAvailablityData] = useState()
    const [availabilityId, setAvailabilityId] = useState()
    const [timeZoneData, setTimeZoneData] = useState()
    const [unAvailablityData, setUnAvailablityData] = useState([])
    const [showAvailability, setShowAvailability] = useState(false)
    const [showEditAvailability, setShowEditAvailability] = useState(false)
    const [showUnAvailability, setShowUnAvailability] = useState(false)
    const [imageData, setImageData] = useState()
    const [selectedBranch, setSelectedBranch] = useState([])
    const [branchList, setBranchList] = useState([])
    const [serviceList, serServiceList] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [staffBranchesData, setStaffBranchesData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [branchStatus, setBranchStatus] = useState(false)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const [disableBranch, setDisableBranch] = useState('')
    const [tab, setTab] = useState(1)
    const [permissions, setPermissions] = useState([]);
    const [ViewPermissions, setViewPermissions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    let branchData = []
    let serviceData = []
    const navigate = useNavigate()
    const date = moment(new Date()).format('YYYY-MM-DD')
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [roleList, setRoleList] = useState([])
    const fileInputRef = useRef(null);
    const [permissionBtn, setPermissionBtn] = useState(false)

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            profileView()
            getBranchList()
            multipleServicesList()
            unAvailabilityList()
            getAvailabilityList()
            list()
        }
    }, [page])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const StaffBranch = () => {
        axios.post(staffBranches(), { userId: staffId, isDeleted: false, providerId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setStaffBranchesData(resp.data.data)
                formik.setFieldValue('branch', userData?.locationId)
                setUnavailability.setFieldValue('branch', userData?.locationId)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        })
    }
    const getBranchList = () => {
        axios.post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setListData(resp.data.data)
                let data = []
                if (data.length === 0) {
                    resp.data.data?.map((item) => {
                        return data.push({ name: item.name, _id: item._id, })
                    })
                    setBranchList(data)
                }
            }
        }).catch(() => { })
    }
    const permissionsList = (id) => {
        axios.post(getAllPermission(), { roleId: id },).then((resp) => {
            // if (resp.data.code === statusCodes.ok) {
            setPermissions(resp.data.data.allPermissions)

            // }
            // else {
            //     setPermissions([])     
            // }
        }).catch(() => { })
    }
    const multipleServicesList = () => {
        axios.post(multipleServicesListApi(), { userId: userData?.userId, isDeleted: false, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                let serviceData = []
                if (serviceData.length === 0) {
                    resp.data.data.map((item) => {
                        return item.servicedetails.map((value) => {
                            return serviceData.push({ name: value.serviceName, _id: value.serviceId, categoryName: item.categoryName })
                        })
                    })
                    serServiceList(serviceData)
                }
            }
            else {
                // toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const profileView = () => {
        setSpinner(true)
        axios.post(staffViewApi(), { userId: staffId, createdbyId: userData?.userId, type: 'WEB' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                teamProfile.setValues(resp.data.data)
                setImageData(resp.data.data.image)
                setSelectedBranch(resp.data.data.branchData)
                setSelectedService(resp.data.data.serviceData)
                setDisableBranch(resp.data.data.isAssignLogin)
                permissionsList(resp.data.data.roleTitle)
                setViewPermissions(resp.data.data.allpermission)
                setSpinner(false)
                if (branchData.length === 0) {
                    const branchDataArray = resp.data.data.branchData?.map((items) => ({
                        name: items.name,
                        _id: items._id,
                    })) || [];

                    teamProfile.setFieldValue('serviceData', branchDataArray);
                }
                if (serviceData.length === 0) {
                    const serviceDataArray = resp.data.data.serviceData?.map((items) => ({
                        name: items.name,
                        _id: items._id,
                    })) || [];

                    teamProfile.setFieldValue('serviceData', serviceDataArray);

                }
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const teamProfile = useFormik({
        initialValues: {
            name: '',
            email: '',
            DOB: '',
            gender: '',
            mobileNo: '',
            branchData: [],
            serviceData: [],
            roleTitle: ''
        },
        validationSchema: teamProfileValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            let data = {
                name: values.name.trim(),
                DOB: values.DOB == null ? '' : values.DOB,
                gender: values.gender,
                userId: staffId,
                image: imageData,
                branchId: values?.branchData?.map((item) => item._id),
                serviceId: values.serviceData?.map((item) => item._id),
                isActive: true,
                isAssignLogin: disableBranch,
                createdbyId: userData?.userId,
                mobileNo: values.mobileNo,
                allpermissions: permissions,
                roleTitle: values.roleTitle
            }
            if (values.serviceData.length === 0 && values.branchData.length === 0) return toast.warn("Please select branch and service")
            if (values.serviceData.length === 0) return toast.warn("Please select service")
            if (values.branchData.length === 0) return toast.warn("Please select branch")
            // console.log(data,'lllllll')
            // return 
            setSpinner(true)
            axios.post(staffProfileUpdate(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setSpinner(false)
                    // profileView()
                    setTab(1)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
        },
    })
    const uploadImage = (e) => {
        const fd = new FormData()
        fd.append('file', e)
        fd.append('type', 'PROFILE')
        axios.post(imageUploadApi(), fd).then((res) => {
            if (res.data.code === 200) {
                setImageData(res.data.data.imagePath)
                fileInputRef.current.value = null;
            }
            else if (res.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(res.data.message)
            }
        })
    }
    /* Availability Start */
    const getAvailabilityList = () => {
        const data = {
            userId: staffId,
            page: page,
            count: PER_PAGE,
            timezone: currentTimeZone,
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            branchId: userData?.locationId
        }
        axios.post(getAvailabilityListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAvailablityData(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
                setAvailablityData([])
            }
        }).catch(() => { })
    }
    const getAvailability = (id) => {
        axios.post(getAvailabilityApi(), { availabilityId: id, userId: staffId, timezone: currentTimeZone }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAvailabilityId(id)
                editAvailability.setValues(resp.data.data)
                let tempArr = defaultFormValue
                let available_days = resp.data.data.available_days
                available_days.forEach((day, dayIndex) => {
                    if (day.isChecked) {
                        if (dayIndex === 0) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeSunday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeSunday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeSunday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeSunday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeSunday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeSunday', moment(time.endTime))
                                }
                            })
                        } else if (dayIndex === 1) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeMonday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeMonday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeMonday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeMonday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeMonday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeMonday', moment(time.endTime))
                                }
                            })
                        } else if (dayIndex === 2) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeTuesday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeTuesday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeTuesday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeTuesday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeTuesday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeTuesday', moment(time.endTime))
                                }
                            })
                        } else if (dayIndex === 3) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeWednesday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeWednesday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeWednesday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeWednesday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeWednesday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeWednesday', moment(time.endTime))
                                }
                            })
                        } else if (dayIndex === 4) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeThursday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeThursday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeThursday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeThursday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeThursday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeThursday', moment(time.endTime))
                                }
                            })
                        } else if (dayIndex === 5) {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeFriday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeFriday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeFriday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeFriday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeFriday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeFriday', moment(time.endTime))
                                }
                            })
                        } else {
                            day.arrayOfTimings.forEach((time, timeIndex) => {
                                if (timeIndex === 0) {
                                    editAvailability.setFieldValue('startTimeSaturday', moment(time.startTime))
                                    editAvailability.setFieldValue('endTimeSaturday', moment(time.endTime))
                                } else if (timeIndex === 1) {
                                    editAvailability.setFieldValue('secondStartTimeSaturday', moment(time.startTime))
                                    editAvailability.setFieldValue('secondEndTimeSaturday', moment(time.endTime))
                                } else {
                                    editAvailability.setFieldValue('thirdStartTimeSaturday', moment(time.startTime))
                                    editAvailability.setFieldValue('thirdEndTimeSaturday', moment(time.endTime))
                                }
                            })
                        }

                        tempArr[dayIndex].isChecked = day.isChecked
                        tempArr[dayIndex].arrayOfTimings = day.arrayOfTimings
                        if (day.arrayOfTimings.length === 2) {
                            tempArr[dayIndex].isAllowedTimes.first = true
                        } else if (day.arrayOfTimings.length === 3) {
                            tempArr[dayIndex].isAllowedTimes.first = true
                            tempArr[dayIndex].isAllowedTimes.second = true
                        }
                    }
                })
                setFormValue([...tempArr])
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const formik = useFormik({
        initialValues: availabilityInitialValues,
        validationSchema: availabilityValidation,
        onSubmit: (values) => {
            let data = {
                Date: moment().format('YYYY-MM-DD'),
                timezone: values.timeZone,
                userId: staffId,
                branchId: values.branch,
                providerId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                available_days: [
                    {
                        id: 0,
                        name: 'Sunday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 1,
                        name: 'Monday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 2,
                        name: 'Tuesday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 3,
                        name: 'Wednesday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 4,
                        name: 'Thursday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 5,
                        name: 'Friday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                    {
                        id: 6,
                        name: 'Saturday',
                        isChecked: true,
                        arrayOfTimings: [],
                    },
                ],
            }
            if (!defaultFormValue[0].isChecked) {
                values.startTimeSunday = ''
                values.endTimeSunday = ''
                values.secondStartTimeSunday = ''
                values.secondEndTimeSunday = ''
                values.thirdStartTimeSunday = ''
                values.thirdEndTimeSunday = ''
            }
            if (!defaultFormValue[1].isChecked) {
                values.startTimeMonday = ''
                values.endTimeMonday = ''
                values.secondStartTimeMonday = ''
                values.secondEndTimeMonday = ''
                values.thirdStartTimeMonday = ''
                values.thirdEndTimeMonday = ''
            }
            if (!defaultFormValue[2].isChecked) {
                values.startTimeTuesday = ''
                values.endTimeTuesday = ''
                values.secondStartTimeTuesday = ''
                values.secondEndTimeTuesday = ''
                values.thirdStartTimeTuesday = ''
                values.thirdEndTimeTuesday = ''
            }
            if (!defaultFormValue[3].isChecked) {
                values.startTimeWednesday = ''
                values.endTimeWednesday = ''
                values.secondStartTimeWednesday = ''
                values.secondEndTimeWednesday = ''
                values.thirdStartTimeWednesday = ''
                values.thirdEndTimeWednesday = ''
            }
            if (!defaultFormValue[4].isChecked) {
                values.startTimeThursday = ''
                values.endTimeThursday = ''
                values.secondStartTimeThursday = ''
                values.secondEndTimeThursday = ''
                values.thirdStartTimeThursday = ''
                values.thirdEndTimeThursday = ''
            }
            if (!defaultFormValue[5].isChecked) {
                values.startTimeFriday = ''
                values.endTimeFriday = ''
                values.secondStartTimeFriday = ''
                values.secondEndTimeFriday = ''
                values.thirdStartTimeFriday = ''
                values.thirdEndTimeFriday = ''
            }
            if (!defaultFormValue[6].isChecked) {
                values.startTimeSaturday = ''
                values.endTimeSaturday = ''
                values.secondStartTimeSaturday = ''
                values.secondEndTimeSaturday = ''
                values.thirdStartTimeSaturday = ''
                values.thirdEndTimeSaturday = ''
            }

            // Sunday
            if (formik.values.startTimeSunday > formik.values.endTimeSunday)
                return toast.warn('Sunday start time cannot be greater than end time');
            if (formik.values.startTimeSunday && formik.values.endTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeSunday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeSunday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeSunday > formik.values.secondEndTimeSunday)
                return toast.warn('Sunday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeSunday && formik.values.secondEndTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeSunday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeSunday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeSunday > formik.values.thirdEndTimeSunday)
                return toast.warn('Sunday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeSunday && formik.values.thirdEndTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeSunday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeSunday).format('HH:mm'),
                });

            // Monday
            if (formik.values.startTimeMonday > formik.values.endTimeMonday)
                return toast.warn('Monday start time cannot be greater than end time');
            if (formik.values.startTimeMonday && formik.values.endTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeMonday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeMonday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeMonday > formik.values.secondEndTimeMonday)
                return toast.warn('Monday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeMonday && formik.values.secondEndTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeMonday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeMonday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeMonday > formik.values.thirdEndTimeMonday)
                return toast.warn('Monday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeMonday && formik.values.thirdEndTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeMonday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeMonday).format('HH:mm'),
                });

            // Repeat for Tuesday to Saturday

            // Tuesday
            if (formik.values.startTimeTuesday > formik.values.endTimeTuesday)
                return toast.warn('Tuesday start time cannot be greater than end time');
            if (formik.values.startTimeTuesday && formik.values.endTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeTuesday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeTuesday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeTuesday > formik.values.secondEndTimeTuesday)
                return toast.warn('Tuesday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeTuesday && formik.values.secondEndTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeTuesday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeTuesday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeTuesday > formik.values.thirdEndTimeTuesday)
                return toast.warn('Tuesday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeTuesday && formik.values.thirdEndTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeTuesday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeTuesday).format('HH:mm'),
                });

            // Wednesday
            if (formik.values.startTimeWednesday > formik.values.endTimeWednesday)
                return toast.warn('Wednesday start time cannot be greater than end time');
            if (formik.values.startTimeWednesday && formik.values.endTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeWednesday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeWednesday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeWednesday > formik.values.secondEndTimeWednesday)
                return toast.warn('Wednesday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeWednesday && formik.values.secondEndTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeWednesday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeWednesday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeWednesday > formik.values.thirdEndTimeWednesday)
                return toast.warn('Wednesday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeWednesday && formik.values.thirdEndTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeWednesday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeWednesday).format('HH:mm'),
                });

            // Thursday
            if (formik.values.startTimeThursday > formik.values.endTimeThursday)
                return toast.warn('Thursday start time cannot be greater than end time');
            if (formik.values.startTimeThursday && formik.values.endTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeThursday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeThursday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeThursday > formik.values.secondEndTimeThursday)
                return toast.warn('Thursday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeThursday && formik.values.secondEndTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeThursday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeThursday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeThursday > formik.values.thirdEndTimeThursday)
                return toast.warn('Thursday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeThursday && formik.values.thirdEndTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeThursday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeThursday).format('HH:mm'),
                });

            // Friday
            if (formik.values.startTimeFriday > formik.values.endTimeFriday)
                return toast.warn('Friday start time cannot be greater than end time');
            if (formik.values.startTimeFriday && formik.values.endTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeFriday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeFriday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeFriday > formik.values.secondEndTimeFriday)
                return toast.warn('Friday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeFriday && formik.values.secondEndTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeFriday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeFriday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeFriday > formik.values.thirdEndTimeFriday)
                return toast.warn('Friday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeFriday && formik.values.thirdEndTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeFriday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeFriday).format('HH:mm'),
                });

            // Saturday
            if (formik.values.startTimeSaturday > formik.values.endTimeSaturday)
                return toast.warn('Saturday start time cannot be greater than end time');
            if (formik.values.startTimeSaturday && formik.values.endTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(formik.values.startTimeSaturday).format('HH:mm'),
                    endTime: moment(formik.values.endTimeSaturday).format('HH:mm'),
                });

            if (formik.values.secondStartTimeSaturday > formik.values.secondEndTimeSaturday)
                return toast.warn('Saturday second start time cannot be greater than second end time');
            if (formik.values.secondStartTimeSaturday && formik.values.secondEndTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(formik.values.secondStartTimeSaturday).format('HH:mm'),
                    endTime: moment(formik.values.secondEndTimeSaturday).format('HH:mm'),
                });

            if (formik.values.thirdStartTimeSaturday > formik.values.thirdEndTimeSaturday)
                return toast.warn('Saturday third start time cannot be greater than third end time');
            if (formik.values.thirdStartTimeSaturday && formik.values.thirdEndTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(formik.values.thirdStartTimeSaturday).format('HH:mm'),
                    endTime: moment(formik.values.thirdEndTimeSaturday).format('HH:mm'),
                });

            if (!data.available_days[0].arrayOfTimings.length) data.available_days[0].isChecked = false
            if (!data.available_days[1].arrayOfTimings.length) data.available_days[1].isChecked = false
            if (!data.available_days[2].arrayOfTimings.length) data.available_days[2].isChecked = false
            if (!data.available_days[3].arrayOfTimings.length) data.available_days[3].isChecked = false
            if (!data.available_days[4].arrayOfTimings.length) data.available_days[4].isChecked = false
            if (!data.available_days[5].arrayOfTimings.length) data.available_days[5].isChecked = false
            if (!data.available_days[6].arrayOfTimings.length) data.available_days[6].isChecked = false

            if (
                data.available_days[0].isChecked === false &&
                data.available_days[1].isChecked === false &&
                data.available_days[2].isChecked === false &&
                data.available_days[3].isChecked === false &&
                data.available_days[4].isChecked === false &&
                data.available_days[5].isChecked === false &&
                data.available_days[6].isChecked === false
            ) return toast.warning('Please select atleast one day')
            setSpinner(true)
            axios
                .post(setAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        formik.resetForm()
                        getAvailabilityList()
                        setSpinner(false)
                        setShowAvailability(false)
                        closeToggleBtn()

                    }
                    else if (resp.data.code === statusCodes.tokenExpire) {
                        logOutFun()
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)

                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const editAvailability = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            let data = {
                Date: moment().format('YYYY-MM-DD'),
                timezone: values.timezone,
                availabilityId: availabilityId,
                providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                userId: staffId,
                branchId: values.branchId,
                available_days: [
                    {
                        id: 0,
                        name: 'Sunday',
                        isChecked: defaultFormValue[0].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 1,
                        name: 'Monday',
                        isChecked: defaultFormValue[1].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 2,
                        name: 'Tuesday',
                        isChecked: defaultFormValue[2].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 3,
                        name: 'Wednesday',
                        isChecked: defaultFormValue[3].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 4,
                        name: 'Thursday',
                        isChecked: defaultFormValue[4].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 5,
                        name: 'Friday',
                        isChecked: defaultFormValue[5].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 6,
                        name: 'Saturday',
                        isChecked: defaultFormValue[6].isChecked,
                        arrayOfTimings: [],
                    },
                ],
            }

            // Sunday
            if (editAvailability.values.startTimeSunday > editAvailability.values.endTimeSunday)
                return toast.warn('Sunday start time cannot be greater than end time');
            if (editAvailability.values.startTimeSunday && editAvailability.values.endTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeSunday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeSunday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeSunday > editAvailability.values.secondEndTimeSunday)
                return toast.warn('Sunday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeSunday && editAvailability.values.secondEndTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeSunday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeSunday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeSunday > editAvailability.values.thirdEndTimeSunday)
                return toast.warn('Sunday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeSunday && editAvailability.values.thirdEndTimeSunday)
                data.available_days[0].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeSunday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeSunday).format('HH:mm'),
                });

            // Monday
            if (editAvailability.values.startTimeMonday > editAvailability.values.endTimeMonday)
                return toast.warn('Monday start time cannot be greater than end time');
            if (editAvailability.values.startTimeMonday && editAvailability.values.endTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeMonday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeMonday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeMonday > editAvailability.values.secondEndTimeMonday)
                return toast.warn('Monday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeMonday && editAvailability.values.secondEndTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeMonday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeMonday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeMonday > editAvailability.values.thirdEndTimeMonday)
                return toast.warn('Monday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeMonday && editAvailability.values.thirdEndTimeMonday)
                data.available_days[1].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeMonday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeMonday).format('HH:mm'),
                });

            // Repeat for Tuesday to Saturday

            // Tuesday
            if (editAvailability.values.startTimeTuesday > editAvailability.values.endTimeTuesday)
                return toast.warn('Tuesday start time cannot be greater than end time');
            if (editAvailability.values.startTimeTuesday && editAvailability.values.endTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeTuesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeTuesday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeTuesday > editAvailability.values.secondEndTimeTuesday)
                return toast.warn('Tuesday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeTuesday && editAvailability.values.secondEndTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeTuesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeTuesday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeTuesday > editAvailability.values.thirdEndTimeTuesday)
                return toast.warn('Tuesday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeTuesday && editAvailability.values.thirdEndTimeTuesday)
                data.available_days[2].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeTuesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeTuesday).format('HH:mm'),
                });

            // Wednesday
            if (editAvailability.values.startTimeWednesday > editAvailability.values.endTimeWednesday)
                return toast.warn('Wednesday start time cannot be greater than end time');
            if (editAvailability.values.startTimeWednesday && editAvailability.values.endTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeWednesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeWednesday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeWednesday > editAvailability.values.secondEndTimeWednesday)
                return toast.warn('Wednesday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeWednesday && editAvailability.values.secondEndTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeWednesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeWednesday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeWednesday > editAvailability.values.thirdEndTimeWednesday)
                return toast.warn('Wednesday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeWednesday && editAvailability.values.thirdEndTimeWednesday)
                data.available_days[3].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeWednesday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeWednesday).format('HH:mm'),
                });

            // Thursday
            if (editAvailability.values.startTimeThursday > editAvailability.values.endTimeThursday)
                return toast.warn('Thursday start time cannot be greater than end time');
            if (editAvailability.values.startTimeThursday && editAvailability.values.endTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeThursday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeThursday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeThursday > editAvailability.values.secondEndTimeThursday)
                return toast.warn('Thursday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeThursday && editAvailability.values.secondEndTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeThursday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeThursday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeThursday > editAvailability.values.thirdEndTimeThursday)
                return toast.warn('Thursday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeThursday && editAvailability.values.thirdEndTimeThursday)
                data.available_days[4].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeThursday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeThursday).format('HH:mm'),
                });

            // Friday
            if (editAvailability.values.startTimeFriday > editAvailability.values.endTimeFriday)
                return toast.warn('Friday start time cannot be greater than end time');
            if (editAvailability.values.startTimeFriday && editAvailability.values.endTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeFriday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeFriday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeFriday > editAvailability.values.secondEndTimeFriday)
                return toast.warn('Friday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeFriday && editAvailability.values.secondEndTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeFriday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeFriday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeFriday > editAvailability.values.thirdEndTimeFriday)
                return toast.warn('Friday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeFriday && editAvailability.values.thirdEndTimeFriday)
                data.available_days[5].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeFriday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeFriday).format('HH:mm'),
                });

            // Saturday
            if (editAvailability.values.startTimeSaturday > editAvailability.values.endTimeSaturday)
                return toast.warn('Saturday start time cannot be greater than end time');
            if (editAvailability.values.startTimeSaturday && editAvailability.values.endTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.startTimeSaturday).format('HH:mm'),
                    endTime: moment(editAvailability.values.endTimeSaturday).format('HH:mm'),
                });

            if (editAvailability.values.secondStartTimeSaturday > editAvailability.values.secondEndTimeSaturday)
                return toast.warn('Saturday second start time cannot be greater than second end time');
            if (editAvailability.values.secondStartTimeSaturday && editAvailability.values.secondEndTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.secondStartTimeSaturday).format('HH:mm'),
                    endTime: moment(editAvailability.values.secondEndTimeSaturday).format('HH:mm'),
                });

            if (editAvailability.values.thirdStartTimeSaturday > editAvailability.values.thirdEndTimeSaturday)
                return toast.warn('Saturday third start time cannot be greater than third end time');
            if (editAvailability.values.thirdStartTimeSaturday && editAvailability.values.thirdEndTimeSaturday)
                data.available_days[6].arrayOfTimings.push({
                    startTime: moment(editAvailability.values.thirdStartTimeSaturday).format('HH:mm'),
                    endTime: moment(editAvailability.values.thirdEndTimeSaturday).format('HH:mm'),
                });
            setSpinner(true)
            axios
                .post(updateAvailabilityApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getAvailabilityList()
                        closeToggleBtn()
                        setSpinner(false)
                        setShowEditAvailability(false)

                    }
                    else if (resp.data.code === statusCodes.tokenExpire) {
                        logOutFun()
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const valueChangeToggleSwitch = (day) => {
        let formValues = defaultFormValue
        formValues[day].isChecked = !formValues[day].isChecked
        setFormValue([...formValues])
    }
    const showAdditionalTimingsBox = (day) => {
        let formValues = defaultFormValue
        if (formValues[day].isAllowedTimes.first && formValues[day].isAllowedTimes.second) return toast.warn('Maximun 3 times allowed')
        if (!formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.first = true
        else if (formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.second = true
        setFormValue([...formValues])
    }
    const hideAdditionalTimingsBox = (day, index) => {
        let formValues = defaultFormValue
        switch (index) {
            case 1:
                formValues[day].isAllowedTimes.first = false
                break
            case 2:
                formValues[day].isAllowedTimes.second = false
                break
            default:
        }
        setFormValue([...formValues])
    }
    const closeToggleBtn = () => {
        setFormValue([...initialState])
    }
    /* Availability End */

    /* Unavailability */
    const timeZone = () => {
        axios.post(timeZoneApi(), { isActive: true, count: 500 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTimeZoneData(resp.data.data)
                const timeZone = resp.data.data.find((item) => item.name === 'Asia/Kolkata')
                formik.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
                setUnavailability.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
            }
        })
    }
    const setCurrentTimeZone = () => {
        const timeZone = timeZoneData.find((item) => item.name === 'Asia/Kolkata')
        if (tab === 2) {
            formik.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
        }
        else {
            setUnavailability.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
        }
    }
    const setUnavailability = useFormik({
        initialValues: {
            timeZone: '',
            branch: '',
            startDate: '',
            endDate: '',
            startTime: null,
            endTime: null,
            secondStartTime: null,
            secondEndTime: null,
            thirdStartTime: null,
            thirdEndTime: null,

        },
        validationSchema: unAvailabilityValidation,
        onSubmit: (values) => {
            let data = {
                branchId: values.branch,
                timezone: values.timeZone,
                staffId: staffId,
                // createdby_id: userData?.userId,
                // providerId: userData?.userId,
                providerId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                startDate: values.startDate,
                endDate: values.endDate,
                timingArr: [],
            }
            if (setUnavailability.values.startTime > setUnavailability.values.endTime) return toast.warn('Start time can not be greater than end time')
            if (setUnavailability.values.startTime && setUnavailability.values.endTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.startTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.endTime).format('HH:mm'),
                })

            if (setUnavailability.values.secondStartTime > setUnavailability.values.secondEndTime) return toast.warn('Second start time time can not be greater than second end time')
            if (setUnavailability.values.secondStartTime && setUnavailability.values.secondEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.secondStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.secondEndTime).format('HH:mm'),
                })

            if (setUnavailability.values.thirdStartTime > setUnavailability.values.thirdEndTime) return toast.warn('Third start time time can not be greater than third end time')
            if (setUnavailability.values.thirdStartTime && setUnavailability.values.thirdEndTime)
                data.timingArr.push({
                    startTime: moment(setUnavailability.values.thirdStartTime).format('HH:mm'),
                    endTime: moment(setUnavailability.values.thirdEndTime).format('HH:mm'),
                })
            setSpinner(true)
            axios
                .post(unAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        setUnavailability.resetForm()
                        unAvailabilityList()
                        setSpinner(false)
                        setShowUnAvailability(false)

                    }
                    else if (resp.data.code === statusCodes.tokenExpire) {
                        logOutFun()
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)

                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const unAvailabilityList = () => {
        const data = {
            providerId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            staffId: staffId,
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            page: page,
            count: PER_PAGE,
            branchId: userData?.locationId
        }
        axios.post(unAvailabilityLists(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setUnAvailablityData(resp.data.data)
                setSpinner(false)
                setCount(resp.data.totalCount)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
                setUnAvailablityData([])
            }
        }).catch(() => { })
    }
    const unavailabilityDelete = (id) => {
        axios.post(unavailabilityDeleteApi(), { unAvailabilityId: id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                unAvailabilityList()
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const handlePermissionChange = (categoryTitle, keyTitle) => {
        setPermissions(prevPermissions => {
            return prevPermissions.map(category => {
                if (category.title === categoryTitle) {
                    const updatedPermissionData = category.permissionData.map(permission => {
                        if (permission.title === keyTitle) {
                            return {
                                ...permission,
                                data: {
                                    ...permission.data,
                                    value: !permission.data.value
                                }
                            };
                        }
                        return permission;
                    });
                    return {
                        ...category,
                        permissionData: updatedPermissionData
                    };
                }
                return category;
            });
        });
    };
    const list = () => {
        axios.post(listRole(), {},).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRoleList(resp.data.data)
            }
            else {
                setRoleList([])
            }
        })
            .catch(() => {
            })
    }
    const handleMarkAll = (value) => {
        const updatedPermissions = permissions.map(category => ({
            ...category,
            permissionData: category.permissionData.map(permission => {
                return {
                    ...permission,
                    data: {
                        ...permission.data,
                        value: value
                    }
                };
            })
        }));

        setPermissions(updatedPermissions)
    };
    const permissionsCheck = () => {
        const updatedPermissions = permissions?.map(existingPermission => {
            const incomingPermission = ViewPermissions?.find(permission => permission?.title === existingPermission?.title);
            if (incomingPermission) {
                return {
                    ...existingPermission,
                    permissionData: existingPermission?.permissionData?.map(existingData => {
                        const incomingData = incomingPermission?.permissionData?.find(data => data?.title === existingData?.title);
                        if (incomingData) {
                            return {
                                ...existingData,
                                data: {
                                    ...existingData?.data,
                                    value: incomingData?.data?.value
                                }
                            };
                        }
                        return existingData;
                    })
                };
            }
            return existingPermission;
        });
        setSelectAll(!anyValueTrue(updatedPermissions))
        setPermissions(updatedPermissions);
    }
    const anyValueTrue = (permissions) => {
        for (const permission of permissions) {
            if (permission.permissionData.some(data => data.data.value === true)) {
                return true;
            }
        }
        return false;
    }
    const handleTogglePermissions = () => {
        const checkbox = document.getElementById('item-2');
        checkbox.checked = !checkbox.checked;
    };

    return {
        timeZone,
        unAvailabilityList,
        unavailabilityDelete,
        getAvailabilityList,
        getAvailability,
        valueChangeToggleSwitch,
        showAdditionalTimingsBox,
        hideAdditionalTimingsBox,
        closeToggleBtn,
        logOutFun,
        StaffBranch,
        getBranchList,
        multipleServicesList,
        profileView,
        uploadImage,
        setFormValue,
        setListData,
        setAvailablityData,
        setAvailabilityId,
        setTimeZoneData,
        setUnAvailablityData,
        setShowAvailability,
        setShowUnAvailability,
        setImageData,
        setSelectedBranch,
        setBranchList,
        serServiceList,
        setSelectedService,
        setStaffBranchesData,
        setSpinner,
        setBranchStatus,
        setCount,
        setPage,
        setDisableBranch,
        setTab,
        navigate,
        setPermissions,
        handlePermissionChange,
        handleMarkAll,
        setPermissionBtn,
        setSelectAll,
        permissionsList,
        permissionsCheck,
        showEditAvailability,
        setShowEditAvailability,
        handleTogglePermissions,
        setCurrentTimeZone,
        permissionBtn,
        selectAll,
        roleList,
        permissions,
        initialState,
        setUnavailability,
        formik,
        editAvailability,
        teamProfile,
        staffId,
        userData,
        defaultFormValue,
        listData,
        availablityData,
        availabilityId,
        timeZoneData,
        unAvailablityData,
        showAvailability,
        showUnAvailability,
        imageData,
        selectedBranch,
        branchList,
        serviceList,
        selectedService,
        staffBranchesData,
        spinner,
        branchStatus,
        count,
        page,
        PER_PAGE,
        disableBranch,
        tab,
        branchData,
        serviceData,
        date,
        currentTimeZone,
        imgData,
        fileInputRef,
        userInfo

    }
}

export default UseTeamProfile
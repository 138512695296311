/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DummyLogo from "../../Assets/images/dummyimg.png";
import Group from "../../Assets/images/group.png";
import moment from "moment/moment";
import { ToastContainer } from "react-toastify";
import logo from "../../Assets/images/logo white color.png";
import Spinner from "../../Components/spinner/spinner";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Picker from "emoji-picker-react";
import UseChat from './Hooks/useChat';
import GroupInfo from './groupInfo';
import GroupModal from './groupModal';
import DirectModal from './directModal';
import ClientModal from './clientModal';
import { Modal } from 'react-bootstrap';
import { intialRole } from '../../Utils/Utils';

const Chat = () => {
  const {
    handleSendMessage,
    handleEditMessage,
    handleDeleteMessage,
    enterSendMessage,
    teamMember,
    Conversation,
    ConversationList,
    ConversationDelete,
    ConversationBlock,
    enterSearch,
    selectConversation,
    getClientList,
    uploadImage,
    handleCheckboxChange,
    groupDetail,
    memberAddInGroup,
    memberRemoveInGroup,
    groupSetting,
    handleScroll,
    matchTime,
    onEmojiClickEdit,
    onEmojiClick,
    pdfDownload,
    deleteDocument,
    uploadPdf,
    groupUpdate,
    setProfile,
    setSearchText,
    setSendMessage,
    setType,
    setGroupName,
    setShowModal,
    setShowGroupInfo,
    setAddMember,
    setUpdateBtn,
    setChecked,
    setMsgId,
    setEditMsg,
    setUploadData,
    setGroupUpdateSettingShow,
    setIndex,
    setShowPicker,
    setShowEditPicker,
    setConversationType,
    directModalShow, setDirectModalShow,
    clientModalShow, setClientModalShow,
    teamFilteredData,
    clientFilteredData,
    teamMemberData,
    conversationData,
    profile,
    spinner,
    userData,
    sendMessage,
    receiveMessages,
    type,
    file,
    groupName,
    selectedValues,
    showModal,
    showGroupInfo,
    addMember,
    groupDetailData,
    updateBtn,
    checked,
    msgId,
    editMsg,
    uploadData,
    index,
    messageEl,
    fileInputRef,
    disableAdmin,
    showPicker,
    showEditPicker,
    imgData,
    conversationType,
    userInfo
  } = UseChat()

  return (
    <>
      <ToastContainer />
      <section>
        {spinner ? <Spinner /> : null}
        <div className="m-t-3 cusheight" style={{ borderRadius: '15px' }}>
          {intialRole({ permission: "chat", userData: userInfo, section: 'view' }) ?
            <div className="app" style={{ borderRadius: '15px' }}>
              <div className="row app-one">
                <div className="col-sm-4 side phonehide">
                  <div className="side-one">
                    <div className="row main-heading2">
                      <div className="col-sm-3 col-xs-3 heading-avatar">
                        <div className="heading-avatar-icon">
                          <h2 className="page-tittle mt-3 ms-1">
                            Chats
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="modal-body">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item mainn ms-4" role="presentation">
                            <button onClick={() => { setType('INDIVIDUAL'); setProfile([]); setIndex(null); setUploadData(''); setConversationType((pre) => ({ ...pre, INDIVIDUAL: "" })) }} className="nav-link active" id="Direct-tab" style={{ background: '#fff' }} data-bs-toggle="tab" data-bs-target="#Direct" type="button" role="tab" aria-controls="Direct" aria-selected="true">Direct</button>
                          </li>
                          {conversationType.INDIVIDUAL === type ? null : conversationType.INDIVIDUAL === 'INDIVIDUAL' ? <div className='chatTabberNotification'></div> : null}
                          {
                            intialRole({ permission: "chat", userData: userInfo, section: 'ViewGroupChat' }) ?
                              <>
                                <li className="nav-item mainn ms-4" role="presentation">
                                  <button onClick={() => { setType('GROUP'); setProfile([]); setIndex(null); setUploadData(''); setConversationType((pre) => ({ ...pre, GROUP: "" })) }} className="nav-link" id="Group-tab" style={{ background: '#fff' }} data-bs-toggle="tab" data-bs-target="#Group" type="button" role="tab" aria-controls="Group" aria-selected="false">Groups</button>
                                </li>
                                {conversationType.GROUP === type ? null : conversationType.GROUP === 'GROUP' ? <div className='chatTabberNotification'></div> : null}
                              </>
                              : null}
                          {
                            intialRole({ permission: "chat", userData: userInfo, section: 'ViewClientChat' }) ?
                              <>
                                <li className="nav-item mainn ms-4" role="presentation">
                                  <button onClick={() => { setType('CLIENT'); setProfile([]); setIndex(null); setUploadData(''); setConversationType((pre) => ({ ...pre, CLIENT: "" })) }} className="nav-link" id="Client-tab" style={{ background: '#fff' }} data-bs-toggle="tab" data-bs-target="#Client" type="button" role="tab" aria-controls="Client" aria-selected="false">Clients</button>
                                </li>
                                {conversationType.CLIENT === type ? null : conversationType.CLIENT === 'CLIENT' ? <div className='chatTabberNotification'></div> : null}
                              </>
                              : null}
                        </ul>
                        <div className="tab-content py-3" id="myTabContent">
                          {/* Direct Tab */}
                          <div className="tab-pane fade show active newtabs" id="Direct" role="tabpanel" aria-labelledby="Direct-tab">
                            <div className="row chat_searchBox">
                              <div className="col-sm-12 searchBox-inner">
                                <div className="form-group has-feedback d-flex">
                                  <input id="searchText" type="text" className="form-control" name="searchText" placeholder="Search" style={{ marginLeft: '12px', border: '1px solid #ced4da' }}
                                    onKeyDown={(e) => enterSearch(e)}
                                    onChange={(e) => {
                                      if (e.target.value.length === 0) {
                                        ConversationList()
                                      }
                                    }} />
                                  <span className="glyphicon glyphicon-search form-control-feedback"></span>
                                  <i className="fa-solid fa-plus mt-2 ms-2" onClick={() => { teamMember(); setSearchText(''); setDirectModalShow(true) }}></i>
                                </div>
                              </div>
                            </div>
                            <div className="row sideBar">
                              <div className='chat_scroll'>
                                {
                                  conversationData.map((item, i) =>
                                    <div key={i}>
                                      <div className={`row ${index === i ? 'sideBar-body-active' : 'sideBar-body'}`} onClick={() => {
                                        setIndex(i)
                                        setUploadData('');
                                        selectConversation(item)
                                      }}>
                                        <div className="col-sm-3 col-xs-3 sideBar-avatar" onClick={() => selectConversation(item)}>
                                          <div className="avatar-icon">
                                            <img src={item?.image === '' || item?.image === null ? DummyLogo : imgData + item?.image} alt="img" />
                                          </div>
                                        </div>
                                        <div className="col-sm-9 col-xs-9 sideBar-main" >
                                          <div className="row d-flex" >
                                            <div className="col-sm-6 col-xs-8 sideBar-name" >
                                              <span className={`${index === i ? 'name-meta-active' : 'name-meta'}`}>{item?.name}
                                              </span>
                                              <p className={`${index === i ? 'text-light' : 'text-secondary'} mb-2`}>{item?.lastMessages[0]?.message.length ? (item.lastMessages[0]?.message.slice(0, 10) + (item.lastMessages[0]?.message.length > 10 ? "..." : "")) : null}</p>
                                            </div>
                                            <div className="col-sm-6 col-xs-4 pull-right sideBar-time d-flex justify-content-end">
                                              {item?.unReadMsg === 0 ?
                                                <div className={`${index === i ? 'name-meta-active' : 'name-meta'} font12 mt-3`}>{moment(item?.lastMessages[0]?.msgTime).format('LT')}</div>
                                                :
                                                <div className='flex-column me-2'>
                                                  <span className="time-meta pull-right">{moment(item?.lastMessages[0].msgTime).format('LT')}
                                                  </span>
                                                  <br />
                                                  <span className='chat_notification'>{item?.unReadMsg}</span>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="overlay_chat">
                                            <i className="fa-solid fa-chevron-down" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                            <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="dropdownMenuLink">
                                              <a className="dropdown-item" onClick={() => { ConversationDelete(item?.conversation_id) }}>Delete Conversation</a>
                                              <a className="dropdown-item" onClick={() => ConversationBlock(item)}>{!item?.isBlocked ? "Block" : "Unblock"}</a>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  )
                                }
                              </div>
                            </div>
                          </div>
                          {/* Group Tab */}
                          <div className="tab-pane fade" id="Group" role="tabpanel" aria-labelledby="Group-tab">
                            <div className="row chat_searchBox">
                              <div className="col-sm-12 searchBox-inner">
                                <div className="form-group has-feedback d-flex">
                                  <input id="searchText" type="text" className="form-control" name="searchText" placeholder="Search" style={{ marginLeft: '12px', border: '1px solid #ced4da' }}
                                    onKeyDown={(e) => enterSearch(e)}
                                    onChange={(e) => {
                                      if (e.target.value.length === 0) {
                                        ConversationList()
                                      }
                                    }} />
                                  <span className="glyphicon glyphicon-search form-control-feedback"></span>
                                  <i className="fa-solid fa-plus mt-2 ms-2" onClick={() => { teamMember(); setShowModal(true); setSearchText('') }}></i>
                                </div>
                              </div>
                            </div>
                            <div className="row sideBar">
                              <div className='chat_scroll'>
                                {
                                  conversationData.map((item, i) =>

                                    <div className={`row ${index === i ? 'sideBar-body-active' : 'sideBar-body'}`} key={i} onClick={() => {
                                      selectConversation(item)
                                      setIndex(i)
                                      setUploadData('')
                                    }}>
                                      <div className="col-sm-3 col-xs-3 sideBar-avatar">
                                        <div className="avatar-icon">
                                          <img src={item.image === '' || item.image === null ? DummyLogo : imgData + item.image} alt="img" />
                                        </div>
                                      </div>
                                      <div className="col-sm-9 col-xs-9 sideBar-main">
                                        <div className="row d-flex">
                                          <div className="col-sm-6 col-xs-8 sideBar-name">
                                            <span className={`${index === i ? 'name-meta-active' : 'name-meta'}`}>{item.name}
                                            </span>
                                            <p className={`${index === i ? 'text-light' : 'text-secondary'} mb-2`}>{item.lastMessages[0]?.message.length ? (item.lastMessages[0]?.message.slice(0, 10) + (item.lastMessages[0]?.message.length > 10 ? "..." : "")) : null}</p>
                                          </div>
                                          <div className="col-sm-6 col-xs-4 pull-right sideBar-time sideBar-time d-flex justify-content-end">
                                            {item.unReadMsg === 0 ?
                                              <div className={`${index === i ? 'name-meta-active' : 'name-meta'} font12 mt-3`}>{moment(item?.lastMessages[0]?.msgTime).format('LT')}</div>
                                              :
                                              <div div className='flex-column me-2'>
                                                <span className="time-meta pull-right">{moment(item.lastMessages[0].msgTime).format('LT')}
                                                </span>
                                                <br />
                                                <span className='chat_notification'>{item.unReadMsg}</span>

                                              </div>
                                            }
                                            <div className="overlay_chat">
                                              <i className="fa-solid fa-chevron-down" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                              <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="dropdownMenuLink">
                                                <a className="dropdown-item" onClick={() => memberRemoveInGroup(userData?.userId)}>Leave Group</a>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          {/* Client Tab */}
                          <div className="tab-pane fade" id="Client" role="tabpanel" aria-labelledby="Client-tab">
                            <div className="row chat_searchBox">
                              <div className="col-sm-12 searchBox-inner">
                                <div className="form-group has-feedback d-flex">
                                  <input id="searchText" type="text" className="form-control" name="searchText" placeholder="Search" style={{ marginLeft: '12px', border: '1px solid #ced4da' }}
                                    onKeyDown={(e) => enterSearch(e)}
                                    onChange={(e) => {
                                      if (e.target.value.length === 0) {
                                        ConversationList()
                                      }
                                    }} />
                                  <span className="glyphicon glyphicon-search form-control-feedback"></span>
                                  <i className="fa-solid fa-plus mt-2 ms-2" onClick={() => { getClientList(); setSearchText(''); setClientModalShow(true) }}></i>
                                </div>
                              </div>
                            </div>
                            <div className="row sideBar">
                              <div className='chat_scroll'>
                                {
                                  conversationData.map((item, i) =>

                                    <div className={`row ${index === i ? 'sideBar-body-active' : 'sideBar-body'}`} key={i} onClick={() => {
                                      setIndex(i)
                                      setUploadData('')
                                      selectConversation(item)
                                    }}>
                                      <div className="col-sm-3 col-xs-3 sideBar-avatar" onClick={() => selectConversation(item)}>
                                        <div className="avatar-icon">
                                          <img src={item.image === '' || item.image === null ? DummyLogo : imgData + item.image} alt="img" />
                                        </div>
                                      </div>
                                      <div className="col-sm-9 col-xs-9 sideBar-main">
                                        <div className="row d-flex">
                                          <div className="col-sm-6 col-xs-8 sideBar-name">
                                            <span className={`${index === i ? 'name-meta-active' : 'name-meta'}`}>{item.name}
                                            </span>
                                            <p className={`${index === i ? 'text-light' : 'text-secondary'} mb-2`}>{item.lastMessages[0]?.message.length ? (item.lastMessages[0]?.message.slice(0, 10) + (item.lastMessages[0]?.message.length > 10 ? "..." : "")) : null}</p>
                                          </div>
                                          <div className="col-sm-6 col-xs-4 pull-right sideBar-time d-flex justify-content-end">
                                            {item.unReadMsg === 0 ?
                                              <div className={`${index === i ? 'name-meta-active' : 'name-meta'} font12 mt-3`}>{moment(item?.lastMessages[0]?.msgTime).format('LT')}</div>
                                              :
                                              <div className='flex-column me-2'>
                                                <span className="time-meta pull-right">{moment(item.lastMessages[0].msgTime).format('LT')}
                                                </span>
                                                <br />
                                                <span className='chat_notification'>{item.unReadMsg}</span>

                                              </div>
                                            }
                                          </div>
                                        </div>
                                        <div className="overlay_chat">
                                          <i className="fa-solid fa-chevron-down" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                          <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" onClick={() => ConversationDelete(item.conversation_id)}>Delete Conversation</a>
                                            <a className="dropdown-item" onClick={() => ConversationBlock(item)}>{!item.isBlocked ? "Block" : "Unblock"}</a>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Chat Start */}
                <div className="col-sm-8 conversation">
                  {
                    !profile.length ? <div className='text-center'>
                      <img src={logo} alt="" style={{ marginTop: '30%' }} width={150} height={150} />
                      <p style={{ marginTop: '5%' }}>Click the&nbsp; <span style={{ fontSize: '18px', fontWeight: '900' }}>+</span> &nbsp;icon to initiate a conversation with the people.</p>
                    </div>
                      :
                      <>
                        <div className="row main-heading" onClick={() => { type === 'GROUP' ? groupDetail(profile[0].conversation_id) : setShowGroupInfo(false) }}>
                          <div className='col-sm-12 d-flex'>
                            <img className='heading-avatar-icon' src={profile[0].image === '' || profile[0].image == null ? DummyLogo : imgData + profile[0].image} alt="img" />
                            <p className="heading-name-meta ms-1 mt-1">{profile[0].name}
                            </p>
                          </div>
                        </div>

                        <div className="row message" id="conversation" style={{ width: '100%' }} ref={messageEl} onScroll={handleScroll}>
                          <div className='col-sm-12 mt-5 d-flex flex-column width-100'>


                            {

                              receiveMessages?.messagesData?.allMessages?.map((item, i) =>
                                <div key={i}>
                                  <span className='d-flex justify-content-center'>{matchTime(item.msgTime, receiveMessages?.messagesData?.allMessages[i - 1]?.msgTime)}</span>
                                  <div className='d-flex d-flex flex-column'>
                                    <div className={`${item.sender_id !== userData?.userId ? 'd-flex  justify-content-between' : 'd-flex justify-content-end'} msg_send`}>
                                      <div className='d-flex-justify-content-start'>
                                        {type === 'GROUP' ? <span className='chat_Time me-1'>{item.userData?.name}</span> : null}
                                        <span className='chat_Time'>{moment(item.msgTime).format('LT')}</span>
                                        {item.isEdited ? <span className='chat_Time ms-1'>Edited</span> : null}
                                      </div>
                                      <div>
                                        {
                                          item?.sender_id === userData?.userId ?
                                            !item.isDeleted ?
                                              <span className="overlay_chat_send">
                                                <MoreHorizIcon className='cursor float-end' id="chatLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                                <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="chatLink">
                                                  {item.document.length ? null : <a className="dropdown-item cursor" onClick={() => { setMsgId(item.message_id); setEditMsg(item.message) }}>Edit</a>}
                                                  <a className="dropdown-item cursor" onClick={() => { handleDeleteMessage(item?.message_id) }}>Delete</a>
                                                </ul>
                                              </span>
                                              : null

                                            : null
                                        }
                                      </div>
                                    </div>
                                    <div key={i} className={`${item?.sender_id !== userData?.userId ? 'receive_message_box' : "send_message_box"} d-flex w-100`}>
                                      {
                                        msgId === item.message_id ?
                                          <div>
                                            <div className='d-flex col-sm-6 float-end'>
                                              {
                                                item.sender_id === userData?.userId ?
                                                  <div className='w-100'>
                                                    <input type="text" className='form-control' name="" id="" value={editMsg} onChange={(e) => setEditMsg(e.target.value)} />
                                                    <div className='me-2 mt-2'>
                                                      <SentimentSatisfiedAltIcon className='cursor mb-2' onClick={() => setShowEditPicker((val) => !val)} />
                                                      <i className="fa-solid fa-check float-end cursor ms-3" onClick={() => handleEditMessage()}></i>
                                                      <i className="fa-solid fa-xmark float-end cursor" onClick={() => setMsgId('')}></i>
                                                    </div>
                                                  </div> : null
                                              }

                                            </div>
                                          </div>
                                          : (
                                            <div>
                                              <div
                                                className={`${item.sender_id !== userData?.userId
                                                  ? "receive_message"
                                                  : "send_message"
                                                  } `}
                                              >
                                                {item.document.length ? (
                                                  <div
                                                    className="chat-doc-box"
                                                    onClick={() =>
                                                      item.isDeleted
                                                        ? null
                                                        : pdfDownload(item.document)
                                                    }
                                                  >
                                                    {item.document}
                                                    <i className="fa-solid fa-download ms-2"></i>
                                                  </div>
                                                ) : null}
                                                {item.message}
                                              </div>
                                            </div>
                                          )}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                          {/* <div>
                                                    {showPicker && (
                                                        <Picker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClick} onClickOutside={() => {
                                                            setShowPicker(false);
                                                        }} />
                                                    )}
                                                </div> */}
                        </div>
                        {uploadData ? (
                          <div className="d-flex justify-content-center">
                            <div className="document_box">
                              {uploadData}
                              <span className="cursor" onClick={deleteDocument}>
                                X
                              </span>
                            </div>
                          </div>
                        ) : null}

                        {/* send Message Start*/}
                        <div className="row reply">
                          {profile[0].isBlocked ? (
                            <div className="d-flex justify-content-evenly">
                              <p
                                className="mt-1"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                You have blocked this user
                              </p>
                              <button
                                className="btn btn_Style btn-sm"
                                onClick={() => ConversationBlock(profile[0])}
                              >
                                Unblock
                              </button>
                            </div>
                          ) : type === "GROUP" ? (
                            receiveMessages.isAdmin ? (
                              <>
                                <div className="col-sm-1 col-xs-1 reply-emojis">
                                  <label htmlFor="inputTag" className="">
                                    <input
                                      id="inputTag"
                                      type="file"
                                      style={{ display: "none" }}
                                      ref={fileInputRef}
                                      onChange={(e) => uploadPdf(e.target.files[0])}
                                    />
                                    <AttachFileIcon className="mt-1 heading-color cursor" />
                                  </label>
                                  <SentimentSatisfiedAltIcon
                                    className="mt-1  heading-color cursor"
                                    onClick={() => {
                                      setShowPicker((val) => !val);
                                    }}
                                  />
                                </div>
                                <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sendMessage}
                                    onKeyDown={(e) => enterSendMessage(e)}
                                    onChange={(e) => setSendMessage(e.target.value)}
                                    placeholder="Type a message"
                                  />
                                </div>
                                <div className="col-sm-1 col-xs-1 reply-recording">
                                  <div
                                    style={{
                                      width: "35px",
                                      height: "33px",
                                      background: "#f47d3a",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <SendIcon
                                      className="mt-1 ms-2 text-light cursor"
                                      onClick={() =>
                                        sendMessage.length || uploadData.length
                                          ? handleSendMessage()
                                          : null
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : !receiveMessages.onlyAdminCanMessage ? (
                              <>
                                <div className="col-sm-1 col-xs-1 reply-emojis">
                                  <label htmlFor="inputTag" className="">
                                    <input
                                      id="inputTag"
                                      type="file"
                                      style={{ display: "none" }}
                                      ref={fileInputRef}
                                      onChange={(e) => uploadPdf(e.target.files[0])}
                                    />
                                    <AttachFileIcon className="mt-1 heading-color cursor" />
                                  </label>
                                  <SentimentSatisfiedAltIcon
                                    className="mt-1  heading-color cursor"
                                    onClick={() => {
                                      setShowPicker((val) => !val);
                                    }}
                                  />
                                </div>
                                <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sendMessage}
                                    onKeyDown={(e) => enterSendMessage(e)}
                                    onChange={(e) => setSendMessage(e.target.value)}
                                    placeholder="Type a message"
                                  />
                                </div>
                                <div className="col-sm-1 col-xs-1 reply-recording">
                                  <div
                                    style={{
                                      width: "35px",
                                      height: "33px",
                                      background: "#f47d3a",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <SendIcon
                                      className="mt-1 ms-2 text-light cursor"
                                      onClick={() =>
                                        sendMessage.length || uploadData.length
                                          ? handleSendMessage()
                                          : null
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <p
                                className="d-flex justify-content-center  mt-1"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Only admins can message
                              </p>
                            )
                          ) : (
                            <>
                              <div className="col-sm-1 col-xs-1 reply-emojis float-end">
                                <label htmlFor="inputTag" className="">
                                  <input
                                    id="inputTag"
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={(e) => {
                                      uploadPdf(e.target.files[0]);
                                    }}
                                  />
                                  <AttachFileIcon className="mt-1 heading-color cursor" />
                                </label>
                                <SentimentSatisfiedAltIcon
                                  className="heading-color cursor"
                                  onClick={() => {
                                    setShowPicker((val) => !val);
                                  }}
                                />
                              </div>
                              <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  value={sendMessage}
                                  onKeyDown={(e) => enterSendMessage(e)}
                                  onChange={(e) => setSendMessage(e.target.value)}
                                  placeholder="Type a message"
                                />
                              </div>
                              <div className="col-sm-1 col-xs-1 reply-recording">
                                <div
                                  style={{
                                    width: "35px",
                                    height: "33px",
                                    background: "#f47d3a",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <SendIcon
                                    className="mt-1 ms-2 text-light cursor"
                                    onClick={() =>
                                      sendMessage.length || uploadData.length
                                        ? handleSendMessage()
                                        : null
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/* Emoji  */}
                        <Modal
                          centered
                          show={showPicker}
                          size="sm"
                          onHide={() => setShowPicker(false)}
                        >
                          <div className="d-flex justify-content-end me-3 mt-3">
                            <i
                              className="fa-solid fa-x cursor"
                              onClick={() => setShowPicker(false)}
                            ></i>
                          </div>
                          <Picker
                            onEmojiClick={onEmojiClick}
                            onClickOutside={() => {
                              setShowPicker(false);
                            }}
                          />
                        </Modal>
                        {/* Emoji  */}

                        {/* Emoji  Edit*/}
                        <Modal
                          centered
                          show={showEditPicker}
                          size="sm"
                          onHide={() => setShowEditPicker(false)}
                        >
                          <div className="d-flex justify-content-end me-3 mt-3">
                            <i
                              className="fa-solid fa-x cursor"
                              onClick={() => setShowEditPicker(false)}
                            ></i>
                          </div>
                          <Picker
                            pickerStyle={{ width: "100%" }}
                            onEmojiClick={onEmojiClickEdit}
                          />
                        </Modal>
                        {/* Emoji Edit */}

                        {/* send Message End*/}
                      </>
                  }
                </div>
                {/* Chat End */}
              </div>
            </div>
            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>

        <DirectModal
          setSearchText={setSearchText}
          teamFilteredData={teamFilteredData}
          Conversation={Conversation}
          imgData={imgData}
          directModalShow={directModalShow}
          setDirectModalShow={setDirectModalShow}
        />

        <GroupModal
          showModal={showModal}
          setShowModal={setShowModal}
          file={file}
          Group={Group}
          imgData={imgData}
          uploadImage={uploadImage}
          setGroupName={setGroupName}
          setSearchText={setSearchText}
          teamFilteredData={teamFilteredData}
          handleCheckboxChange={handleCheckboxChange}
          groupName={groupName}
          Conversation={Conversation}
          selectedValues={selectedValues}
        />

        <GroupInfo
          showGroupInfo={showGroupInfo}
          setShowGroupInfo={setShowGroupInfo}
          setGroupUpdateSettingShow={setGroupUpdateSettingShow}
          setAddMember={setAddMember}
          addMember={addMember}
          teamMemberData={teamMemberData}
          groupDetailData={groupDetailData}
          imgData={imgData}
          disableAdmin={disableAdmin}
          memberAddInGroup={memberAddInGroup}
          groupSetting={groupSetting}
          updateBtn={updateBtn}
          memberRemoveInGroup={memberRemoveInGroup}
          userData={userData}
          file={file}
          Group={Group}
          teamMember={teamMember}
          groupUpdate={groupUpdate}
          moment={moment}
          checked={checked}
          setChecked={setChecked}
          uploadImage={uploadImage}
          groupName={groupName}
          setGroupName={setGroupName}
          setUpdateBtn={setUpdateBtn}
        />

        <ClientModal
          setSearchText={setSearchText}
          clientFilteredData={clientFilteredData}
          imgData={imgData}
          Conversation={Conversation}
          clientModalShow={clientModalShow}
          setClientModalShow={setClientModalShow}
        />
      </section>
    </>
  );
};

export default Chat;

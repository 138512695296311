import React from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment/moment";
import Calendars from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import ScrollBars from "react-scrollbar";
import ValidationMessage from "../ValidationMessage/validationMessage";
import Spinner from "../../Components/spinner/spinner";
import { Calendar } from "react-big-calendar";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import { Modal } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UseCalendar from './Hooks/useCalendar'
import AddTeamUnAvailability from '../Team/addTeamUnAvailability'
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { intialRole } from '../../Utils/Utils'

const AddAppointment = () => {
  const {
    teamMembers,
    timesSlot,
    onRangeChange,
    getBranchList,
    getStaffList,
    multipleServicesList,
    handleSelectEvent,
    dataFun,
    offerApply,
    offerList,
    handleSelectSlot,
    loadClientOptions,
    teamAvailableId,
    unavailabilityDelete,
    servicesList,
    priceFun,
    mobFun,
    appointmentReschedule,
    appointmentDelete,
    navigate,
    setStaffList,
    setSlots,
    setModalView,
    setShowReschedule,
    setDeleteModal,
    setUnAvailabilityDelete,
    setSlotTime,
    setShow,
    setShowModal,
    setClientPerPage,
    setCalendarValue,
    setStaffId,
    setSlotSelect,
    setMultipleAppointments,
    setSingleSlot,
    setOfferListData,
    onShowMore,
    setCurrentTeam,
    setCurrentBranch,
    setDaySelected,
    setMultipleData,
    setPopup,
    setAppointmentSlotModal,
    setAppointmentSlotBookAgain,
    appointmentSlotBookAgain,
    appointmentSlotModal,
    popup,
    showModal,
    slots,
    bookAppointment,
    cancelAppointment,
    branchListData,
    staffList,
    serviceCategory,
    serviceList,
    show,
    spinner,
    EVENTS,
    localizer,
    modalView,
    showReschedule,
    appointmentViewData,
    deleteModal,
    unAvailabilityDelete,
    slotTime,
    calendarValue,
    currentBranch,
    multipleData,
    slotSelect,
    multipleAppointments,
    singleSlot,
    offerListData,
    teamMember,
    currentTeam,

    setUnavailability,
    showUnAvailability,
    setShowUnAvailability,
    timeZone,
    timeZoneList,
    defaultFormValue,
    showAdditionalTimingsBox,
    hideAdditionalTimingsBox,
    teamMembersUnAvailibility,
    teamAccordingBranch,
    userInfo,
    userData
  } = UseCalendar()

  return (
    <>
      <ToastContainer />
      <section className=" position-relative">
        <div className="m-t-3 cus-padd cusheight">
          {spinner ? <Spinner /> : null}
          <h2 className="page-tittle">
            <i
              className="fa-solid fa-angle-left cusgap"
              onClick={() => navigate(-1)}
            ></i>
            Calendar
          </h2>
          <hr />
          {
            intialRole({ permission: "appointment", userData: userInfo, section: 'viewCalender' }) ?

              <>
                <div className='row'>
                  <div className="col-sm-3">
                    <select className='mb-2 form-control' disabled value={currentBranch} onChange={(e) => { setCurrentBranch(e.target.value); teamMembers(e.target.value); setDaySelected('month') }} aria-label="Default select example" name="branch">
                      <option hidden>Select Branch</option>
                      {branchListData?.map((item) => (
                        <option value={item._id} key={item._id} >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select className='mb-2 form-select ' value={currentTeam} onChange={(e) => { setCurrentTeam(e.target.value); setDaySelected('month') }} aria-label="Default select example" name="branch">
                      <option hidden>Select Team Member</option>
                      {teamMember?.map((item) => (
                        <option value={item._id} key={item._id} >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <Calendar
                  localizer={localizer}
                  events={EVENTS}
                  step={15}
                  startAccessor="start"
                  onShowMore={onShowMore}
                  endAccessor="end"
                  style={{ height: 620 }}
                  className="calender_web_height"
                  views={{ week: true, month: true, day: true }}
                  defaultView="month"
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  selectable
                  eventPropGetter={(event) => {
                    const backgroundColor = event.item.status === 'UNAVAILABILITY' ? '#ABB2B9' : '#fbbf9e';
                    return { style: { backgroundColor } }
                  }}
                  onView={(e) => { setDaySelected(e) }}
                  onRangeChange={(e) => { onRangeChange(e) }}
                />
              </>
              : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
        {
          intialRole({ permission: "appointment", userData: userInfo, section: 'createAppointment' }) ?
            <div className="d-flex justify-content-end">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Create Appointment</Tooltip>}>
                <button className="btn addbtn2" onClick={() => { setShow(true); multipleServicesList(); bookAppointment.setFieldValue('branch', userData?.locationId) }}>
                  <i className="fa-solid fa-plus"></i>
                </button>
              </OverlayTrigger>
            </div>
            : null}
      </section>

      {/* Create Appointment Start */}
      {/* <Backdrop open={show}> */}
      <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
        <div style={{ padding: '10px' }}>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); bookAppointment.resetForm(); setSlotTime([]); setSlots([]); setClientPerPage(1); setStaffId(''); setMultipleData([]); setSlotSelect([]); setOfferListData([]) }}></button>
          <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
            Create Appointment
          </h1>
        </div>
        <div className="offcanvas-body">
          <div className="cus-form">
            <div className="mb-3">
              <label className="form-label">Client Name *</label>
              <AsyncPaginate
                loadOptions={loadClientOptions}
                name="clientName"
                value={bookAppointment.values.clientName}
                onChange={(e) => {
                  bookAppointment.setFieldValue('clientName', e)
                  mobFun(e.value)
                  offerList(e.value)
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Booking Type *</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                name="visitType"
                value={bookAppointment.values.visitType}
                onChange={bookAppointment.handleChange}
                disabled={bookAppointment.values.clientName.value?.length ? false : true}
              >
                <option hidden>Select Booking Type</option>
                <option value='INOFFICE'>In-Office</option>
                <option value='HOME'>Home</option>
                <option value='ONLINE'>Online</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Services Category*</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                disabled={
                  bookAppointment.values.visitType?.length ? false : true
                }
                name="serviceCategory"
                value={bookAppointment.values.serviceCategory}
                onChange={(e) => {
                  bookAppointment.setFieldValue(
                    "serviceCategory",
                    e.target.value
                  );
                  servicesList(e.target.value);
                  setStaffList([])
                  bookAppointment.setFieldValue('availabaleStaff', '')
                  // bookAppointment.setFieldValue('branch', '')
                  // bookAppointment.setFieldValue('clientName', '')
                  bookAppointment.setFieldValue("totalAmount", "");
                  setOfferListData([]);
                }}
              >
                <option hidden>Select Service Category </option>
                {serviceCategory?.map((item, index) => (
                  <option value={item.categoryId} key={index}>
                    {item.categoryName}
                  </option>
                ))}
              </select>
              <ValidationMessage
                formName={bookAppointment}
                name={"serviceCategory"}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Services *</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                disabled={
                  bookAppointment.values.serviceCategory.length ? false : true
                }
                name="services"
                value={bookAppointment.values.services}
                onChange={(e) => {
                  bookAppointment.setFieldValue('services', e.target.value)
                  // bookAppointment.setFieldValue('branch', '')
                  offerList(e.target.value)
                  priceFun(e)
                  getStaffList()
                  getStaffList(e.target.value)
                }}
              >
                <option hidden>Select Service </option>
                {serviceList?.map((item, index) => (<React.Fragment key={index}>
                  <option value={item._id} >
                    {item.name} {`(${item.duration} Minutes)`}
                  </option>
                </React.Fragment>))}
              </select>
              <ValidationMessage formName={bookAppointment} name={"services"} />
            </div>
            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                disabled
                name="price"
                value={bookAppointment.values.price}
                onChange={bookAppointment.handleChange}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                maxLength={6}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Branch *</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                name="branch"
                value={bookAppointment.values.branch}
                onChange={(e) => {
                  bookAppointment.setFieldValue("branch", e.target.value);
                  getStaffList(e.target.value);
                  bookAppointment.setFieldValue("availabaleStaff", "");
                  setSlots([]);
                }}
                // disabled={bookAppointment.values.services.length ? false : true}
                disabled
              >
                <option hidden>Select Branch</option>
                {branchListData?.map((item, index) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <ValidationMessage formName={bookAppointment} name={"branch"} />
            </div>
            {!bookAppointment?.values?.mobileNumber?.length ? null : (
              <div className="mb-3">
                <label className="form-label">Mobile Number *</label>
                <input
                  type="text"
                  className="form-control text-muted"
                  name="mobileNumber"
                  disabled
                  value={bookAppointment.values.mobileNumber}
                  onChange={bookAppointment.handleChange}
                />
              </div>
            )}
            <div className="mb-3">
              <label className="form-label">Available Team*</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                name="availabaleStaff"
                disabled={bookAppointment.values.branch?.length ? false : true}
                value={bookAppointment.values.availabaleStaff}
                onChange={(e) => {
                  bookAppointment.handleChange(e);
                  teamAvailableId(e);
                  setStaffId(e.target.value);
                }}
              >
                <option hidden>Select Team</option>
                {staffList?.map((item) => (
                  <option value={item.staffId} key={item.staffId}>
                    {item.staffName}
                  </option>
                ))}
              </select>
            </div>
            {bookAppointment.values.availabaleStaff.length ? (
              <>
                <div>
                  <Calendars
                    onChange={(e) => {
                      timesSlot(e);
                      setSlotTime([]);
                      setCalendarValue(e);
                      bookAppointment.setFieldValue("offerSelect", "");
                      bookAppointment.setFieldValue(
                        "totalAmount",
                        bookAppointment.values.price
                      );
                      setAppointmentSlotBookAgain(false)
                    }}
                    value={calendarValue}
                  />
                  <ScrollBars
                    horizontal
                    smoothScrolling
                    style={{
                      height: "200px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="form-check mt-2">
                      {slots.length ? null : (
                        <h4 className="text-center">No Slots Available</h4>
                      )}
                      {slots?.map((item, index) => (
                        <>
                          <div className="row">
                            <button
                              type="button"
                              disabled={item.status === "Unavailable" ? true : false}
                              style={{
                                backgroundColor: (index === appointmentSlotModal.index && appointmentSlotBookAgain) ? "#f47d3a" : item.isActive ? "#f47d3a" : "#fff",
                                color: (index === appointmentSlotModal.index && appointmentSlotBookAgain) ? '#fff' : item.status === "Booked" || item.status === "Unavailable" ? "grey" : item.isActive ? "#fff" : "#f47d3a",
                                width: "300px",
                                marginLeft: "2px",
                                border:(index === appointmentSlotModal.index && appointmentSlotBookAgain) ? '1px solid #f47d3a': item.status === "Booked" || item.status === "Unavailable" ? "1px solid grey" : "1px solid #f47d3a",
                              }}
                              className="btn me-5 mb-2 col-sm-12"
                              onClick={() => {
                                if (item.status === "Booked") {
                                  setAppointmentSlotModal((pre) => ({ ...pre, open: true, data: item, index: index }))
                                }
                                if (item.status !== "Booked") {
                                  setSlots((prevState) => {
                                    const newData = prevState.map((el, i) => {
                                      if (i === index)
                                        return { ...el, isActive: true };
                                      else return { ...el, isActive: false };
                                    });
                                    return [...newData];
                                  });
                                  setSlotTime(item);
                                  dataFun(item);
                                }
                                  setAppointmentSlotBookAgain(false)
                              }
                              }
                            >
                              {moment(item.startTime).format("LT")} -  {moment(item.endTime).format("LT")}
                              <span className="ms-1">{item.status === "Booked" ? "Booked" : item.status === "Unavailable" ? "Unavailable" : null}</span>
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                  </ScrollBars>
                  {!offerListData.length ? null : (
                    <div className="mb-3 mt-3">
                      <label className="form-label">Offer List</label>
                      <div className="row">
                        <div className="col-sm-10 px-0">
                          <select
                            className="form-select form-control ms-3"
                            aria-label="Default select example"
                            name="offerSelect"
                            value={bookAppointment.values.offerSelect}
                            onChange={(e) => {
                              offerApply(e.target.value);
                              bookAppointment.setFieldValue(
                                "offerSelect",
                                e.target.value
                              );
                              // offerName(e.target.value)
                            }}
                          >
                            <option hidden>Select Offer</option>
                            {offerListData?.map((item) => (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          className={`col-1 ms-4 ${bookAppointment.values.offerSelect.length
                            ? "delete_offer"
                            : "delete_offer_disable"
                            }`}
                        >
                          <i
                            className={`fa-solid fa-xmark ${bookAppointment.values.offerSelect.length
                              ? "cursor"
                              : null
                              } `}
                            style={{ marginTop: "10px" }}
                            onClick={() =>
                              bookAppointment.values.offerSelect.length
                                ? bookAppointment.setFieldValue(
                                  "offerSelect",
                                  ""
                                )(
                                  bookAppointment.setFieldValue(
                                    "totalAmount",
                                    bookAppointment.values.price
                                  )
                                )
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mb-5">
                    <label className="form-label">Total Amount *</label>
                    <input
                      type="text"
                      className="form-control text-muted"
                      name="totalAmount"
                      disabled
                      value={bookAppointment.values.totalAmount}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="mb-2">
              <p className="font12">
                <span className="star_clr">*</span> Note : You must have
                <span
                  className="cursor navi_clr"
                  onClick={() => navigate("/layout/client")}
                >
                  Clients,
                </span>
                <span
                  className="cursor navi_clr"
                  onClick={() => navigate("/layout/service")}
                >
                  Services,
                </span>
                <span
                  className="cursor navi_clr"
                  onClick={() => navigate("/layout/profile")}
                >
                  Branches
                </span>{" "}
                &
                <span
                  className="cursor navi_clr ms-1"
                  onClick={() => navigate("/layout/team")}
                >
                  Team
                </span>
                <span className="ms-1">Create appointment.</span>
              </p>
            </div>
            <div className="d-flex justify-content-evenly">
              <button
                onClick={bookAppointment.handleSubmit}
                type="submit"
                disabled={!multipleAppointments}
                className="btn colorbtun popupbtn px"
              >
                Book Appointment
              </button>
              {multipleAppointments ? (
                <button
                  className="btn "
                  style={{
                    bottom: "10px",
                    marginLeft: "300px",
                    position: "absolute",
                  }}
                  onClick={() => {
                    multipleData.push(multipleAppointments);
                    setMultipleAppointments("");
                    bookAppointment.resetForm();
                    slotSelect.push(singleSlot);
                    setSingleSlot("");
                    setOfferListData([]);
                    bookAppointment.setFieldValue('branch', userData?.locationId)
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              ) : null}
            </div>
          </div>
          {/* </form> */}
        </div>
      </Offcanvas>
      {/* </Backdrop> */}
      {/* Create Appointment End */}

      {/* Appointment View Start*/}
      <Modal show={modalView} onHide={() => setModalView(false)} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <h2 className="text-center mb-4">Appointment Summary</h2>
            </div>
            <div>
              <button
                type="button"
                className="btn-close mt-2"
                onClick={() => setModalView(false)}
              ></button>
            </div>
          </div>
          <div className="modal-body viewdetail">
            <div className="row">
              <form className="row cus-form">
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Appointment ID</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={appointmentViewData?.appointment_number}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Date</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={moment(appointmentViewData?.startDateTime).format(
                        "DD-MM-YYYY"
                      )}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Time</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={`${moment(
                        appointmentViewData?.startDateTime
                      ).format("LT")} - ${moment(
                        appointmentViewData?.endDateTime
                      ).format("LT")}`}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Branch</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={appointmentViewData?.branchName}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Client Name</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={appointmentViewData?.clientName}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={appointmentViewData?.clientMoblieNo}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <label className="form-label">Service</label>
                  <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', borderRadius: '5px', color: "#867d7d" }}>
                    <ul className='mt-1 ms-1'>
                      {
                        appointmentViewData?.serviceDetail?.map((item) =>
                          <li class="list-group-item">{item.name}</li>
                        )
                      }
                    </ul>
                  </div>
                </div>
                {
                  appointmentViewData?.visitType &&
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label className="form-label">Booking Type</label>
                      <input
                        type="text"
                        className="form-control alreadytext"
                        value={appointmentViewData?.visitType}
                        disabled={true}
                      />
                    </div>
                  </div>
                }
                <div className="col-md-6">
                  <label className="form-label">Team Member</label>
                  <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', borderRadius: '5px', color: '#867d7d' }}>
                    <ul className='mt-1 ms-1'>
                      {
                        appointmentViewData?.staffDetail?.map((item) =>
                          <li class="list-group-item">{item.name}</li>
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1">
                    <label className="form-label">Status</label>
                    <input
                      type="text"
                      className="form-control alreadytext"
                      value={appointmentViewData?.status}
                      disabled={true}
                    />
                  </div>
                </div>
                {
                  !appointmentViewData?.isComboPackage &&
                  <div className="col-md-6 mt-2">
                    <div className="mb-1">
                      <label className="form-label">Price</label>
                      <input
                        type="text"
                        className="form-control alreadytext"
                        value={appointmentViewData?.amount}
                        disabled={true}
                      />
                    </div>
                  </div>

                }
              </form>



              <div className='d-flex justify-content-center mt-3'>
                {

                  !appointmentViewData?.isComboPackage &&
                    intialRole({ permission: "appointment", userData: userInfo, section: 'reschedule' }) ?
                    appointmentViewData?.reschedule?.status === false ?

                      <div>
                        <button className='btn apptButton' onClick={() => {
                          setShowReschedule(true); setModalView(false); getBranchList();
                          multipleServicesList()
                          getStaffList();

                        }}>
                          Reschedule</button>
                      </div>
                      :
                      null
                    : null
                }
                {
                  intialRole({ permission: "appointment", userData: userInfo, section: 'cancel' }) ?
                    <div className="ms-2">
                      <button className='btn  px-4 apptButton' onClick={() => { setShowModal(true); setModalView(false) }}>Cancel</button>
                    </div>
                    : null
                }
                {
                  intialRole({ permission: "appointment", userData: userInfo, section: 'delete' }) ?
                    <div className='ms-2'>
                      <button className='btn  px-4 apptButton' onClick={() => { setModalView(false); setDeleteModal(true) }}>Delete</button>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Appointment View End*/}

      {/* Appointment Cancel Start*/}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div className="modal-body">
          <p className="text-center">Are You Sure !</p>
          <p className="text-center">You want to cancel this appointment</p>
          <div className="mb-3">
            <label className="form-label">Reason *</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              name="reason"
              value={cancelAppointment.values.reason}
              onChange={cancelAppointment.handleChange}
            ></textarea>
            <ValidationMessage formName={cancelAppointment} name={"reason"} />
          </div>
          <button
            type="submit"
            className="btn nobtn"
            onClick={cancelAppointment.handleSubmit}
            style={{ marginLeft: "20%" }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn yesbtn"
            data-bs-dismiss="modal"
            onClick={() => setShowModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
      {/* Appointment Cancel End*/}

      {/* Appointment Delete Start*/}
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
        <h5 className="text-center mt-3">Are You Sure !</h5>
        <p className="text-center">You want to delete this appointment</p>
        <div className="modal-body text-center">
          <button
            className="btn nobtn"
            onClick={() => {
              appointmentDelete();
            }}
          >
            Yes
          </button>
          <button
            className="btn yesbtn ms-2"
            onClick={() => setDeleteModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
      {/* Appointment Delete End*/}

      {/* Appointment Same Slot Book Again Start*/}
      <Modal show={appointmentSlotModal.open} onHide={() => setAppointmentSlotModal((pre) => ({ ...pre, open: false, data: '', index: '' }))} centered animation>
        <h5 className="text-center mt-3">An appointment is already booked for this time slot</h5>
        <p className="text-center">Are you sure you want to book another appointment ?</p>
        <div className="modal-body text-center">
          <button className="btn yesbtn" onClick={() => setAppointmentSlotModal((pre) => ({ ...pre, open: false, data: '', index: '' }))}>
            Cancel
          </button>
          <button className="btn nobtn ms-2" onClick={() => {
            setSlotTime(appointmentSlotModal.data);
            dataFun(appointmentSlotModal.data);
            setSlots((prevState) => {
              const newData = prevState.map((el, i) => {
                if (i === appointmentSlotModal.index)
                  return { ...el, isActive: false };
                else return { ...el, isActive: false };
              });
              return [...newData];
            });
            setAppointmentSlotModal((pre) => ({ ...pre, open: false }))
            setAppointmentSlotBookAgain(true)
          }}>
            Book
          </button>

        </div>
      </Modal>
      {/* Appointment Same Slot Book Again End*/}

      {/* Popup Start*/}
      {
        !intialRole({ permission: "appointment", userData: userInfo, section: 'bookAppointmentFromCalender' }) && !intialRole({ permission: "UnAvailability", userData: userInfo, section: 'add' }) ?
          null
          :
          <Modal show={popup} size='sm' onHide={() => setPopup(false)} centered >
            <div className='modal-body text-center'>
              {
                intialRole({ permission: "appointment", userData: userInfo, section: 'bookAppointmentFromCalender' }) ?
                  <Button variant="outlined" sx={{ background: '#f47d3a', color: 'white', border: '1px solid #f47d3a', ":hover": { bgcolor: "#f47d3a", border: '1px solid #f47d3a', color: "white" } }} startIcon={<CalendarMonthIcon />} onClick={() => {
                    setShow(true); setPopup(false);
                    bookAppointment.setFieldValue('branch', userData?.locationId)
                  }}>
                    Book Appointment
                  </Button>
                  : null
              }

              {
                intialRole({ permission: "UnAvailability", userData: userInfo, section: 'add' }) ?
                  <Button variant="outlined" sx={{ background: '#f47d3a', color: 'white', border: '1px solid #f47d3a', ":hover": { bgcolor: "#f47d3a", border: '1px solid #f47d3a', color: "white" } }} className='mt-2' startIcon={<WatchLaterIcon />} onClick={() => { setShowUnAvailability(true); setPopup(false); timeZone() }}>
                    Set Un-Availability
                  </Button>
                  : null}

            </div>
          </Modal>
      }
      {/* Popup End*/}

      {/* Appointment Un-Availability Delete Start*/}
      <Modal
        show={unAvailabilityDelete}
        onHide={() => setUnAvailabilityDelete(false)}
        centered
      >
        <h5 className="text-center mt-3">Are You Sure !</h5>
        <p className="text-center">You want to delete this un-availability</p>
        <div className="modal-body text-center">
          <button
            className="btn nobtn"
            onClick={() => {
              unavailabilityDelete();
            }}
          >
            Yes
          </button>
          <button
            className="btn yesbtn ms-2"
            onClick={() => setUnAvailabilityDelete(false)}
          >
            No
          </button>
        </div>
      </Modal>
      {/* Appointment Un-Availability Delete End*/}

      {/* Reschedule Appointment Start */}
      <Offcanvas show={showReschedule} onHide={() => setShowReschedule(false)} placement="end" >
        <div style={{ padding: '10px' }}>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowReschedule(false); setSlots([]) }}></button>
          <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
            Reschedule Appointment
          </h1>
        </div>
        <div className="offcanvas-body">
          <div
            className="cus-form"
            onSubmit={appointmentReschedule.handleSubmit}
          >
            <div className="mb-3">
              <div className="mb-3">
                <label className="form-label">Client Name *</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={appointmentViewData.clientName}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Type *</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="visitType"
                  value={appointmentViewData.visitType}
                  disabled
                >
                  <option hidden>Select Booking Type</option>
                  <option value='INOFFICE'>In-Office</option>
                  <option value='HOME'>Home</option>
                  <option value='ONLINE'>Online</option>
                </select>
              </div>
              <label className="form-label">Services Category*</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={appointmentViewData.serviceCategoryName?.[0]?.name}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Service *</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={appointmentViewData.serviceDetail?.[0]?.name}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                disabled
                name="price"
                value={
                  appointmentViewData.amount || appointmentViewData.servicePrice
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Branch *</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={appointmentViewData.branchName}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Team *</label>
              <input
                type="text"
                className="form-control"
                disabled
                value={appointmentViewData?.staffDetail?.[0]?.name}
              />
            </div>
            <div>
              <Calendars
                onChange={(e) => {
                  timesSlot(e);
                  setSlotTime([]);
                }}
              />
              <ScrollBars
                horizontal
                style={{ height: "200px", width: "100%", marginTop: "10px" }}
                smoothScrolling={true}
              >
                <div className="form-check mt-2 mb-2">
                  {slots.length ? null : (
                    <h4 className="text-center">No Slots Available</h4>
                  )}
                  {slots?.map((item, index) => (
                    <div key={index}>
                      <div className='row'>
                        <button
                          disabled={
                            item.status === "Booked" ||
                              item.status === "Unavailable"
                              ? true
                              : false
                          }
                          type="button"
                          style={{
                            backgroundColor: item.isActive ? "#f47d3a" : "#fff",
                            color:
                              item.status === "Booked" ||
                                item.status === "Unavailable"
                                ? "grey"
                                : item.isActive
                                  ? "#fff"
                                  : "#f47d3a",
                            width: "300px",
                            marginLeft: "2px",
                            border:
                              item.status === "Booked" ||
                                item.status === "Unavailable"
                                ? "1px solid grey"
                                : "1px solid #f47d3a",
                          }}
                          className="btn me-5 mb-2 col-sm-12"
                          onClick={() => {
                            setSlots((prevState) => {
                              const newData = prevState.map((el, i) => {
                                if (i === index)
                                  return { ...el, isActive: true };
                                else return { ...el, isActive: false };
                              });
                              return [...newData];
                            });
                            setSlotTime(item);
                          }}
                        >
                          {moment(item.startTime).format("LT")} -{" "}
                          {moment(item.endTime).format("LT")}{" "}
                          {item.status === "Booked"
                            ? "Booked"
                            : item.status === "Unavailable"
                              ? "Unavailable"
                              : null}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollBars>
              <div className="d-grid  col-6 mx-auto mb-4">
                <button
                  disabled={slotTime.length === 0 ? true : false}
                  className="btn colorbtun popupbtn px"
                  onClick={() => appointmentReschedule()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas>
      {/* Reschedule Appointment End */}

      {showUnAvailability ?
        <AddTeamUnAvailability showUnAvailability={showUnAvailability} setShowUnAvailability={setShowUnAvailability} setUnavailability={setUnavailability} timeZoneData={timeZoneList} staffBranchesData={branchListData}
          showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} defaultFormValue={defaultFormValue} teamMember={teamAccordingBranch} teamMembersUnAvailibility={teamMembersUnAvailibility} />
        : null}
    </>
  );
};

export default AddAppointment;

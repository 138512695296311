export const statusCodes = {
  ok: 200,
  error: 404,
  token: 412,
  tokenExpire: 405,
  notAuthorized: 401,
  locationScreen: 307
}

export const commonMessage = {
  networkError: 'Network Error'
}

export const roleNames = {
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
  STAFF: 'STAFF',
  FRONTDESK: 'FRONTDESK'
}


// Project Id Use in Ticket Section

// Local
// export const projectId = '64f849a7661cdf852e37b447'  

// Live
export const projectId = '6529314fd89c1f9e23652351'


export const initialState = [
  {
    id: 0,
    name: 'Sunday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 1,
    name: 'Monday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 2,
    name: 'Tuesday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 3,
    name: 'Wednesday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 4,
    name: 'Thursday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 5,
    name: 'Friday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
  {
    id: 6,
    name: 'Saturday',
    isChecked: false,
    isAllowedTimes: {
      first: false,
      second: false,
    },
    arrayOfTimings: [],
  },
]

export const initialValues = {
  timezone: '',
  branchId: '',

  startTimeSunday: null,
  endTimeSunday: null,
  secondStartTimeSunday: null,
  secondEndTimeSunday: null,
  thirdStartTimeSunday: null,
  thirdEndTimeSunday: null,

  startTimeMonday: null,
  endTimeMonday: null,
  secondStartTimeMonday: null,
  secondEndTimeMonday: null,
  thirdStartTimeMonday: null,
  thirdEndTimeMonday: null,

  startTimeTuesday: null,
  endTimeTuesday: null,
  secondStartTimeTuesday: null,
  secondEndTimeTuesday: null,
  thirdStartTimeTuesday: null,
  thirdEndTimeTuesday: null,

  startTimeWednesday: null,
  endTimeWednesday: null,
  secondStartTimeWednesday: null,
  secondEndTimeWednesday: null,
  thirdStartTimeWednesday: null,
  thirdEndTimeWednesday: null,

  startTimeThursday: null,
  endTimeThursday: null,
  secondStartTimeThursday: null,
  secondEndTimeThursday: null,
  thirdStartTimeThursday: null,
  thirdEndTimeThursday: null,

  startTimeFriday: null,
  endTimeFriday: null,
  secondStartTimeFriday: null,
  secondEndTimeFriday: null,
  thirdStartTimeFriday: null,
  thirdEndTimeFriday: null,

  startTimeSaturday: null,
  endTimeSaturday: null,
  secondStartTimeSaturday: null,
  secondEndTimeSaturday: null,
  thirdStartTimeSaturday: null,
  thirdEndTimeSaturday: null,
}
export const availabilityInitialValues = {
  timeZone: '',
  branch: '',

  startTimeSunday: null,
  endTimeSunday: null,
  secondStartTimeSunday: null,
  secondEndTimeSunday: null,
  thirdStartTimeSunday: null,
  thirdEndTimeSunday: null,

  startTimeMonday: null,
  endTimeMonday: null,
  secondStartTimeMonday: null,
  secondEndTimeMonday: null,
  thirdStartTimeMonday: null,
  thirdEndTimeMonday: null,

  startTimeTuesday: null,
  endTimeTuesday: null,
  secondStartTimeTuesday: null,
  secondEndTimeTuesday: null,
  thirdStartTimeTuesday: null,
  thirdEndTimeTuesday: null,

  startTimeWednesday: null,
  endTimeWednesday: null,
  secondStartTimeWednesday: null,
  secondEndTimeWednesday: null,
  thirdStartTimeWednesday: null,
  thirdEndTimeWednesday: null,

  startTimeThursday: null,
  endTimeThursday: null,
  secondStartTimeThursday: null,
  secondEndTimeThursday: null,
  thirdStartTimeThursday: null,
  thirdEndTimeThursday: null,

  startTimeFriday: null,
  endTimeFriday: null,
  secondStartTimeFriday: null,
  secondEndTimeFriday: null,
  thirdStartTimeFriday: null,
  thirdEndTimeFriday: null,

  startTimeSaturday: null,
  endTimeSaturday: null,
  secondStartTimeSaturday: null,
  secondEndTimeSaturday: null,
  thirdStartTimeSaturday: null,
  thirdEndTimeSaturday: null,
}


export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


